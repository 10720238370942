import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Grid,
  Modal,
  Fade,
  Backdrop,
  TextField,
  Box,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";
import { useMutation } from '@apollo/client'

import { STRAPI_DATE_FORMAT } from '../../constants';
import { CREATE_EXPULSION, DELETE_EXPULSION, UPDATE_EXPULSION } from '../../graphql';
import ActionButton from '../ActionButton';
import styles from "../YouthCenterForm/YouthCenterFormStyles";
import { css } from 'aphrodite';
import { AppStyles } from '../../theme';
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '500px',
    margin: '0 20px'
  },
}));


export default function(props) {
  const classes = useStyles();

  const {
    open,
    handleClose,
    participant,
  } = props;

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [reason, setReason] = useState('');
  const [isValidEndDate, setIsValidEndDate] = useState(true);
  const [formDateMsg, setFromDateMsg] = useState('');

  const [createExpulsion, {
    loading: loadingCreateExpulsion,
    error: errCreateExpulsion,
  }] = useMutation(CREATE_EXPULSION, {
    onCompleted: data => {
      handleClose();

      if (props.formSubmitCallback) {
        props.formSubmitCallback('create');
      };
    },
  });

  const [updateExpulsion, {
    loading: loadingUpdateExpulsion,
    error: errUpdateExpulsion,
  }] = useMutation(UPDATE_EXPULSION, {
    onCompleted: data => {
      handleClose();

      if (props.formSubmitCallback) {
        props.formSubmitCallback('update');
      };
    },
  });

  const [deleteExpulsion, {
    loading: loadingDeleteExpulsion,
    error: errDeleteExpulsion,
  }] = useMutation(DELETE_EXPULSION, {
    onCompleted: data => {
      handleClose();

      if (props.formSubmitCallback) {
        props.formSubmitCallback('delete');
      };
    },
  });

  useEffect(() => {
    if (props.extExpulsion) {
      setStartDate(props.extExpulsion.start + "T00:00");
      setEndDate(props.extExpulsion.end + "T00:00");
      setReason(props.extExpulsion.reason);
    };
  }, [props.extExpulsion])

  const validateForm = () => {
    let isValid = true;

    if (!endDate) {
      setIsValidEndDate(false);
      setFromDateMsg('Date de fin requise');
      isValid = false;
    } else if (startDate >= endDate) {
      setIsValidEndDate(false);
      setFromDateMsg('La date de fin doit être postérieure à la date de début');
      isValid = false;
    };

    return isValid;
  };

  const handleCreateExpulsion = () => {
    if (validateForm()) {
      createExpulsion({
        variables: {
          participant: participant.id,
          startDate: moment(startDate).format(STRAPI_DATE_FORMAT),
          endDate: moment(endDate).format(STRAPI_DATE_FORMAT),
          reason,
        }
      })
    };
  };

  const handleUpdateExpulsion = () => {
    if (validateForm()) {
      updateExpulsion({
        variables: {
          id: props.extExpulsion.id,
          participant: participant.id,
          start: moment(startDate).format(STRAPI_DATE_FORMAT),
          end: moment(endDate).format(STRAPI_DATE_FORMAT),
          reason,
        },
      });
    };
  };

  const handleDeleteExpulsion = () => {
    deleteExpulsion({
      variables: {
        id: props.extExpulsion.id,
      },
    });
  };


  const renderOperationsErrors = () => {
    if (errCreateExpulsion || errUpdateExpulsion || errDeleteExpulsion) {
      console.error("errCreateExpulsion", errCreateExpulsion)
      console.error("errUpdateExpulsion", errUpdateExpulsion)
      console.error("errDeleteExpulsion", errDeleteExpulsion)

      return (
        <Grid item xs={12}>
          <span className={`${css(AppStyles.formError)}`}>
            Quelque chose a mal tourné lors de la mise à jour de l'expulsion.
          </span>
        </Grid>
      )
    }

    return null;
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h2 id="transition-modal-title">
            Expulsion de {participant.firstName} {participant.lastName}
          </h2>
          <form
            className={classes.root}
            noValidate
            autoComplete="off"
          >
            <MuiPickersUtilsProvider
              locale={frLocale}
              utils={DateFnsUtils}
            >
              <Grid
                container
                spacing={6}
                justifyContent="space-between"
              >
                <Grid item xs={12} md={6}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    id="date-picker-inline"
                    label="À partir de"
                    invalidDateMessage="Date invalide"
                    fullWidth
                    value={startDate}
                    onChange={date => {
                      setStartDate(date);
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    id="date-picker-inline"
                    label="Jusqu’à"
                    fullWidth
                    value={endDate}
                    onChange={date => {
                      setEndDate(date);
                      setIsValidEndDate(true);
                      setFromDateMsg('');
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  {!isValidEndDate &&
                    <span className={`${css(AppStyles.formError)}`}>
                      {formDateMsg}
                    </span>
                  }
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="reason"
                    label="Raison"
                    fullWidth
                    multiline
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                  />
                </Grid>
                
                {renderOperationsErrors()}
              
                <Grid item xs={12} md={6}>
                  {props.extExpulsion ?
                    <ActionButton
                      className={css(styles.deleteBtn)}
                      title="Supprimer"
                      isLoading={loadingDeleteExpulsion}
                      onClick={handleDeleteExpulsion}
                    />
                    :
                    <ActionButton
                      className={css(styles.cancelButton)}
                      title="Annuler"
                      isLoading={loadingDeleteExpulsion}
                      onClick={() => handleClose()}
                    />
                  }
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box textAlign={'right'}>
                    {props.extExpulsion
                      ?
                      <div style={{display: 'flex'}}>
                        <ActionButton
                          className={css(styles.cancelButton)}
                          title="Annuler"
                          isLoading={loadingDeleteExpulsion}
                          onClick={() => handleClose()}
                        />
                        <ActionButton
                          className={css(styles.addBtn)}
                          title="Mise à jour"
                          isLoading={loadingUpdateExpulsion}
                          onClick={handleUpdateExpulsion}
                        />
                      </div>
                      :
                      <ActionButton
                        className={css(styles.addBtn)}
                        title="Expulser le participant"
                        isLoading={loadingCreateExpulsion}
                        onClick={handleCreateExpulsion}
                      />
                    }
                  </Box>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </form>
        </div>
      </Fade>
    </Modal>
  );
};