import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../theme/Colors";
import { Button, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  flexClass: { flex: 1 },
  appBarBg: {
    background: Colors.brand.primary,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
    fontSize: 18,
    textTransform: "uppercase",
  },
}));

export default function ActionButtonView(props) {
  const classes = useStyles();

  if (props.hide) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Button
        onClick={props.onClick}
        className={`${props.className} action-button`}
        type={props.type}
        disabled={props.isLoading || props.disabled}
      >
        {props.isLoading ? (
          <CircularProgress size={24} color="white" />
        ) : (
          props.title
        )}
      </Button>
    </div>
  );
}
