// @flow
import { StyleSheet } from "aphrodite";
import { AppStyles, Colors } from "../../../../theme";

export default StyleSheet.create({
  linkIconWrapper: {
    color: Colors.blackish,
    display: "flex",
    justifyContent: "center",
    alignItems: " center",
  },

  TableWrpper: {
    width: "100%",
    marginTop: 40,
    height: "calc(100vh - 410px)",
    minHeight: 400,
  },
});
