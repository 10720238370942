// @flow
import React from "react";
import { css } from "aphrodite";
import styles from "./PasswordResetStyles";
import { PasswordReset } from "../../components";

export default function LoginView() {
  return (
    <div>
      <div className={css(styles.passwordResetContainer)}>
        <PasswordReset />
      </div>
    </div>
  );
}
