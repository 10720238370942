import React, { useState } from 'react';
import {
  makeStyles,
  Grid,
  Modal,
  Fade,
  Backdrop,
  TextField,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { css } from 'aphrodite';
import { useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';

import ActionButton from '../ActionButton';
import styles from '../YouthCenterForm/YouthCenterFormStyles';
import { BACKEND_URI } from '../../constants';
import Util from '../../services/Util';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '500px',
    margin: '0 20px'
  },
  dialogPaper: {
    width: '80%',
    maxHeight: 435,
  },
}));


function ConfirmationDialogRaw(props) {
  const {
    onClose,
    onConfirm,
    open,
    ...other
  } = props;

  const handleCancel = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">{props.title}</DialogTitle>
      <DialogContent dividers>
        {props.description}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Annuler
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};


export default function (props) {
  const {
    open,
    handleClose,
    participant,
  } = props;
  const classes = useStyles();
  const user = useSelector((state) => {
    return state.user;
  });
  const ycState = useSelector((state) => {
    return state.youth_center;
  });

  const [participants, setParticipants] = useState([]);
  const [mergeIntoParticipant, setMergeIntoParticipant] = useState({
    firstName: '',
    lastName: '',
  });
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [showNoParticipantErr, setShowNoParticipantErr] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchParticipantsSearch = async () => {
    const { results: participants } = await Util.fetchParticipantsSearch({
      user,
      page: 1,
      pageSize: 30,
      searchTerm,
      showArchived: false,
      isMerge: true,
    });

    setParticipants(participants.filter(p => {
      if (parseInt(participant.id) === parseInt(p.id)) {
        return false;
      }

      return true;
    }));
  };

  React.useEffect(() => {
    if (open) {
      fetchParticipantsSearch();
    }
  }, [searchTerm, open]);

  const mergeParticipant = async () => {
    const res = await fetch(`${BACKEND_URI}/api/participants/merge`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.jwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        merge_into_id: mergeIntoParticipant.id,
        p_id: participant.id,
      }),
    }).then(resBuff => resBuff.json())

    if (res.error) {
      console.error("Error while merging participant: ", res)
      props.formErrCallback(res.error)
      props.formErrCallback("Quelque chose s'est mal passé lors de l'archivage du participant. " + res);
      return;
    }

    setConfirmOpen(false);
    handleClose();

    if (props.formSubmitCallback) {
      props.formSubmitCallback();
    }

    return res;
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h2 id="transition-modal-title">
            Fusion de "{participant.firstName} {participant.lastName}"
          </h2>
          <form
            className={classes.root}
            style={{
              width: '600px',
              maxWidth: '100%'
            }}
            noValidate
            autoComplete="off"
          >

            <Grid
              container
              spacing={6}
              justifyContent="space-between"
            >
              <Grid item xs={12} md={12}>
                <Autocomplete
                  fullWidth
                  options={participants}
                  getOptionLabel={(option) => `${option.firstName || ''} ${option.lastName || ''}`}
                  id="Participant"
                  key="participant-autocomple"
                  onChange={(e, participant) => {
                    setMergeIntoParticipant(participant || {});
                    setShowNoParticipantErr(false);
                  }}
                  loading={ycState.loadingParticipants}
                  loadingText="Chargement..."
                  noOptionsText="Aucun participant trouvé"
                  title="Fusionner le participant avec..."
                  renderInput={(params) => {
                    return (
                      <TextField
                        label='Fusionner le participant avec...'
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          onChange: (e) => {
                            setSearchTerm(e.target.value);
                          },
                          endAdornment: (
                            <React.Fragment>
                              {ycState.loadingParticipants ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )
                  }}
                />
              </Grid>
            </Grid>

            {showNoParticipantErr &&
              <Grid
                container
                spacing={6}
                justifyContent="space-between"
              >
                <Grid item xs={12} md={12}>
                  <Typography color='error'>
                    Veuillez sélectionner un participant
                  </Typography>
                </Grid>
              </Grid>
            }

            <Grid
              container
              spacing={6}
              style={{ marginTop: 20 }}
              justifyContent="space-between"
            >
              <Grid item xs={6}>
                <ActionButton
                  className={css(styles.deleteBtn)}
                  title="Annuler"
                  onClick={() => {
                    handleClose();
                  }}
                />
              </Grid>
              <Grid item xs={6} a>
                <Box textAlign={'right'}>
                  <ActionButton
                    className={css(styles.addBtn)}
                    title="Fusionner"
                    onClick={() => {
                      if (!mergeIntoParticipant.firstName) {
                        setShowNoParticipantErr(true);
                        return null;
                      };
                      setConfirmOpen(true);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

            <ConfirmationDialogRaw
              classes={{
                paper: classes.dialogPaper,
              }}
              id="confirm-merge"
              keepMounted
              open={confirmOpen}
              onClose={() => {
                setConfirmOpen(false);
              }}
              onConfirm={mergeParticipant}
              title={`Confirmer la fusion`}
              a={`
              
Les présences, les participations aux activités, les notes, les expulsions et les pièces jointes de 
XYZ seront réaffectées au participant ABC 
et le participant XYZ sera archivé.

Cette opération est irréversible!

Voulez-vous vraiment fusionner le participant XYZ (id 123) avec le participant ABC (id 456)?
              
              `}
              description={<div className='participant-merge-desc'>
                Les présences, les participations aux activités, les notes, les expulsions et les pièces jointes de
                <strong> {participant.firstName} {participant.lastName} </strong>
                seront réaffectées au participant
                <strong> {mergeIntoParticipant.firstName} {mergeIntoParticipant.lastName} </strong>
                et le participant <strong> {participant.firstName} {participant.lastName}</strong> sera archivé.
                <br />
                <br />
                Cette opération est irréversible!
                <br />
                <br />
                Voulez-vous vraiment fusionner le participant
                <strong> {participant.firstName} {participant.lastName}</strong> (id <strong>{participant.id}</strong>)
                avec le participant
                <strong> {mergeIntoParticipant.firstName} {mergeIntoParticipant.lastName}</strong> (id <strong>{mergeIntoParticipant.id}</strong>)?
              </div>}
            />

          </form>
        </div>
      </Fade>
    </Modal>
  );
};