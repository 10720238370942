// @flow
import React from "react";
import PropTypes from "prop-types";

import ActivityInvolvementInput from "./ActivityInvolvementInputView";

export default class ActivityInvolvementInputController extends React.Component {
  static propTypes = {
    entityId: PropTypes.string,
    lable: PropTypes.string,
    count: PropTypes.number,
    handleCountUpdate: PropTypes.func,
  };

  static defaultProps = {
    handleCountUpdate: () => {
      console.log("Method Not Available");
    },
  };

  render() {
    return <ActivityInvolvementInput {...this.props} />;
  };
}
