// @flow
import React from "react";
import ActivitySummaryView from "./ActivitySummaryView";

export default class ActivitySummaryController extends React.Component {
  static propTypes = {};

  static defaultProps = {};
  render() {
    return <ActivitySummaryView {...this.props} />;
  }
}
