import Immutable from "seamless-immutable";
import {
  LOGIN_USER,
  LOGOUT_USER,
  SET_PASSWORD_COMPLETED,
  UPDATE_USER,
} from "../actions/ActionTypes";

const initialState = Immutable({});


export default (state = initialState, action) => {

  switch (action.type) {
    case LOGIN_USER: {

      return Immutable.merge(state, {
        youth_center: {},
        ...action.payload.user,
        jwt: action.payload.jwt,
      });
    }
    case UPDATE_USER: {
      return Immutable.merge(state, {
        ...state,
        ...action.payload.user,
      });
    }
    case SET_PASSWORD_COMPLETED: {
      return Immutable.merge(state, {
        ...state.user,
        requestPasswordChange: false,
      });
    }
    case LOGOUT_USER: {
      return Immutable.merge(state, {});
    }
    default:
      return state;
  };
}