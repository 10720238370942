// @flow
import { StyleSheet } from "aphrodite";

export default StyleSheet.create({
  passwordResetContainer: {
    marginBottom: 200,
    "@media (max-width: 600px)": {
      marginBottom: 100,
    },
  },
});
