// @flow
import React from "react";
import PropTypes from "prop-types";

import MergeModalView from "./MergeModalView";

export default class MergeModalConstroller extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    participant: PropTypes.object.isRequired,
    formSubmitCallback: PropTypes.func.isRequired,
    extMerge: PropTypes.object
  };

  static defaultProps = {};

  render() {
    return <MergeModalView {...this.props} />;
  };
};