// @flow
import React from "react";
import PropTypes from "prop-types";

import HeaderView from "./HeaderView";

export default class HeaderController extends React.Component {
  static propTypes = {
    date: PropTypes.object,
    title: PropTypes.string,
  };

  static defaultProps = {
    date: null,
    title: "",
  };

  render() {
    return <HeaderView {...this.props} />;
  }
}
