// @flow
import React from "react";
import PropTypes from "prop-types";

import DashboardView from "./DashboardView";

export default class DashboardController extends React.Component {
  static propTypes = {};

  static defaultProps = {};

  render() {
    return <DashboardView {...this.props} />;
  }
}
