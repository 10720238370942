// @flow
import React from "react";
import PropTypes from "prop-types";

import ActionButtonView from "./ActionButtonView";

export default class ActionButtonController extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string,
    type: PropTypes.string,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    onClick: () => {},
    className: "",
    title: "",
    type: "button",
    isLoading: false,
    disabled: false,
  };

  render() {
    return <ActionButtonView {...this.props} />;
  }
}
