// @flow
import { StyleSheet } from "aphrodite";
import { Colors } from "../../theme";

export default StyleSheet.create({
  addBtn: {
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 400,
    boxShadow: "2px 2px 8px 1px #0000002b",
    ":hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
  updateBtn: {
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 400,
    width: "auto",
    boxShadow: "2px 2px 8px 1px #0000002b",
    cursor: "pointer",
    ":hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
  deleteBtn: {
    background: Colors.red2,
    color: Colors.white,
    border: `1px solid ${Colors.red2}`,
    textTransform: "uppercase",
    fontWeight: 400,
    width: "auto",
    boxShadow: "2px 2px 8px 1px #0000002b",
    cursor: "pointer",
    ":hover": {
      color: Colors.red2,
      background: Colors.white,
      boxShadow: "none",
    },
  },
  cancelButton: {
    color: Colors.red2,
    textTransform: "uppercase",
    fontWeight: 400,
    width: "auto",
    cursor: "pointer",
    ":hover": {
      // fontWeight: 900
    },
  },
  checkboxWrapper: {
    marginTop: 20,
    marginBottom: 10,
  },
});
