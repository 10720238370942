// @flow
import _ from "lodash";
import React from "react";
import PasswordResetView from "./PasswordResetView";
import { ROUTES } from "../../constants";
import Util from "../../services/Util";

class PasswordResetController extends React.Component {
  static propTypes = {};

  componentDidMount() {
    this.redirectIfNoPasswordReset();
  }

  componentDidUpdate() {
    this.redirectIfNoPasswordReset();
  }

  redirectIfNoPasswordReset = () => {
    if (!Util.isPasswordChangeRequested()) {
      window.location.href = `${window.location.origin}${ROUTES.DASHBOARD}`;
    }
  };

  render() {
    return <PasswordResetView {...this.props} />;
  }
}

export default PasswordResetController;
