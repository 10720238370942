// @flow
import React from "react";
import PropTypes from "prop-types";

import ActivityFormView from "./ActivityFormView";

export default class ActivityFormController extends React.Component {
  static propTypes = {
    date: PropTypes.object.isRequired,
    servicePoint: PropTypes.string,
    activityID: PropTypes.number,
    pushActivityList: PropTypes.func,
    popActivityList: PropTypes.func,
  };

  static defaultProps = {
    activityID: undefined,
    pushActivityList: () => {
      console.log("Method Not Available");
    },
    popActivityList: () => {
      console.log("Method Not Available");
    },
  };

  render() {
    return <ActivityFormView {...this.props} />;
  }
}
