import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, frFR, GridToolbar } from "@material-ui/data-grid";
import { Colors } from "../../theme";
import {
  Grid,
  Container,
  Typography,
  Card,
  CardContent,
  Snackbar,
  IconButton,
  Fab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Paper,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { css } from "aphrodite";
import { AppStyles } from "../../theme";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import {
  UPDATE_PARTICIPANT,
  PARTICIPANT,
  GET_PARTICIPANT_ACTIVITIES,
  NOTES_FOR_PARTICIPANT,
  UPDATE_PARTICIPANT_ATTACHMENTS,
} from "../../graphql";
import {
  DATE_FORMAT11,
} from "../../constants";
import styles from "./ParticipantsStyles";
import { Header, UserForm } from "../../components";
import _ from "lodash";
import { useSelector } from "react-redux";
import ExpulsionModal from "../../components/ExpulsionModal";
import { Add, AttachFile, Delete } from "@material-ui/icons";
import NoteModal from "./components/NoteModal";
import Util from "../../services/Util";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  flexClass: { flex: 1 },
  appBarBg: {
    background: Colors.brand.primary,
  },
  inputFieldsWrapper: {
    marginTop: theme.spacing(2),
  },
  hrMargin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  hrColor: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  otherField: {
    marginTop: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
    fontSize: 18,
    textTransform: "uppercase",
  },

  green: {
    color: Colors.brand.primary,
  },
  formControl: {
    minWidth: "100%",
  },
  deleteBtn: {
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 400,
    boxShadow: "2px 2px 8px 1px #0000002b",
    "&:hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
}));

export default function ParticipantForm(props) {
  const user = useSelector((state) => {
    return state.user;
  });
  const classes = useStyles();
  const [singleParticipant, setSingleParticipant] = useState(() => { });
  const [tableData, setTableData] = useState(() => []);
  const [activityData, setActivityData] = useState(() => []);
  const [noteData, setNoteData] = useState(() => []);
  const [expulsionsData, setExpulsionsData] = useState(() => []);
  const [extExpulsion, setExtExpulsion] = useState(() => { });
  const [modalOpen, setModalOpen] = useState(() => false);
  const [files, setFiles] = useState(() => []);
  const [activeNote, setActiveNote] = useState({});
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [notePage, setNotePage] = React.useState(0);
  const [noteRowsPerPage, setNoteRowsPerPage] = React.useState(100);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const [openSnackBar, setOpenSnackBar] = useState(() => false);
  const [formSubmitResponse, setFormSubmitResponse] = useState(() => "");

  const snackBarCloseHandler = () => {
    setOpenSnackBar(false);
  };

  const [openErrSnackBar, setOpenErrSnackBar] = useState(() => false);
  const [clientErrMsg, setClientErrMsg] = useState("");

  const snackBarErrClose = () => {
    setOpenErrSnackBar(false);
    setClientErrMsg("");
  };

  const {
    match: { params },
  } = props;

  const [
    updateParticipantReq,
    {
      data: participantUpdate,
      error: participantUpdateError,
      loading: participantUpdateLoading,
    },
  ] = useMutation(UPDATE_PARTICIPANT, {
    onCompleted: (data) => {
      setOpenSnackBar(true);
      setFormSubmitResponse("Le profil est mis à jour");
    },
  });

  const [
    updateAttachments,
  ] = useMutation(UPDATE_PARTICIPANT_ATTACHMENTS, {
    onCompleted: data => {
      setFiles(data.updateParticipant.data.attributes.files.data.map(file => {
        return {
          id: file.id,
          ...file.attributes,
        }
      }));

      setOpenSnackBar(true);
      setFormSubmitResponse("Les pièces jointes ont été téléversées avec succès");
    },
    onError: err => {
      console.error("Error updating attachments", err);
      setClientErrMsg("Un problème est survenu lors du téléversement des pièces jointes");
      setOpenErrSnackBar(true);
    }
  });

  const { loading, error, refetch: refetchParticipnat } = useQuery(PARTICIPANT, {
    fetchPolicy: "network-only",
    skip: _.isUndefined(params.id) || _.isNil(params.id),
    variables: {
      id: params.id,
    },
    onCompleted: (data) => {
      const participant = {
        participant: Util.serialize_PARTICIPANT_res(data),
      };

      setSingleParticipant(participant);
      setTableData(participant.participant.attendances.sort((a, b) => Date.parse(b.date) - Date.parse(a.date)));
      setExpulsionsData([...participant.participant.expulsions].sort((a, b) => Date.parse(b.end) - Date.parse(a.end)));
      setFiles(participant.participant.files);
    },
  });

  useQuery(
    GET_PARTICIPANT_ACTIVITIES,
    {
      variables: {
        participantID: params.id,
      },
      fetchPolicy: "network-only",
      skip: _.isUndefined(params.id) || _.isNil(params.id),
      onCompleted: (data) => {
        setActivityData(data.activities.data.map((activity) => {
          return {
            id: activity.id,
            ...activity.attributes,
          }
        }));
      },
    }
  );

  const {
    refetch: refetchParticipantNotes,
  } = useQuery(NOTES_FOR_PARTICIPANT, {
    variables: {
      participantId: params.id,
    },
    fetchPolicy: "network-only",
    skip: _.isUndefined(params.id) || _.isNil(params.id),
    onCompleted: (data) => {
      setNoteData(data.participantNotes.data.map((note) => {
        return {
          id: note.id,
          ...note.attributes,
          participants: note.attributes.participants.data.map((participant) => {
            return {
              id: participant.id,
              ...participant.attributes,
              
            }
          }),
          activity: note.attributes.activity?.data ? {
            id: note.attributes.activity.data.id,
            ...note.attributes.activity.data.attributes,
          } : null,
        };
      }));
    },
  });

  const columns = [
    {
      key: "date",
      field: "date",
      headerName: "Date",
      flex: 1,
      sortable: false,
      minWidth: 200,
      renderCell: (param) => {
        return (
          <div>
            {moment(param.row.date.toLocaleString())
              .locale("fr")
              .format(DATE_FORMAT11)}
          </div>
        );
      },
    },
    {
      key: "Lieu",
      field: "Lieu",
      headerName: "Lieu",
      flex: 1,
      sortable: false,
      minWidth: 200,
      renderCell: (param) => {
        const youthCenterName = user.youth_center.Name;
        return (
          <div>
            {param.row.service_point?.name || youthCenterName}
          </div>
        );
      },
    },
  ];

  const activityColumns = [
    {
      key: "title",
      field: "title",
      headerName: "Titre",
      flex: 1,
      sortable: false,
      minWidth: 200,
    },
  ];

  const noteColumns = [
    {
      key: "textnote",
      field: "textnote",
      headerName: "Note",
      flex: 1,
      sortable: false,
      width: 200,
      renderCell: (params) => {
        return (
          <div>
            <p>test</p>
            {params.value}
          </div>
        )
      },
    },
    {
      key: "activity",
      field: "activity",
      headerName: "Activité",
      // flex: 1,
      sortable: false,
      // minWidth: 150,
      renderCell: (param) => {
        return <div>{param.row.activity?.title}</div>;
      },
    },
    {
      key: "notedate",
      field: "notedate",
      headerName: "Date",
      // flex: 1,
      sortable: false,
      // minWidth: 80,
      renderCell: (param) => {
        let date = param.row.createdAt;

        if (param.row.activity?.date) {
          date = param.row.activity.date;
        };

        date = moment(date).format('YYYY-MM-DD');

        return <div>{date}</div>;
      },
    },
  ];

  const expulsionColumns = [
    {
      key: "start",
      field: "start",
      headerName: "Date de début",
      minWidth: 130,
      sortable: false,
    },
    {
      key: "end",
      field: "end",
      headerName: "Date de fin",
      minWidth: 130,
      sortable: false,
    },
    {
      key: "reason",
      field: "reason",
      headerName: "Raison ",
      flex: 1,
      sortable: false,
      minWidth: 200,
    },
  ];

  const uploadFiles = async (newFiles) => {
    const fileIds = [...files].map(f => parseInt(f.id));
    const formData = new FormData();

    newFiles.forEach(file => {
      formData.append('files', file);
    })

    const resBuff = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/upload`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.jwt}`,
      },
      body: formData,
    });
    const res = await resBuff.json();

    res.forEach(f => fileIds.push(f.id));

    updateAttachments({
      variables: {
        id: params.id,
        files: fileIds,
      },
    });
  };

  const handleFileDelete = async file => {
    const resBuff = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/upload/files/${file.id}`, {
      method: 'DELETE',
      headers: {
        Authorization: "Bearer " + user.jwt,
      }
    });

    if (resBuff.status !== 200) {
      setClientErrMsg("Un problème est survenu lors de la suppression de la pièce jointe.");
      setOpenErrSnackBar(true);

      const res = await resBuff.json();
      console.error(res);

      return null;
    }

    setFormSubmitResponse('Pièce jointe supprimée');
    setOpenSnackBar(true);

    const filesCopy = [...files];
    setFiles(filesCopy.filter(f => f.id !== file.id));
  }

  const filesColumns = [
    {
      key: "icon",
      field: "icon",
      headerName: " ",
      minWidth: 24,
      sortable: false,
      renderCell: param => {
        if (['image/gif', 'image/jpeg', 'image/png'].includes(param.row.mime)) {
          return (
            <img
              src={(process.env.REACT_APP_FILES_URI || '') + param.row.url}
              style={{
                height: 24,
              }}
            />
          );
        };

        return <AttachFile />;
      },
    },
    {
      key: "name",
      field: "name",
      headerName: "Nom",
      sortable: true,
      flex: 1,
      renderCell: param => {
        return (
          <a
            style={{ textTransform: 'none' }}
            href={(process.env.REACT_APP_FILES_URI || '') + param.row.url}
            target="_blank"
          >
            {param.row.name}
          </a>
        );
      },
    },
    {
      field: "",
      headerName: "",
      sortable: false,
      filterable: false,
      renderCell: param => {
        return (
          <IconButton
            aria-label="delete"
            onClick={() => {
              handleFileDelete(param.row);
            }}
            style={{
              padding: 6
            }}
          >
            <Delete />
          </IconButton>
        )
      }
    }
  ];

  const expulsionFormSubmitCallback = (type) => {
    if (type === 'update') {
      setFormSubmitResponse('Expulsion mise à jour');
    } else if (type === 'delete') {
      setFormSubmitResponse('Expulsion supprimée');
    } else {
      setFormSubmitResponse('OK');
    }

    setOpenSnackBar(true);
    refetchParticipnat();
  };


  const handleNoteChangePage = (event, newPage) => {
    setNotePage(newPage);
  };

  const handleNoteChangeRowsPerPage = (event) => {
    setNoteRowsPerPage(+event.target.value);
    setNotePage(0);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openErrSnackBar}
        onClose={snackBarErrClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={snackBarErrClose}
          severity="error"
        >
          {clientErrMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openSnackBar}
        autoHideDuration={4000}
        onClose={snackBarCloseHandler}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={snackBarCloseHandler}
          severity="success"
        >
          {formSubmitResponse}
        </Alert>
      </Snackbar>
      <div>
        <Header />
        <>
          <div className={classes.root}>
            <Container
              className={css(AppStyles.marginTop128, AppStyles.mBottom45)}
            >
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6">Informations</Typography>
                  <Card className={classes.cardWrapper}>
                    <CardContent className={classes.cardContentWrapperTwo}>
                      <UserForm
                        formSubmitCallback={vars => {
                          updateParticipantReq({
                            variables: {
                              ...vars,
                              id: params.id,
                            },
                          });
                        }}
                        singleParticipant={singleParticipant}
                        clearFormAfterSubmit={false}
                        formSubmitBtnLabel="Mettre à jour"
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className={css(styles.TableWrpper)}>
                    <Typography variant="h6">Présences</Typography>
                    <DataGrid
                      localeText={frFR.props.MuiDataGrid.localeText}
                      rows={tableData}

                      columns={columns}
                      pageSize={10}
                      isRowSelectable={false}
                      disableSelectionOnClick={true}
                      disableColumnMenu={true}
                    />
                  </div>
                  <div className={css(styles.TableWrpper, AppStyles.pTop60)}>
                    <Typography variant="h6">Activités</Typography>
                    <DataGrid
                      localeText={frFR.props.MuiDataGrid.localeText}
                      rows={activityData}
                      columns={activityColumns}
                      pageSize={10}
                      isRowSelectable={false}
                      disableSelectionOnClick={true}
                      disableColumnMenu={true}
                    />
                  </div>
                  <div className={css(styles.TableWrpper, AppStyles.pTop60)}>
                    <Grid style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingBottom: 5
                    }}>
                      <Typography variant="h6" style={{marginBottom: -10}}>Notes</Typography>
                      <Fab
                        component="label"
                        color="primary"
                        size="small"
                        className={classes.button}
                        onClick={() => {
                          setActiveNote({});
                          setNoteModalOpen(true);
                        }}
                      >
                        <Add />
                      </Fab>
                    </Grid>
                    <Paper>
                      <TableContainer style={{ height: '240px' }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                Note
                              </TableCell>
                              <TableCell>
                                Activité
                              </TableCell>
                              <TableCell>
                                Date
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {noteData.slice(notePage * noteRowsPerPage, notePage * noteRowsPerPage + noteRowsPerPage).map((note) => {
                              let date = note.createdAt;

                              if (note.activity?.date) {
                                date = note.activity.date;
                              };

                              date = moment(date).format('YYYY-MM-DD');

                              return (
                                <TableRow onClick={() => {
                                  setActiveNote(note);
                                  setNoteModalOpen(true)
                                }} hover role="checkbox" tabIndex={-1}>
                                  <TableCell>
                                    {note.textnote}
                                  </TableCell>
                                  <TableCell>
                                    {note.activity?.title}
                                  </TableCell>
                                  <TableCell style={{ whiteSpace: "nowrap" }}>
                                    {date}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={noteData.length}
                        rowsPerPage={noteRowsPerPage}
                        page={notePage}
                        onPageChange={handleNoteChangePage}
                        onRowsPerPageChange={handleNoteChangeRowsPerPage}
                      />
                    </Paper>
                  </div>
                  <div className={css(styles.TableWrpper, AppStyles.pTop60)}>
                    <Typography variant="h6">Expulsions</Typography>
                    <DataGrid
                      localeText={frFR.props.MuiDataGrid.localeText}
                      rows={expulsionsData}
                      columns={expulsionColumns}
                      pageSize={10}
                      isRowSelectable={false}
                      disableSelectionOnClick={true}
                      disableColumnMenu={true}
                      onRowClick={({ row }) => {
                        setExtExpulsion(row);
                        handleModalOpen(true)
                      }}
                    />
                  </div>
                  <div className={css(styles.TableWrpper, AppStyles.pTop60, AppStyles.mBottom50)}>
                    <Grid style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingBottom: 5
                    }}>
                      <Typography variant="h6">Pièces jointes</Typography>

                      <Fab
                        component="label"
                        color="primary"
                        size="small"

                        className={classes.button}
                      >
                        <Add />
                        <input
                          type="file"
                          name="files"
                          onChange={e => {
                            const filesMap = e.target.files;
                            const files = Object.keys(filesMap).map(fileKey => filesMap[fileKey])
                            uploadFiles(files);
                          }}
                          hidden
                          multiple
                        />
                      </Fab>
                    </Grid>

                    <DataGrid
                      className={`notesTable participant-attachments`}
                      localeText={frFR.props.MuiDataGrid.localeText}
                      rows={files}
                      columns={filesColumns}
                      pageSize={10}
                      isRowSelectable={false}
                      disableSelectionOnClick={true}
                      disableColumnMenu={true}
                    />
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </>
        {singleParticipant && <ExpulsionModal
          open={modalOpen}
          handleClose={handleModalClose}
          participant={singleParticipant.participant}
          formSubmitCallback={expulsionFormSubmitCallback}
          extExpulsion={extExpulsion}
        />}
        {singleParticipant && <NoteModal
          note={activeNote}
          open={noteModalOpen}
          handleClose={() => {
            setNoteModalOpen(false);
          }}
          submitCallback={message => {
            refetchParticipantNotes();
            setFormSubmitResponse(message);
            setOpenSnackBar(true);
            setNoteModalOpen(false);
          }}
          deleteCallback={message => {
            refetchParticipantNotes();
            setFormSubmitResponse(message);
            setOpenSnackBar(true);
            setNoteModalOpen(false);
          }}
          participant={singleParticipant.participant}
        />}
      </div>
    </>
  );
}
