// @flow
import React from "react";
import PropTypes from "prop-types";

import ExpulsionModalView from "./ExpulsionModalView";

export default class ExpulsionModalConstroller extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    participant: PropTypes.object.isRequired,
    formSubmitCallback: PropTypes.func.isRequired,
    extExpulsion: PropTypes.object
  };

  static defaultProps = {};

  render() {
    return <ExpulsionModalView {...this.props} />;
  };
};