// @flow
import React from "react";
import PropTypes from "prop-types";

import YouthCenterFormView from "./YouthCenterFormView";

export default class YouthCenterFormController extends React.Component {
  static propTypes = {
    userID: PropTypes.number,
  };

  static defaultProps = {
    userID: undefined,
  };

  render() {
    return <YouthCenterFormView {...this.props} />;
  }
}
