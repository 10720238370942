// @flow
const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const CANCEL = "CANCEL";
const FAILURE = "FAILURE";

function createRequestTypes(base) {
  const res = {};
  [REQUEST, SUCCESS, FAILURE, CANCEL].forEach((type) => {
    res[type] = `${base}_${type}`;
  });
  return res;
}

export const GET_VEHICLES = createRequestTypes("GET_VEHICLES");

// Activity types
export const UPDATE_TODAYS_PARTICIPANTS_MAP = "UPDATE_TODAYS_PARTICIPANTS_MAP";
export const LOGIN_USER = "LOGIN_USER";
export const SET_PASSWORD_COMPLETED = "SET_PASSWORD_COMPLETED";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_USER = "UPDATE_USER";
export const SET_YC_PARTICIPANTS = "SET_YC_PARTICIPANTS";
export const SET_MERGE_MODAL_SEARCH_TEXT = "SET_MERGE_MODAL_SEARCH_TEXT";
export const UPDATE_LAYOUT = "UPDATE_LAYOUT";
