// @flow
import React from "react";
import PropTypes from "prop-types";

import UserFormView from "./UserFormView";



class UserFormController extends React.Component {
  static propTypes = {
    formSubmitCallback: PropTypes.func.isRequired,
    formSubmitBtnLabel: PropTypes.string,
    closeFormCallback: PropTypes.func,
    closeFormBtnLabel: PropTypes.string,
    singleParticipant: PropTypes.object,
    clearFormAfterSubmit:  PropTypes.bool,
  };

  static defaultProps = {
    formSubmitCallback: () => {
      console.log("Method Not Available");
    },
  };

  render() {
    return <UserFormView {...this.props} />;
  }
};

UserFormController.defaultProps = {
  clearFormAfterSubmit: true
};


export default UserFormController;