import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Grid,
  Modal,
  Fade,
  Backdrop,
  TextField,
  Box,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";

import ActionButton from "../../components/ActionButton";
import styles from "../../components/YouthCenterForm/YouthCenterFormStyles";
import { css } from "aphrodite";
import { AppStyles } from "../../theme";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: "500px",
    margin: "0 20px",
  },
}));

export default function (props) {
  const classes = useStyles();
  const user = useSelector((state) => {
    return state.user;
  });

  const { open, handleClose: closeCallback, extEtiquette, modalActions, apiEndpoint } = props;

  const [etiquette, setEtiquette] = useState("");
  const [fromErr, setFormErr] = useState("");

  const handleClose = () => {
    if (closeCallback) {
      closeCallback();
    };

    setTimeout(() => {
      setEtiquette("");
    }, 200)
  };

  const [
    createEtiquette,
    { loading: loadingCreateEtiquette, error: errCreateEtiquette },
  ] = useMutation(modalActions.CREATE, {
    onCompleted: (data) => {
      handleClose();

      if (props.formSubmitCallback) {
        props.formSubmitCallback("create");
      }
    },
  });

  const [
    updateEtiquette,
    { loading: loadingUpdateEtiquette, error: errUpdateEtiquette },
  ] = useMutation(modalActions.UPDATE, {
    onCompleted: (data) => {
      handleClose();

      if (props.formSubmitCallback) {
        props.formSubmitCallback("update");
      }
    },
  });

  const [
    deleteEtiquette,
    { loading: loadingDeleteEtiquette, error: errDeleteEtiquette },
  ] = useMutation(modalActions.DELETE, {
    onCompleted: (data) => {
      handleClose();

      if (props.formSubmitCallback) {
        props.formSubmitCallback("delete");
      }
    },
  });

  useEffect(() => {
    if (props.extEtiquette) {
      setEtiquette(props.extEtiquette.name);
    }
  }, [props.extEtiquette]);

  const validateForm = () => {
    let isValid = true;

    if (etiquette.length < 1) {
      setFormErr("Le nom de l'étiquette est requis");
      return false
    }

    return isValid;
  };

  const handleCreateEtiquette = () => {
    if (validateForm()) {
      createEtiquette({
        variables: {
          name: etiquette,
          [apiEndpoint]: user.youth_center.id,
        },
      });
    }
  };

  const handleUpdateEtiquette = () => {
    if (validateForm()) {
      updateEtiquette({
        variables: {
          id: props.extEtiquette.id,
          name: etiquette,
        },
      });
    }
  };

  const handleDeleteEtiquette = () => {
    deleteEtiquette({
      variables: {
        id: props.extEtiquette.id,
      },
    });
  };

  const renderOperationsErrors = () => {
    if (errCreateEtiquette || errUpdateEtiquette || errDeleteEtiquette) {
      console.error("errCreateEtiquette", errCreateEtiquette);
      console.error("errUpdateEtiquette", errUpdateEtiquette);
      console.error("errDeleteEtiquette", errDeleteEtiquette);

      return (
        <Grid item xs={12}>
          <span className={`${css(AppStyles.formError)}`}>
            Une erreur s'est produite lors de la mise à jour de l'étiquette.
          </span>
        </Grid>
      );
    }

    if (fromErr) {
      return (
        <Grid item xs={12}>
          <span className={`${css(AppStyles.formError)}`}>
            {fromErr}
          </span>
        </Grid>
      )
    }

    return null;
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h2 id="transition-modal-title">
            {Boolean(extEtiquette) ? `Modifier l'étiquette "${extEtiquette.name}"` : "Nouvelle étiquette" }
          </h2>
          <form className={classes.root} noValidate autoComplete="off">
            <Grid container spacing={6} justifyContent="space-between">
              <Grid item xs={12}>
                <TextField
                  id="etiquette"
                  label="Étiquette"
                  fullWidth
                  value={etiquette}
                  onChange={(e) => {
                    setEtiquette(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      props.extEtiquette ? handleUpdateEtiquette() : handleCreateEtiquette();
                    }
                  }}
                />
              </Grid>

              {renderOperationsErrors()}

              <Grid item xs={12} md={6}>
                {props.extEtiquette ? (
                  <ActionButton
                    className={css(styles.deleteBtn)}
                    title="Supprimer"
                    isLoading={loadingDeleteEtiquette}
                    onClick={handleDeleteEtiquette}
                  />
                ) : (
                  <ActionButton
                    className={css(styles.cancelButton)}
                    title="Annuler"
                    isLoading={loadingDeleteEtiquette}
                    onClick={() => handleClose()}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Box textAlign={"right"}>
                  {props.extEtiquette ? (
                    <div style={{ display: "flex" }}>
                      <ActionButton
                        className={css(styles.cancelButton)}
                        title="Annuler"
                        isLoading={loadingDeleteEtiquette}
                        onClick={() => handleClose()}
                      />
                      <ActionButton
                        className={css(styles.addBtn)}
                        title="Mise à jour"
                        isLoading={loadingUpdateEtiquette}
                        onClick={handleUpdateEtiquette}
                      />
                    </div>
                  ) : (
                    <ActionButton
                      className={css(styles.addBtn)}
                      title="Créer"
                      isLoading={loadingCreateEtiquette}
                      onClick={handleCreateEtiquette}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </div>
      </Fade>
    </Modal>
  );
}
