import React from "react";
import _ from "lodash";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
// import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Colors from "../../theme/Colors";
import moment from "moment";
import {
  ACTIVITIES,
  ACTIVITY_TYPE,
  THEMES,
  STRAPI_TIME_FORMAT,
  GOALS,
  USERS,
} from "../../constants";
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Divider,
  TextField,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import { css } from "aphrodite";
import { AppStyles } from "../../theme";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: Colors.brand.primary,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: Colors.brand.primary,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: Colors.brand.primary,
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  flexClass: { flex: 1 },
  appBarBg: {
    background: Colors.brand.primary,
  },
  inputFieldsWrapper: {
    marginTop: theme.spacing(2),
  },
  hrMargin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  hrColor: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
    fontSize: 18,
    textTransform: "uppercase",
  },

  green: {
    color: Colors.brand.primary,
  },
  formControl: {
    minWidth: "100%",
  },
  deleteBtn: {
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 400,
    boxShadow: "2px 2px 8px 1px #0000002b",
    "&:hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
}));

export default function PreFilledActivityFormView() {}
