import React, { useEffect, useState } from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, frFR } from "@material-ui/data-grid";
import { Add, CalendarToday, Close, FlagOutlined, GetApp, Launch } from "@material-ui/icons";
import * as XLSX from "xlsx";
// import { frFR } from "@mui/material/locale";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Link,
  Popover,
  TextField,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import {
  Alert,
} from "@material-ui/lab";
import Colors from "../../../../theme/Colors";
import {
  DATE_FORMAT11,
  ROUTES,
  STRAPI_DATE_FORMAT,
  TIME_FORMAT1,
  USER_PERMISSIONS,
} from "../../../../constants";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";
import "moment/locale/fr";
import { useQuery } from "@apollo/client";
import {
  GET_ATTENDANCE_DASHBOARD,
  GET_ACTIVITIES_DASHBOARD,
} from "../../../../graphql";
import { fr } from "date-fns/locale";
import styles from "./TableStyles";
import { css } from "aphrodite";
import xlsxTemplate from "../../../../assets/xlsx_templates/dashboard.xlsx";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Util from "../../../../services/Util";
moment.locale("fr");

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const columns = [
  {
    field: "date",
    headerName: "Date",
    flex: 1,
    sortable: false,
    minWidth: 200,
    renderCell: (param) => {
      return <div>{param.row.date.displayDate}</div>;
    },
  },
  {
    field: "attendance",
    headerName: "Présences",
    flex: 1,
    filterable: false,
    sortable: false,
    minWidth: 150,
  },
  {
    field: "activity",
    headerName: "Activités",
    flex: 1,
    filterable: false,
    sortable: false,
    minWidth: 150,
    renderCell: (param) => {
      return param.row.activity
    }
  },
  {
    field: "note",
    headerName: "Notes",
    flex: 1,
    filterable: false,
    sortable: false,
    minWidth: 150,
  },
  {
    field: "",
    headerName: "",
    sortable: false,
    filterable: false,
    renderCell: (param) => {
      return (
        <div
          className={css(styles.linkIconWrapper)}
        >
          <Launch color={Colors.black} />
        </div>
      );
    },
  },
];

const groupByDate = (arr, key = "date") => {
  return _.groupBy(arr, key);
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 40,
  },

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableHeading: {
    fontWeight: 600,
  },
  linkColor: {
    color: Colors.text.primary,
    marginRight: 10,
    cursor: "pointer",
  },
  deleteIconColor: {
    color: Colors.text.primary,
    cursor: "pointer",
  },
  activityBtn: {
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 600,
    height: 45,
    // width: "80%",
    boxShadow: "2px 2px 8px 1px #0000002b",
    "&:hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
  downloadBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 600,
    height: 45,
    minWidth: 45,
    borderRadius: "100%",
    boxShadow: "2px 2px 8px 1px #0000002b",
    "&:hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
  tableActionWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: 30,
  },
  datePickerWrapper: {
    flex: 1,
  },
  buttonMargin: { marginLeft: "auto" },
  dateClass: {
  },
}));

function useRouteQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function EnhancedTable() {
  const routeQuery = useRouteQuery();
  
  const fromDateQuery = routeQuery.get('from-date');
  const toDateQuery = routeQuery.get('to-date');
  const pageQuery = routeQuery.get('page');

  const user = useSelector((state) => {
    return state.user;
  });
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(() => null);
  const [sortModel, setSortModel] = React.useState(() => [
    {
      field: "date",
      sort: "desc",
    },
  ]);
  const [dateRange, setDateRange] = useState(() => ({
    startDate: moment().subtract(1, "month"),
    endDate: moment(),
  }));
  const [tableData, setTableData] = useState(() => []);
  const [attendancesMap, setAttendancesMap] = useState({})
  const [activitiesMap, setActivitiesMap] = useState({})

  const [openErrSnackBar, setOpenErrSnackBar] = useState(() => false);
  const [clientErrMsg, setClientErrMsg] = useState("");
  const [page, setPage] = useState(0);
  const [allActivities, setAllActivities] = useState(() => []);
  
  const snackBarErrClose = () => {
    setOpenErrSnackBar(false);
  };


  const { data: attendance, loading: attendancesLoading, error: errFetchAttendances } = useQuery(
    GET_ATTENDANCE_DASHBOARD(
      dateRange.startDate.format(STRAPI_DATE_FORMAT),
      dateRange.endDate.format(STRAPI_DATE_FORMAT),
      user.youth_center.id,
    ),
    {
      onCompleted: (data) => {
        const attendances = data.attendances.data.map((attendance) => {
          return {
            id: attendance.id,
            ...attendance.attributes,
            participant: attendance.attributes.participant.data?.id ? {
              id: attendance.attributes.participant.data.id,
              ...attendance.attributes.participant.data.attributes,
            } : null,
          }
        });

        let groupedAttendance = groupByDate(attendances);

        setAttendancesMap(groupedAttendance);
      },
      fetchPolicy: "network-only"
    }
  );

  const { data: activities, loading: activitiesLoading, error: errFetchActivities } = useQuery(
    GET_ACTIVITIES_DASHBOARD(
      dateRange.startDate.format(STRAPI_DATE_FORMAT),
      dateRange.endDate.format(STRAPI_DATE_FORMAT),
      user.youth_center.id,
    ),
    {
      onCompleted: (data) => {
        const activities = data.activities.data.map((activity) => {
          return Util.serialize_ACTIVITY_res(activity);
        });

        setAllActivities(activities);

        let groupedActivity = groupByDate(activities);

        setActivitiesMap(groupedActivity);
      },
      fetchPolicy: "network-only"
    }
  );

  const handlePageChange = (newPage) => {
    if (fromDateQuery && toDateQuery) {
      history.push(`?from-date="${moment(fromDateQuery).format("YYYY-MM-DD")}"&to-date="${moment(toDateQuery).format("YYYY-MM-DD")}"&page=${newPage}`)
    } else {
      history.push(`?page=${newPage}`)
    }
  }

  useEffect(() => {
    if (pageQuery) {
      setPage(parseInt(pageQuery))
    }
  }, [pageQuery]);

  useEffect(() => {
    if (fromDateQuery && toDateQuery) {
      setDateRange({
        startDate: moment(fromDateQuery),
        endDate: moment(toDateQuery),
      });
    }
  }, [fromDateQuery, toDateQuery]);

  useEffect(() => {
    let hasError = true;

    if (errFetchAttendances) {
      setClientErrMsg("Erreur lors du chargement des présences")
    } else if (errFetchActivities) {
      setClientErrMsg("Erreur lors du chargement des activités")
    } else {
      hasError = false;
    }

    if (hasError) {
      setOpenErrSnackBar(true);
    }

  }, [errFetchAttendances, errFetchActivities]);

  useEffect(() => {
    let temptableData = [];
    let tempDateRange = _.cloneDeep(dateRange);

    for (
      var m = moment(tempDateRange.endDate);
      m.isAfter(tempDateRange.startDate) ||
      m.isSame(tempDateRange.startDate, "day");
      m.subtract(1, "days")
    ) {
      const obj = {
        id: m.format("DDMMYYYY"),
        date: {
          date: m,
          displayDate: moment(m.toLocaleString())
            .locale("fr")
            .format(DATE_FORMAT11),
        },
        attendance: 0,
        activity: 0,
        needs_review: false,
        note: 0,
        detailUrl: `${ROUTES.DAY}/${m.format("DDMMYYYY")}`,
        isDeleted: false,
      };
      temptableData.push(obj);
    }

    const attendancesMapClone = _.cloneDeep({...attendancesMap});

    for (const key in attendancesMapClone) {
      let currIndex = temptableData.findIndex(
        (obj) =>
          obj.date.displayDate ===
          moment(moment(key, STRAPI_DATE_FORMAT).toLocaleString())
            .locale("fr")
            .format(DATE_FORMAT11)
      );

      if (currIndex >= 0) {
        temptableData[currIndex].attendance = attendancesMapClone[key].length;
      }
    }

    const activitiesMapClone = _.cloneDeep({...activitiesMap});

    for (const key in activitiesMapClone) {
      let currIndex = temptableData.findIndex((obj) => {
        return (
          obj.date.displayDate ===
          moment(moment(key, STRAPI_DATE_FORMAT).toLocaleString())
            .locale("fr")
            .format(DATE_FORMAT11)
        );
      });

      if (currIndex >= 0) {
        const needs_review = activitiesMapClone[key].find(obj => obj.needs_review)
        temptableData[currIndex].activity = (
          <div style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            {activitiesMapClone[key].length}
            {Boolean(needs_review) && <FlagOutlined style={{marginBottom: 8}} color="error" />}
          </div>
        )
        temptableData[currIndex].note = activitiesMapClone[key].reduce((number, activity)=> number + activity.participant_notes.length,0);
      }
    }
    setTableData(temptableData);
  }, [dateRange, attendancesMap, activitiesMap]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const customDownloadMethod = () => {
    let dashboardRows = [];
    for (let i = 0, j = tableData.length; i < j; i++) {
      if (i === 0) {
        let arr = [];
        for (let key in tableData[i]) {
          if (key !== "detailUrl" && key !== "isDeleted") {
            arr.push(_.capitalize(key));
          }
        }
        dashboardRows.push(arr);
      }

      let arr = [];
      for (let key in tableData[i]) {
        switch (key) {
          case "date":
            arr.push(tableData[i][key].displayDate);
            break;

          case "detailUrl":
            break;

          case "isDeleted":
            break;

          default:
            arr.push(tableData[i][key]);
            break;
        }
      }
      dashboardRows.push(arr);
    }

    const participantsMapping = {}
    const randomizeIds = user?.role.name === USER_PERMISSIONS.ADMINISTRATOR_LIMITED

    let attendanceRows = [["Date", "Participant ID", "Participant"]];
    for (let i = 0, j = attendance.attendances?.data?.length; i < j; i++) {
      const attn = {
        id: attendance.attendances.data[i].id,
        ...attendance.attendances.data[i].attributes,
        participant: {
          id: attendance.attendances.data[i].attributes.participant?.data?.id,
          ...attendance.attendances.data[i].attributes.participant?.data?.attributes,
        }
      }

      if (attn.participant) {
        if (randomizeIds) {
          attendanceRows.push([
            attn.date,
            Util.mapParticipantId(attn.participant, participantsMapping),
            ' ',
          ]);
        } else {
          attendanceRows.push([
            attn.date,
            attn.participant.id,
            `${attn.participant.firstName} ${attn.participant.lastName}`,
          ]);
        }
      } 
    }


    let involvementColumns = [];

    for (const activity of allActivities) {
      
      const involvementsMap = {};
      for (const involvement of activity.activity_involvements) {

        const mapObj = {
          invKeyId: involvement.id,
          invName: '',
        }

        if (involvement.activity_characteristic) {
          mapObj.invName = involvement.activity_characteristic.name;
        } else if (involvement.activity_tag) {
          mapObj.invName = involvement.activity_tag.name;
        } else if (involvement.activity_theme) {
          mapObj.invName = involvement.activity_theme.name;
        }

        involvementColumns.push(mapObj);
        involvementsMap[involvement.id] = involvement;
      }

      activity.involvementsMap = involvementsMap;
    }

    involvementColumns = involvementColumns.sort((a, b) => a.invName.localeCompare(b.invName));

    let activityRows = [
      [
        "ID",
        "Date",
        "Title",
        "Type",
        "Location",
        "Start Time",
        "End Time",
        "Themes",
        "Objectives",
        "Participants IDs",
        "Participants",
        "Observation",
        "Nombre d’ados additionnels",
        "Étiquettes pour les ados",
        "Nombre de grand public additionnels",
        "Étiquettes pour le grand public",
        "Start Date",
        "End Date",
        ...involvementColumns.map(inv => inv.invName),
      ],
    ];

    const youthCenterName = user.youth_center.Name;

    for (let i = 0, j = allActivities.length; i < j; i++) {
      let observationText = allActivities[i].observation;
      try {
        const rawObservationsState = JSON.parse(observationText);
        const blocks = rawObservationsState.blocks;
        observationText = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
      } catch (e) {
        // JSON parsing failed. Observation is already text
      }

      if (randomizeIds) {
        allActivities[i].participants = allActivities[i].participants.map(participant => ({
          ...participant,
          id: Util.mapParticipantId(participant, participantsMapping),
          firstName: '',
          lastName: '',
        }))
      }

      activityRows.push([
        allActivities[i].id,
        allActivities[i].date,
        allActivities[i].title,
        allActivities[i].activity_type.name,
        allActivities[i]?.service_point?.name || youthCenterName,
        moment(allActivities[i].start_time, "h:m:s.ms").format(
          TIME_FORMAT1
        ),
        moment(allActivities[i].end_time, "h:m:s.ms").format(
          TIME_FORMAT1
        ),
        allActivities[i].themes.reduce(
          (previousValue, currentVal, currentIndex) =>
            `${previousValue}${currentVal.name}${
              currentIndex < allActivities[i].themes.length - 1
                ? `, `
                : ``
            }`,
          ""
        ),

        allActivities[i].goals.reduce(
          (previousValue, currentVal, currentIndex) =>
            `${previousValue}${currentVal.name}${
              currentIndex < allActivities[i].goals.length - 1
                ? `, `
                : ``
            }`,
          ""
        ),

        allActivities[i].participants.reduce(
          (previousValue, currentVal, currentIndex) =>
            `${previousValue}${currentVal.id}${
              currentIndex < allActivities[i].participants.length - 1
                ? `, `
                : ``
            }`,
          ""
        ),

        allActivities[i].participants.reduce(
          (previousValue, currentVal, currentIndex) =>
            `${previousValue}${currentVal.firstName} ${currentVal.lastName}${
              currentIndex < allActivities[i].participants.length - 1
                ? `, `
                : ``
            }`,
          ""
        ),
        observationText,
        allActivities[i].youth,
        allActivities[i].youth_tags.map(y => y.name).join(', '),
        allActivities[i].grand_public,
        allActivities[i].public_tags.map(p => p.name).join(', '),
        allActivities[i].start_date || allActivities[i].date,
        allActivities[i].end_date || allActivities[i].date,
        ...involvementColumns.map(invMapObj => {

          if (allActivities[i].involvementsMap[invMapObj.invKeyId]) {
            return allActivities[i].involvementsMap[invMapObj.invKeyId].count;
          };

          return '';
        }),
      ]);
    }

    const req = new XMLHttpRequest();
    req.open("GET", xlsxTemplate, true);
    req.responseType = "arraybuffer";

    req.onload = function(e) {
      const data = new Uint8Array(req.response);
      const wb = XLSX.read(data, {type:"array"});

      const attendance_ws = wb.Sheets['Attendance'];
      XLSX.utils.sheet_add_aoa(attendance_ws, attendanceRows);

      const activity_ws = wb.Sheets['Activities'];
      XLSX.utils.sheet_add_aoa(activity_ws, activityRows);

      const dashboard_ws = wb.Sheets['Dashboard'];
      XLSX.utils.sheet_add_aoa(dashboard_ws, dashboardRows);

      XLSX.writeFile(wb, 'report.xlsx');
    }

    req.send();
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openErrSnackBar}
        onClose={snackBarErrClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={snackBarErrClose}
          severity="error"
        >
          {clientErrMsg}
        </Alert>
      </Snackbar>
      <Box sx={{ flexGrow: 1, marginTop: 30 }}>
        <Grid
          container
          spacing={4}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth={true}
              aria-describedby={id}
              onClick={handleClick}
              id="date-picker-inline"
              label="Dates affichées"
              value={`${dateRange.startDate.format(
                DATE_FORMAT11
              )} - ${dateRange.endDate.format(DATE_FORMAT11)}`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarToday />
                  </InputAdornment>
                ),
              }}
            />

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <DateRangePicker
                editableDateInputs={true}
                onChange={(item) => {
                  history.push(`?from-date=${moment(item.selection.startDate).format("YYYY-MM-DD")}&to-date=${moment(item.selection.endDate).format("YYYY-MM-DD")}&page=0`)
                }}
                moveRangeOnFirstSelection={false}
                ranges={[
                  {
                    startDate: new Date(dateRange.startDate.toString()),
                    endDate: new Date(dateRange.endDate.toString()),
                    key: "selection",
                    color: Colors.brand.primary,
                  },
                ]}
                locale={fr}
              />
            </Popover>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid item style={{minWidth: 233}}>
                {user?.role.name !== USER_PERMISSIONS.ADMINISTRATOR_LIMITED && (
                  <Button
                    href={ROUTES.DAY}
                    className={classes.activityBtn}
                    startIcon={<Add />}
                  >
                    AJOUTER UNE ACTIVITÉ
                  </Button>
                )}
              </Grid>
              <Grid item xs>
                {/* <CSVLink data={tableData} filename={"my-file.csv"}> */}
                <Button
                  onClick={customDownloadMethod}
                  className={classes.downloadBtn}
                >
                  <GetApp />
                </Button>
                {/* </CSVLink> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <div className={css(styles.TableWrpper)}>

        {(activitiesLoading || attendancesLoading)
          ?
          <div className="progress-container">
            <CircularProgress color="primary" />
          </div>
          :
          <DataGrid
            localeText={frFR.props.MuiDataGrid.localeText}
            sortingOrder={["desc", "asc", null]}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            page={page}
            onPageChange={(newPage) => handlePageChange(newPage)}
            rows={tableData}
            columns={columns}
            pageSize={10}
            isRowSelectable={false}
            disableSelectionOnClick={true}
            disableColumnMenu={true}
            className={user?.role.name === USER_PERMISSIONS.ADMINISTRATOR_LIMITED ? "disable-cursor-pointer" : ""}
            onRowClick={(param) => {
              if (user?.role.name === USER_PERMISSIONS.ADMINISTRATOR_LIMITED) {
                return null;
              }

              history.push(param.row.detailUrl);
            }}
          />
        }

      </div>
    </>
  );
}
