import Immutable from "seamless-immutable";

import { UPDATE_TODAYS_PARTICIPANTS_MAP } from "../actions/ActionTypes";

const initialState = Immutable({
  presentParticipantsMap: {},
});


export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TODAYS_PARTICIPANTS_MAP: {
      return Immutable.merge(state, {
        presentParticipantsMap: action.payload,
      });
    }
    default:
      return state;
  }
}