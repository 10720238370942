// @flow
import React from "react";
import { css } from "aphrodite";
import styles from "./LoginStyles";
import { SignIn } from "../../components";

export default function LoginView() {
  return (
    <div>
      <div className={css(styles.logInContainer)}>
        <SignIn />
      </div>
    </div>
  );
}
