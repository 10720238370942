// @flow
import React from "react";
import { Redirect } from "react-router-dom";
import { ROUTES } from "../../constants";
import PageNotFoundView from "./PageNotFoundView";

class PageNotFoundController extends React.Component {
  static propTypes = {};

  render() {
    // return <Redirect to={ROUTES.DASHBOARD} />;
    return <PageNotFoundView {...this.props} />;
  }
}

export default PageNotFoundController;
