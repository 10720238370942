// @flow
import React from "react";
import PropTypes from "prop-types";

import SignInView from "./SignInView";

export default class SignInController extends React.Component {
  static propTypes = {};

  static defaultProps = {};

  render() {
    return <SignInView {...this.props} />;
  }
}
