import React, { useState } from "react";
import {
  Avatar,
  TextField,
  Container,
  Typography,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { LockOutlined, Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { AppStyles, Colors } from "../../theme";
import { ROUTES, USER_PERMISSIONS } from "../../constants";
import { ActionButton } from "../../components";
import { css } from "aphrodite";
import styles from "./SignInStyles";
import { useMutation } from "@apollo/client";
import { LOGIN } from "../../graphql";
import Util from "../../services/Util";
import { useDispatch } from "react-redux";
import { LOGIN_USER } from "../../actions/ActionTypes";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: Colors.brand.primary,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function SignInView() {

  const dispatch = useDispatch();

  const [loginRequest] = useMutation(LOGIN, {
    onError: (e) => {
      console.error("e", e)
      setLoginError("Nom d'utilisateur ou mot de passe invalide.");
    },
    onCompleted: data => {
      if (data && data.login && data.login.jwt) {
        const role = data.login.user.role.name;
        const userId = data.login.user.id;

        const userPayload = {
          jwt: data.login.jwt,
          user: {
            ...data.login.user,
            youth_center: {
              id: data.login.user.youth_center.data.id,
              ...data.login.user.youth_center.data.attributes,
            }
          }
        }

        dispatch({
          type: LOGIN_USER,
          payload: userPayload,
        });
  

        localStorage.setItem("jwt", userPayload.jwt);
        localStorage.setItem("userId", String(userId));


        if (userPayload.user) {
          localStorage.setItem(
            "youth-center",
            JSON.stringify(userPayload.user.youth_center)
          );

          Util.setPasswordChangeRequested(userPayload.user.requestPasswordChange);

          if (role === USER_PERMISSIONS.RMJQ) {
            history.push(ROUTES.ACTIVITIES);
          } else {
            history.push(ROUTES.DASHBOARD);
          };
        } else {
          localStorage.removeItem("jwt");
          setLoginError("Aucune maison des jeunes attribuée à cet utilisateur");
        }
      }
    }
  });
  const classes = useStyles();
  const [identifier, setIdentifier] = useState("");
  const [loginError, setLoginError] = useState("");
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  // Redirection after login successfully
  const history = useHistory();

  const authFunc = (e) => {
    e.preventDefault();
    loginRequest({
      variables: { identifier: identifier, password: values.password },
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Connexion
        </Typography>
        <form
          className={classes.form}
          noValidate={false}
          onSubmit={authFunc}
          method="post"
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Courriel"
            type="email"
            name="email"
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
          />

          <TextField
            variant="outlined"
            margin="normal"
            name="password"
            type="password"
            id="password"
            fullWidth
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            onChange={handleChange("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="Mot de passe"
          />
          <ActionButton
            className={css(styles.addBtn)}
            title="S'identifier"
            type="submit"
          />
          <div className={css([AppStyles.textAlignCenter, AppStyles.mTop10])}>
            <span className={css(AppStyles.formError)}>{loginError}</span>
          </div>
        </form>
      </div>
    </Container>
  );
}

const mapStateToProps = ({ user }) => ({});

const actions = {};

export default connect(mapStateToProps, actions)(withRouter(SignInView));
