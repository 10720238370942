import React, { useState } from "react";
import {
  TextField,
} from "@material-ui/core";



const ActivityInvolvementInput = props => {

  return (
    <TextField
      id="standard-multiline-flexible"
      label={props.label}
      type="number"
      fullWidth
      value={props.count}
      onChange={(e) => {
        if (e.target.value >= 0) {
          props.handleCountUpdate({
            newCount: e.target.value,
            id: props.entityId,
            type: props.type,
          });
        }
      }}
      onBlur={() => {
        if (!props.count) {
          props.handleCountUpdate({
            newCount: 0,
            id: props.entityId,
            type: props.type,
          });
        }
      }}
      InputProps={{
        inputProps: {
          min: 0,
        },
      }}
    />
  );
}

export default ActivityInvolvementInput; 