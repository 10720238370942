import React, { useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { GRAPHQL_URI, ROUTES, USER_PERMISSIONS } from "../constants";
import {
  PageNotFound,
  Dashboard,
  Activities,
  ActivitiesListing,
  ActivitySummary,
  Participants,
  Login,
  YouthCenters,
  ParticipantsListing,
  UsersListing,
  Users,
  PasswordReset,
  PageForbidden,
  EtiquettesListing,
} from "../pages";
import LayoutWrapper from "./LayoutWrapper";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useSelector } from "react-redux";
import { onError } from "@apollo/client/link/error";
import { UPDATE_USER } from "../actions/ActionTypes";
import { Button, makeStyles } from "@material-ui/core";
import { logoutUser } from "../helpers/dataHelpers";
import { Colors } from "../theme";
import { GET_ME } from "../graphql";

const useStyles = makeStyles((theme) => ({
  activityBtn: {
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 600,
    height: 45,
    boxShadow: "2px 2px 8px 1px #0000002b",
    "&:hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
}));

const NoAuthRoute = ({ ...props }) => {
  return (
    <LayoutWrapper>
      <Route {...props} />
    </LayoutWrapper>
  );
};

const LoginRoute = ({ ...props }) => {
  const user = useSelector((state) => {
    return state.user;
  });

  if (user?.id) {
    return <Redirect to={`${ROUTES.DASHBOARD}`} />;
  }

  return (
    <LayoutWrapper>
      <Route {...props} />
    </LayoutWrapper>
  );
};

const AuthDashboardRoute = (props) => {
  const user = useSelector((state) => {
    return state.user;
  });

  if (!user?.id) {
    return <Redirect to={`${ROUTES.LOGIN}`} />;
  }

  if (user.requestPasswordChange && props.path !== ROUTES.CHANGE_PASSWORD) {
    return <Redirect to={`${ROUTES.CHANGE_PASSWORD}`} />;
  }

  if (
    props.path === ROUTES.DASHBOARD &&
    user.role.name === USER_PERMISSIONS.ADMINISTRATOR_LIMITED
  ) {
    return <Redirect to={`${ROUTES.ACTIVITIES}`} />;
  }

  if (props.restrictedTo && Array.isArray(props.restrictedTo)) {
    if (!props.restrictedTo.includes(user.role.name)) {
      return <Redirect to={`${ROUTES.FORBIDDEN}`} />;
    }
  }

  return (
    <LayoutWrapper>
      <Route {...props} />
    </LayoutWrapper>
  );
};

const httpLink = createHttpLink({
  uri: GRAPHQL_URI,
});

const errorLink = onError((err) => {
  const { graphQLErrors, networkError } = err;

  if (graphQLErrors) {
    for (const gqlErr of graphQLErrors) {
      const { message } = gqlErr;

      if (message === "Invalid token.") {
        logoutUser()
      }
    }
  } else if (networkError) {
    console.log(`[Network error]: ${networkError}`);

    if (networkError.statusCode === 401) {
      logoutUser()
    }
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("jwt");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
});

const Routes = (props) => {
  const classes = useStyles();
  const { user, repeatedProps } = props;

  if (user?.id && !user?.youth_center?.id) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
        <div style={{textAlign: 'center'}}>
          <h1>Vous n’avez pas été assigné.e à une maison des jeunes.</h1>
          <Button
            variant="contained"
            className={classes.activityBtn}
            onClick={() => {
              logoutUser();
            }}
          >
            Déconnexion
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Switch>
        <AuthDashboardRoute
          path={ROUTES.DASHBOARD}
          component={Dashboard}
          restrictedTo={[
            USER_PERMISSIONS.RMJQ,
            USER_PERMISSIONS.MDJ_TEAM,
            USER_PERMISSIONS.MDJ_DIRECTOR,
            USER_PERMISSIONS.ADMINISTRATOR,
          ]}
          {...repeatedProps}
        />

        <AuthDashboardRoute
          path={ROUTES.ETIQUETTES}
          component={EtiquettesListing}
          restrictedTo={[
            USER_PERMISSIONS.RMJQ,
            USER_PERMISSIONS.MDJ_DIRECTOR,
            USER_PERMISSIONS.ADMINISTRATOR,
            USER_PERMISSIONS.ADMINISTRATOR_LIMITED,
          ]}
          {...repeatedProps}
        />

        <AuthDashboardRoute
          path={`${ROUTES.DAY}/:date?`}
          component={Activities}
          restrictedTo={[
            USER_PERMISSIONS.MDJ_TEAM,
            USER_PERMISSIONS.MDJ_DIRECTOR,
            USER_PERMISSIONS.ADMINISTRATOR,
          ]}
          {...repeatedProps}
        />

        <AuthDashboardRoute
          path={`${ROUTES.ACTIVITIES}/:id`}
          component={ActivitySummary}
          restrictedTo={[
            USER_PERMISSIONS.RMJQ,
            USER_PERMISSIONS.MDJ_TEAM,
            USER_PERMISSIONS.MDJ_DIRECTOR,
            USER_PERMISSIONS.ADMINISTRATOR,
          ]}
          {...repeatedProps}
        />
        <AuthDashboardRoute
          path={`${ROUTES.ACTIVITIES}`}
          component={ActivitiesListing}
          {...repeatedProps}
        />

        <AuthDashboardRoute
          path={`${ROUTES.PARTICIPANTS}/:id`}
          component={Participants}
          restrictedTo={[
            USER_PERMISSIONS.MDJ_TEAM,
            USER_PERMISSIONS.MDJ_DIRECTOR,
            USER_PERMISSIONS.ADMINISTRATOR,
          ]}
          {...repeatedProps}
        />

        <AuthDashboardRoute
          path={`${ROUTES.YOUTH_CENTERS}`}
          component={YouthCenters}
          {...repeatedProps}
        />

        <AuthDashboardRoute
          path={`${ROUTES.PARTICIPANTS}`}
          component={ParticipantsListing}
          restrictedTo={[
            USER_PERMISSIONS.MDJ_TEAM,
            USER_PERMISSIONS.MDJ_DIRECTOR,
            USER_PERMISSIONS.ADMINISTRATOR,
          ]}
          {...repeatedProps}
        />

        <AuthDashboardRoute
          path={`${ROUTES.USERS}`}
          component={UsersListing}
          restrictedTo={[
            USER_PERMISSIONS.MDJ_DIRECTOR,
            USER_PERMISSIONS.ADMINISTRATOR,
            USER_PERMISSIONS.ADMINISTRATOR_LIMITED,
          ]}
          {...repeatedProps}
        />

        <AuthDashboardRoute
          path={`${ROUTES.USERS}/:id`}
          component={Users}
          restrictedTo={[
            USER_PERMISSIONS.MDJ_DIRECTOR,
            USER_PERMISSIONS.ADMINISTRATOR,
            USER_PERMISSIONS.ADMINISTRATOR_LIMITED,
          ]}
          {...repeatedProps}
        />

        <AuthDashboardRoute
          path={`${ROUTES.NEW_USER}`}
          component={Users}
          restrictedTo={[
            USER_PERMISSIONS.MDJ_DIRECTOR,
            USER_PERMISSIONS.ADMINISTRATOR,
            USER_PERMISSIONS.ADMINISTRATOR_LIMITED,
          ]}
          {...repeatedProps}
        />
        <AuthDashboardRoute
          path={ROUTES.CHANGE_PASSWORD}
          component={PasswordReset}
          {...repeatedProps}
        />

        <AuthDashboardRoute
          path={ROUTES.FORBIDDEN}
          component={PageForbidden}
          {...repeatedProps}
        />

        <LoginRoute path={ROUTES.LOGIN} component={Login} {...repeatedProps} />

        {/* Keep this in last always */}
        <NoAuthRoute path={Route.PAGE_NOT_FOUND} component={PageNotFound} />
      </Switch>
    </div>
  );
};

const Routers = ({ user }) => {
  const dipatch = useDispatch();
  const repeatedProps = {
    user,
    exact: true,
    forAdminOnly: false,
  };

  useEffect(() => {
    const fetchMe = async () => {
      try {
        const { data } = await client.query({
          query: GET_ME,
          fetchPolicy: 'no-cache',
        });

        const user = {
          ...data.me,
          youth_center: {
            id: data.me.youth_center.data.id,
            ...data.me.youth_center.data.attributes,
          }
        };

        dipatch({
          type: UPDATE_USER,
          payload: {
            user,
          },
        });
      } catch(err) {
        console.error("err", err);
        logoutUser();
      }
    }

    if (user?.id) {
      fetchMe();
    }
  }, [user?.id]);

  return (
    <Router>
      <ApolloProvider client={client}>
        <Routes repeatedProps={repeatedProps} user={user} />
      </ApolloProvider>
    </Router>
  );
};

const mapStateToProps = ({ user }) => ({
  user,
});

const actions = {};

export default connect(mapStateToProps, actions)(Routers);
