// @flow
import _ from "lodash";
import React from "react";
import LoginView from "./LoginView";

class LoginController extends React.Component {
  static propTypes = {};

  render() {
    return <LoginView {...this.props} />;
  }
}

export default LoginController;
