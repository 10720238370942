// @flow
import _ from "lodash";
import moment from "moment";
import Swal from "sweetalert2";

import {
  MESSAGE_TYPES,
  TIME_FORMAT2,
  JOB_STATUS_TYPES,
  DATE_FORMAT3,
  ROUTES,
  TIME_FORMAT3,
  DATE_TIME_FORMAT2,
  TIME_FORMAT1,
  DATE_FORMAT1,
  TIME_DAY_FORMAT3,
  DATE_TIME_FORMAT7,
  BACKEND_URI,
} from "../constants";
import DataHandler from "../services/DataHandler";

class Util {
  keyExtractor = (item, index) => index.toString();

  /**
   *
   * @param {string} phone
   * @return {string}
   */
  formatPhone = (phone) => {
    return phone;
    return `+${phone}`;
  };

  isValidURL(url) {
    // eslint-disable-next-line no-useless-escape
    const re =
      /^(http|https|fttp):\/\/|[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?$/;
    return re.test(url);
  }

  isValidHttpsURL(url) {
    // eslint-disable-next-line no-useless-escape
    const re =
      /^(https|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
    return re.test(url);
  }

  isTimeFormat(time) {
    // eslint-disable-next-line no-useless-escape

    const re =
      /^([1-9]|([012][0-9])|(3[01]))\/([0]{0,1}[1-9]|1[012])\/[0-9]{4} [012]{0,1}[0-9]:[0-6][0-9]$/;
    let bol = re.test(time);
    return bol;
  }

  isEmailValid(email) {
    // eslint-disable-next-line no-useless-escape
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.trim());
  }
  isPasswordValid(password) {
    return password.length > 5;
  }
  isValidName(name) {
    // return /^(?!\s+$)[A-Za-zăâîșțĂÂÎȘȚ\s-]+$/.test(name);
    return /^[a-zA-Z0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'\)\(!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/.test(
      name
    );
  }
  isValidObservation(name) {
    return /^[a-zA-Z0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ'\)\(!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\n]+$/.test(
      name
    );
  }
  isValidAddress(address) {
    return /^[^\s]+(\s+[^\s]+)*$/.test(address);
  }
  isValidCityName(name) {
    // return /^(?!\s+$)[A-Za-zăâîșțĂÂÎȘȚ\s-]+$/.test(name);
    return /^(?!\s+$)[a-zA-ZÀ-ÿ-. \']+$/.test(name);
  }
  isValidPostCode(val) {
    return /^[a-zA-Z0-9 ]*$/.test(val);
  }

  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return "";
  }

  getFormattedDateTime = (date, format) => {
    if (date) return moment(date).format(format);
    return "";
  };

  getDateObjectFromString = (date, format) => {
    if (date) return moment(date, format).toDate();
    return "";
  };
  /* 
  getCurrentUserAccessToken() {
    return DataHandler.getStore().getState().user.data.access_token;
  }*/

  getCurrentAccessToken() {
    let token = DataHandler.getStore().getState().user.data.access_token;
    return token;
  }
  getCurrentRefreshToken() {
    let token = DataHandler.getStore().getState().user.data.refresh_token;

    return token;
  }
  
  setPasswordChangeRequested(value) {
    localStorage.setItem("requestPasswordChange", String(Boolean(value)));
  }
  isPasswordChangeRequested() {
    return localStorage.getItem("requestPasswordChange") === "true";
  }

  isNumber(val) {
    return /^\d+$/.test(val);
  }

  isValidPhoneNumber(val) {
    return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(val);
  }

  generateGetParameter(obj) {
    let final = "?";
    for (const key in obj) {
      final = `${final}${key}=${obj[key]}&`;
    }
    final = final.slice(0, -1);
    return final;
  }

  isValidMobileNumber(str) {
    if (!str) return false;
    const isnum = /^\d+$/.test(str);

    if (str.length < 15 && str.length > 9 && isnum) {
      return true;
    }
    return false;
  }

  isValidUKMobileNumber(str) {
    if (!str) return false;
    str = str.replace(/ /g, "");
    let mobileNumber = str.replace("+", "");
    // Number begins with 44
    if (mobileNumber.charAt(0) == "4" && mobileNumber.charAt(1) == "4") {
      mobileNumber = "0" + mobileNumber.slice(2);
    }
    // return /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/.test(mobileNumber);
    return /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/.test(
      mobileNumber
    );
  }

  // async getReq() {
  //   let options = Object.assign({ method: 'POST' });
  //   options.credentials = 'include';
  //   options.headers = {
  //     Accept: 'application/json',
  //     'Content-Type': 'application/json',
  //     dataType: 'json'
  //   };
  //   let data = {};
  //   options.body = JSON.stringify(data);
  //   const response = await fetch(
  //     `https://kiffgo-development.herokuapp.com/b/login-website`,
  //     options
  //   );
  //   const responseJson = await response.json();
  //   return responseJson._csrf;
  // }
  generateGuid() {
    const S4 = () =>
      (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    return (
      S4() +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      S4() +
      S4()
    );
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180);
  } // function for getting radians from degrees

  isEven = (n) => n % 2 === 0;

  isOdd = (n) => Math.abs(n % 2) === 1;

  penceToPoundsWithDecimal = (value) =>
    value > 0 ? `£ ${(value / 100).toFixed(2)}` : `£ 0`;

  topAlert = (message, type = MESSAGE_TYPES.SUCCESS) => {
    let title = "";
    if (type === MESSAGE_TYPES.SUCCESS) {
      title = "Success";
    } else if (type === MESSAGE_TYPES.ERROR) {
      title = "Error";
    } else if (type === MESSAGE_TYPES.INFO) {
      title = "Info";
    }
    this.dmInformAlert(title, message, null, null);
  };

  topAlertError = (message) => {
    this.topAlert(message, MESSAGE_TYPES.ERROR);
  };

  getFormattedDurationFromMins = (duration, format) => {
    if (duration) {
      return moment
        .utc(moment.duration(duration, "minutes").asMilliseconds())
        .format(TIME_FORMAT2);
    }

    return "";
  };

  getFormattedDurationFromHour = (duration, format) => {
    if (duration) {
      return moment
        .utc(moment.duration(duration, "hours").asMilliseconds())
        .format(TIME_FORMAT2);
    }

    return "";
  };

  dmConfirmAlert = (
    title,
    text,
    confirmButtonText,
    onConfirmPress,
    showCancelButton = true
  ) => {
    const obj = {
      background: "rgba(52, 52, 52, 1)",
      position: "top",
      title: title,
      text: text,
      reverseButtons: true,
      showCancelButton,
      confirmButtonText: confirmButtonText ? confirmButtonText : "OK",
      customClass: {
        container: "dm_swl_container",
        popup: "dm_swl_popup",
        header: "dm_swl_header",
        title: "dm_swl_title",
        closeButton: "dm_swl_closeButton",
        icon: "dm_swl_icon",
        image: "dm_swl_image",
        content: "dm_swl_content",
        input: "dm_swl_input",
        actions: "dm_swl_actions",
        confirmButton: "dm_swl_confirmButton",
        cancelButton: "dm_swl_cancelButton",
        footer: "dm_swl_footer",
      },
    };

    Swal.fire(obj).then((yes) => {
      if (
        yes.value &&
        !_.isUndefined(onConfirmPress) &&
        onConfirmPress != null
      ) {
        onConfirmPress();
      }
    });
  };

  dmInformAlert = (title, text, confirmButtonText, onConfirmPress) => {
    this.dmConfirmAlert(title, text, confirmButtonText, onConfirmPress, false);
  };

  unitFormat = (value, unit, isSuffix) => {
    if (isSuffix) {
      return `${value} ${unit}`;
    } else {
      return `${unit} ${value}`;
    }
  };

  getMiles(i) {
    if (i) {
      if (i > 90) {
        return Math.round(i * 0.000621371192 * 10) / 10;
      }
      // when value is less than 90 it gived 0 miles so we override that value with 0.1
      return 0.1;
    }

    return 0;
  }

  getJobTypeTitle = (type) => {
    for (let key in JOB_STATUS_TYPES) {
      if (JOB_STATUS_TYPES.hasOwnProperty(key)) {
        if (JOB_STATUS_TYPES[key].type === type) {
          return JOB_STATUS_TYPES[key].title;
        }
      }
    }
    return null;
  };

  dateParser = (date, format = DATE_FORMAT3) => {
    const isToday = moment().isSame(moment(date), "day");
    return isToday ? "Today" : this.getFormattedDateTime(date, format);
  };

  dateParserTwo = (date) => {
    const isToday = moment().isSame(moment(date), "day");
    const isTomorrow = moment().add(1, "day").isSame(moment(date), "day");

    if (isToday) {
      return `${this.getFormattedDateTime(date, TIME_FORMAT1)} - Today`;
    } else if (isTomorrow) {
      return `${this.getFormattedDateTime(date, TIME_FORMAT1)} - Tomorrow`;
    } else {
      return this.getFormattedDateTime(date, DATE_FORMAT1);
    }
  };

  dateParserThree = (date) => {
    const isToday = moment().isSame(moment(date), "day");
    const isTomorrow = moment().add(1, "day").isSame(moment(date), "day");

    if (isToday) {
      return `${this.getFormattedDateTime(date, TIME_FORMAT1)}`;
    } else if (isTomorrow) {
      return `Tom - ${this.getFormattedDateTime(date, TIME_FORMAT1)}`;
    } else {
      return this.getFormattedDateTime(date, TIME_DAY_FORMAT3);
    }
  };

  timeWindowDateParser = (date) => {
    const isToday = moment().isSame(moment(date), "day");
    const isTomorrow = moment().add(1, "day").isSame(moment(date), "day");

    const isYesterday = moment().subtract(1, "day").isSame(moment(date), "day");

    if (isToday) {
      return `${this.getFormattedDateTime(date, TIME_FORMAT1)}`;
    } else if (isTomorrow) {
      return `${this.getFormattedDateTime(date, TIME_FORMAT1)}(Tom.)`;
    } else if (isYesterday) {
      return `${this.getFormattedDateTime(date, TIME_FORMAT1)}(Yes.)`;
    } else {
      return this.getFormattedDateTime(date, DATE_TIME_FORMAT7);
    }
  };

  getCurrentUrl = () => {
    return window.location.origin;
  };

  createStopTrackingUrl = (uniqueUrl) => {
    return `${this.getCurrentUrl()}${ROUTES.PUBLIC_TRACKING}/s/${uniqueUrl}`;
  };

  createJobTrackingUrl = (uniqueUrl) => {
    return `${this.getCurrentUrl()}${ROUTES.PUBLIC_TRACKING}/j/${uniqueUrl}`;
  };

  checkValueExits = (value, defaults = "") => {
    if (value) {
      return value;
    }
    return defaults;
  };

  addFocusListener = (onFocus) => {
    window.removeEventListener("focus", onFocus);

    setTimeout(() => {
      window.addEventListener("focus", onFocus);
    }, 500);
  };

  findDay = (date) => {
    const momentDate = moment(date);
    const isToday = moment().isSame(momentDate, "day");
    const isTomorrow = moment().add(1, "day").isSame(momentDate, "day");
    const isYesterday = moment().subtract(1, "day").isSame(momentDate, "day");

    let day = "";

    if (isToday) {
      day = `Today ${momentDate.format(TIME_FORMAT3)}`;
      return day;
    } else if (isTomorrow) {
      day = `Tomorrow ${momentDate.format(TIME_FORMAT3)}`;
      return day;
    } else if (isYesterday) {
      day = `Yesterday ${momentDate.format(TIME_FORMAT3)}`;
      return day;
    }

    return momentDate.format(DATE_TIME_FORMAT2);
  };

  mmToMeter = (mm) => {
    let result = 0;

    result = mm / 1000;
    result = result.toFixed(2);
    return result + "m";
  };

  makeAddressString = (AddressText, businessName) => {
    let index = AddressText.indexOf(businessName);
    let newStr = AddressText.replace(businessName, "");

    let bol = true;
    while (bol) {
      if (newStr[index] === " " || newStr[index] === ",") {
        newStr = newStr.replace(newStr[index], "");
      } else {
        bol = false;
      }
    }
    return newStr;
  };

  getSingularPluralText(quantity, text) {
    return quantity > 1 ? `${text}s` : text;
  }

  getFormattedPhone = (phone) => {
    // const first2Numbers = phone.substring(0, 2);
    // const next4Numbers = phone.substring(2, 6);
    // const remainingNumbers = phone.substring(6, phone.length);
    // return `+${first2Numbers} ${next4Numbers} ${remainingNumbers}`;
    return `+${phone}`;
  };
  isLoggedIn = () => {
    const jwToken = localStorage.getItem("jwt");

    if (_.isEmpty(jwToken)) {
      return false;
    } else {
      return true;
    }
  };

  mapParticipantId = (participant, participantsMapping = {}) => {
    let replacedId = 0;

    if (participantsMapping[participant.id]) {
      replacedId = participantsMapping[participant.id]
    } else {
      replacedId = Math.floor((Math.random() * 10000) + 1)
      participantsMapping[participant.id] = replacedId
    }

    return replacedId;
  };

  fetchParticipantsSearch = async ({
    user,
    searchTerm = '',
    page = 1,
    pageSize = 20,
    showArchived = false,
    isMerge = false,
  }) => {
    const newResBuff = await fetch(`${BACKEND_URI}/api/search-participants-by-name`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.jwt,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        youthCenterId: user.youth_center.id,
        name: searchTerm,
        archived: showArchived,
        isMerge,
        pagination: {
          page,
          pageSize,
        },
      }),
    });
    const newRes = await newResBuff.json();

    return newRes;
  };

  serialize_ACTIVITY_res = (activityRaw) => {
    const activityAttr = activityRaw.attributes;
    const activity = {
      id: activityRaw.id,
      ...activityAttr,
    };

    if (activityAttr.files?.data) {
      activity.files = activityAttr.files.data.map((file) => {
        return {
          id: file.id,
          ...file.attributes,
        };
      });
    }

    if (activityAttr.activity_involvements?.data) {
      activity.activity_involvements = activityAttr.activity_involvements.data.map(
        (ai) => {
          const aiRes = {
            id: ai.id,
            count: ai.attributes.count,
          };

          if (ai.attributes.activity_theme?.data) {
            aiRes.activity_theme = {
              id: ai.attributes.activity_theme.data.id,
              ...ai.attributes.activity_theme.data.attributes,
            };
          }

          if (ai.attributes.activity_tag?.data) {
            aiRes.activity_tag = {
              id: ai.attributes.activity_tag.data.id,
              ...ai.attributes.activity_tag.data.attributes,
            };
          }

          if (ai.attributes.activity_characteristic?.data) {
            aiRes.activity_characteristic = {
              id: ai.attributes.activity_characteristic.data.id,
              ...ai.attributes.activity_characteristic.data.attributes,
            };
          }

          return aiRes;
        }
      );
    }

    if (activityAttr.activity_type?.data) {
      activity.activity_type = {
        id: activityAttr.activity_type.data.id,
        ...activityAttr.activity_type.data.attributes,
      };
    }

    if (activityAttr.activity_scenario?.data) {
      activity.activity_scenario = {
        id: activityAttr.activity_scenario.data.id,
        ...activityAttr.activity_scenario.data.attributes,
      };
    }

    if (activityAttr.service_point?.data) {
      activity.service_point = {
        id: activityAttr.service_point.data.id,
        ...activityAttr.service_point.data.attributes,
      };
    }

    if (activityAttr.public_tags?.data) {
      activity.public_tags = activityAttr.public_tags.data.map((pt) => {
        return {
          id: pt.id,
          ...pt.attributes,
        }
      });
    }

    if (activityAttr.youth_tags?.data) {
      activity.youth_tags = activityAttr.youth_tags.data.map((yt) => {
        return {
          id: yt.id,
          ...yt.attributes,
        }
      });
    }

    if (activityAttr.themes?.data) {
      activity.themes = activityAttr.themes.data.map((t) => {
        return {
          id: t.id,
          ...t.attributes,
        }
      });
    }

    if (activityAttr.activity_tags?.data) {
      activity.activity_tags = activityAttr.activity_tags.data.map((at) => {
        return {
          id: at.id,
          ...at.attributes,
        }
      });
    }

    if (activityAttr.activity_characteristics?.data) {
      activity.activity_characteristics = activityAttr.activity_characteristics.data.map((ac) => {
        return {
          id: ac.id,
          ...ac.attributes,
        }
      });
    }

    if (activityAttr.goals?.data) {
      activity.goals = activityAttr.goals.data.map((g) => {
        return {
          id: g.id,
          ...g.attributes,
        }
      });
    }

    if (activityAttr.participants?.data) {
      activity.participants = activityAttr.participants.data.map((p) => {
        const participant = {
          id: p.id,
          ...p.attributes,
        };

        if (p.attributes.participant_types?.data) {
          participant.participant_types = p.attributes.participant_types.data.map(
            (pt) => {
              return {
                id: pt.id,
                ...pt.attributes,
              };
            }
          );
        }

        if (p.attributes.disabilities?.data) {
          participant.disabilities = p.attributes.disabilities.data.map(
            (pd) => {
              return {
                id: pd.id,
                ...pd.attributes,
              };
            }
          );
        }

        return participant;
      });
    }


    if (activityAttr.participant_notes?.data) {
      activity.participant_notes = activityAttr.participant_notes.data.map(
        (note) => {
          return {
            id: note.id,
            ...note.attributes,
          };
        }
      );
    }

    return activity;
  };

  serialize_PARTICIPANT_res = (data) => {
    const pl = data?.participant?.data || data.createParticipant?.data || data;
    const participant = {
      id: pl.id,
      ...pl.attributes,
    };

    if (pl.attributes.files?.data) {
      participant.files = pl.attributes.files.data.map(
        (file) => {
          return {
            id: file.id,
            ...file.attributes,
          };
        }
      );
    }

    if (pl.attributes.expulsions?.data) {
      participant.expulsions = pl.attributes.expulsions.data.map(
        (expulsion) => {
          return {
            id: expulsion.id,
            ...expulsion.attributes,
          };
        }
      );
    }

    if (pl.attributes.attendances?.data) {
      participant.attendances = pl.attributes.attendances.data.map(
        (attendance) => {
          const res = {
            id: attendance.id,
            ...attendance.attributes,
          };

          if (attendance.attributes.service_point?.data) {
            res.service_point = {
              id: attendance.attributes.service_point.data.id,
              ...attendance.attributes.service_point.data.attributes,
            };
          }

          if (attendance.attributes.youth_center?.data) {
            res.youth_center = {
              id: attendance.attributes.youth_center.data.id,
              ...attendance.attributes.youth_center.data.attributes,
            };
          }

          return res;
        }
      );
    }

    if (pl.attributes.participant_tags?.data) {
      participant.participant_tags = pl.attributes.participant_tags.data.map(
        (tag) => {
          return {
            id: tag.id,
            ...tag.attributes,
          };
        }
      );
    }

    if (pl.attributes.participant_types?.data) {
      participant.participant_types = pl.attributes.participant_types.data.map(
        (type) => {
          return {
            id: type.id,
            ...type.attributes,
          };
        }
      );
    }

    if (pl.attributes.disabilities?.data) {
      participant.disabilities = pl.attributes.disabilities.data.map(
        (disability) => {
          return {
            id: disability.id,
            ...disability.attributes,
          };
        }
      );
    }

    if (pl.attributes.province?.data) {
      participant.province = {
        id: pl.attributes.province.data.id,
        ...pl.attributes.province.data.attributes,
      };
    }

    if (pl.attributes.participant_notes?.data) {
      participant.participant_notes = pl.attributes.participant_notes.data.map(
        (note) => {
          return {
            id: note.id,
            ...note.attributes,
          };
        }
      );
    }

    return participant;
  }
}

export default new Util();
