import { getWebInstrumentations, initializeFaro, ReactIntegration, FaroErrorBoundary } from '@grafana/faro-react';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const FARO_COLLECTOR_URL = process.env.REACT_APP_FARO_COLLECTOR_URL || null;
const FARO_ENV = process.env.REACT_APP_FARO_ENV || 'rmjq';

if (!FARO_COLLECTOR_URL) {
  console.warn('Faro collector URL is not set');
}
initializeFaro({
  url: FARO_COLLECTOR_URL,
  app: {
    name: FARO_ENV,
    version: '1.0.0',
    environment: 'production'
  },
  instrumentations: [
    // Load the default Web instrumentations
    ...getWebInstrumentations(),

    // Tracing Instrumentation is needed if you want to use the React Profiler
    new TracingInstrumentation(),

    new ReactIntegration(),
  ],
});


ReactDOM.render(
  <React.StrictMode>
    <FaroErrorBoundary>
      <App />
    </FaroErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
