// @flow
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import ParticipantsListingView from "./ParticipantsListingView";

export default class ParticipantsListingController extends React.Component {
  static propTypes = {};

  static defaultProps = {};
  render() {
    return <ParticipantsListingView {...this.props} />;
  }
}
