import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button } from "@material-ui/core";

import "./index.css";

const CookieConsent = () => {
  const { shouldShowCookieConsent } = useSelector((state) => state.layout);
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem("shouldShowCookieConsent", shouldShowCookieConsent);
  }, [shouldShowCookieConsent]);

  if (shouldShowCookieConsent) {
    return (
      <Box className="cookie-consent">
        <Box className="cookie-consent__text">
          Ce site Web utilise des témoins (cookies) pour en assurer le bon
          fonctionnement et une expérience utilisateur optimale. Par votre accès
          au site, vous acceptez l’utilisation de tels témoins.
        </Box>
        <Button
          className="cookie-consent__close"
          onClick={() => {
            dispatch({
              type: "UPDATE_LAYOUT",
              payload: {
                shouldShowCookieConsent: false,
              },
            });
          }}
        >
          Fermer
        </Button>
      </Box>
    );
  }

  return null;
};

export default CookieConsent;
