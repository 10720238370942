import { combineReducers } from "redux";

import activity from "./activity";
import user from "./user";
import youth_center from "./youth_center";
import layout from "./layout";

export default combineReducers({
  user,
  layout,
  activity,
  youth_center,
});
