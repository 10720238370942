import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, frFR } from "@material-ui/data-grid";
import { Add } from "@material-ui/icons";

import { Colors } from "../../theme";
import {
  Button,
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Snackbar,
  Tabs,
  Tab,
  Box
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { css } from "aphrodite";
import { AppStyles } from "../../theme";
import { useQuery } from "@apollo/client";
import {
  CREATE_ETIQUETTE,
  DELETE_ETIQUETTE,
  UPDATE_ETIQUETTE,
  GLOBAL_ETIQUETTES_LISTING,
  CREATE_YOUTH_TAGS,
  CREATE_PUBLIC_TAGS,
  CREATE_PARTICIPANT_TAG,
  UPDATE_YOUTH_TAG,
  DELETE_YOUTH_TAG,
  UPDATE_PUBLIC_TAG,
  DELETE_PUBLIC_TAG,
  UPDATE_PARTICIPANT_TAG,
  DELETE_PARTICIPANT_TAG
} from "../../graphql";
import styles from "./EtiquettesListingStyles";
import { Header } from "../../components";
import _ from "lodash";
import { useSelector } from "react-redux";
import EtiquetteModal from "./EtiquetteModal";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  flexClass: { flex: 1 },
  appBarBg: {
    background: Colors.brand.primary,
  },
  inputFieldsWrapper: {
    marginTop: theme.spacing(2),
  },
  hrMargin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  hrColor: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  green: {
    color: Colors.brand.primary,
  },
  formControl: {
    minWidth: "100%",
  },
  deleteBtn: {
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 400,
    boxShadow: "2px 2px 8px 1px #0000002b",
    "&:hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
  cardWrapper: {
    boxShadow: "1px 1px 9px 2px #0000001a",
    borderRadius: 7,
  },
  cardContentWrapper: {
    padding: "16px 26px",
  },
  addBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 600,
    height: 45,
    minWidth: 45,
    borderRadius: "100%",
    boxShadow: "2px 2px 8px 1px #0000002b",
    marginLeft: "auto",
    "&:hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
  title: {
    color: "rgba(0, 0, 0, 0.54)",
    padding: 0,
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "0.00938em",
    transform: "translate(0, 1.5px) scale(0.75)",
    transformOrigin: "top left",
  },
}));
  
function a11yProps(index) {
  return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function EtiquettesListingView(props) {
  const user = useSelector((state) => {
    return state.user;
  });
  const classes = useStyles();

  const [tableData, setTableData] = useState(() => []);
  const [openErrSnackBar, setOpenErrSnackBar] = useState(() => false);
  const [clientErrMsg, setClientErrMsg] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [modalEtiquette, setModalEtiquette] = useState(null);
  const [globalEtiquettes, setGlobalEtiquettes] = useState([]);
  const [tab, setTab] = useState("activity-tags");
  const [modalActions, setModalActions] = useState({
    CREATE: CREATE_ETIQUETTE,
    UPDATE: UPDATE_ETIQUETTE,
    DELETE: DELETE_ETIQUETTE
  });

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const snackBarErrClose = () => {
    setOpenErrSnackBar(false);
  };

  const apiEndpoint = useMemo(() => {
    return ['participant-tags', 'activity-tags'].includes(tab) ? 'youth_centers' : 'youth_center';
  }, [tab]);

  const fetchData = async ({endpoint}) => {
    const operator = ['participant-tags', 'activity-tags'].includes(endpoint) ? '$in' : '$eq';
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URI}/api/${endpoint}?filters[${apiEndpoint}][id][${operator}]=${user.youth_center.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.jwt}`,
      },
    });

    const data = await response.json();
    setTableData(data.data.map(at => {
      return {
        id: at.id,
        ...at.attributes,
      };
    }));

    if (!response.ok) {
      throw new Error(data.message);
    }
  };

  useEffect(() => {
    if(tab === "activity-tags") {
      fetchData({ endpoint: tab });
      setModalActions({
        CREATE: CREATE_ETIQUETTE,
        UPDATE: UPDATE_ETIQUETTE,
        DELETE: DELETE_ETIQUETTE
      });
    }

    if(tab === "youth-tags") {
      fetchData({ endpoint: tab });
      setModalActions({
        CREATE: CREATE_YOUTH_TAGS,
        UPDATE: UPDATE_YOUTH_TAG,
        DELETE: DELETE_YOUTH_TAG
      });
    }
  
    if(tab === "public-tags") {
      fetchData({ endpoint: tab });
      setModalActions({
        CREATE: CREATE_PUBLIC_TAGS,
        UPDATE: UPDATE_PUBLIC_TAG,
        DELETE: DELETE_PUBLIC_TAG
      });
    }
  
    if(tab === "participant-tags") {
      fetchData({ endpoint: tab });
      setModalActions({
        CREATE: CREATE_PARTICIPANT_TAG,
        UPDATE: UPDATE_PARTICIPANT_TAG,
        DELETE: DELETE_PARTICIPANT_TAG
      });
    }

  }, [tab]);

  useQuery(GLOBAL_ETIQUETTES_LISTING(), {
    onCompleted: (data) => {
      setGlobalEtiquettes(data.activityTags.data.map(at => {
        return {
          id: at.id,
          ...at.attributes,
        };
      }));
    },
    fetchPolicy: "network-only",
  });

  const columns = [
    {
      key: "id",
      field: "id",
      headerName: "ID",
      sortable: false,
    },
    {
      key: "name",
      field: "name",
      headerName: "Nom",
      minWidth: 300,
      renderCell: (param) => {
        return <div>{`${param.row?.name}`}</div>;
      },
    },
    {
      key: "global",
      field: "is_global",
      minWidth: 200,
      headerName: " ",
      renderCell: (param) => {
        if (param.row?.is_global) {
          return "Étiquette globale";
        }

        return "";
      },
    },
  ];

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openErrSnackBar}
        onClose={snackBarErrClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={snackBarErrClose}
          severity="error"
        >
          {clientErrMsg}
        </Alert>
      </Snackbar>
      <Header />
      <>
        <div className={classes.root}>
          <Container maxWidth="lg" className={css(AppStyles.marginTop128)}>
            <Card className={classes.cardWrapper}>
              <CardContent className={classes.cardContentWrapper}>
                <Grid
                  style={{ marginTop: "20px", width: "100%" }}
                  container
                  spacing={4}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs={12} md={11}>
                    <Typography variant="h4" className={classes.titleone}>
                      Étiquettes
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={1} alignItems="flex-end">
                    <Button
                      onClick={() => {
                        setOpenModal(true);
                      }}
                      className={classes.addBtn}
                    >
                      <Add />
                    </Button>
                  </Grid>
                </Grid>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs">
                            <Tab value="activity-tags" label="Activités" {...a11yProps(0)} />
                            <Tab value="youth-tags" label="Ados additionnels" {...a11yProps(1)} />
                            <Tab value="public-tags" label="Grand public additionnels" {...a11yProps(2)} />
                            <Tab value="participant-tags" label="Participants" {...a11yProps(3)} />
                        </Tabs>
                    </Box>
                    
                </Box>
                <div className={css(styles.TableWrpper)}>
                  <DataGrid
                      className={`usersTable`}
                      localeText={frFR.props.MuiDataGrid.localeText}
                      rows={tableData}
                      columns={columns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[10, 50, 100]}
                      isRowSelectable={false}
                      disableSelectionOnClick={true}
                      disableColumnMenu={true}
                      sortingOrder={["desc", "asc", null]}
                      onRowClick={(param) => {
                        if (!param.row.is_global) {
                          setModalEtiquette(param.row);
                          setOpenModal(true);
                        }
                      }}
                      getRowClassName={(params) => {
                        if (params.row.is_global) {
                          return "global-etiquette-disabled";
                        }
                      }}
                    />
                </div>
              </CardContent>
            </Card>
          </Container>
        </div>
      </>
      <EtiquetteModal
        open={openModal}
        modalActions={modalActions}
        apiEndpoint={apiEndpoint}
        extEtiquette={modalEtiquette}
        handleClose={() => {
          setOpenModal(false);
          setModalEtiquette(null);
        }}
        formSubmitCallback={() => {
          fetchData({endpoint: tab});
        }}
      />
    </div>
  );
}
