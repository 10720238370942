// @flow
import React from "react";
import PropTypes from "prop-types";

import ParticipantThreeDotMenuView from "./ParticipantThreeDotMenuView";

export default class ParticipantThreeDotMenuController extends React.Component {
  static propTypes = {
    participant: PropTypes.object.isRequired,
    handleArchivation: PropTypes.func.isRequired,
    formSubmitCallback: PropTypes.func.isRequired,
    openProfileInNewTab: PropTypes.bool,
  };

  static defaultProps = {};

  render() {
    return <ParticipantThreeDotMenuView {...this.props} />;
  };
};
