import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../theme/Colors";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Snackbar,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { ActionButton } from "../../components";
import { css } from "aphrodite";
import { AppStyles } from "../../theme";
import styles from "./UserFormStyles";
import {
  PROVINCE,
  PARTICIPANT_TYPES,
  DISABILITIES,
  CREATE_PARTICIPANT_TAG,
  GET_ALL_PARTICIPANT_TAGS,
} from "../../graphql";
import { useMutation, useQuery } from "@apollo/client";
import {
  INVALID_FIRSTNAME,
  INVALID_DOB,
  INVALID_ADDRESS,
  INVALID_POSTALCODE,
  INVALID_CITY,
  INVALID_EMAIL,
  INVALID_PHONE,
  FORM_VALIDATION_FAILED,
} from "../../constants";
import Util from "../../services/Util";
import frLocale from "date-fns/locale/fr";
import { Alert, Autocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { getSortedOptions } from "../../helpers/dataHelpers";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  flexClass: { flex: 1 },
  appBarBg: {
    background: Colors.brand.primary,
  },
  inputFieldsWrapper: {
    marginTop: theme.spacing(2),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  otherField: {
    marginTop: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
    fontSize: 18,
    textTransform: "uppercase",
  },

  green: {
    color: Colors.brand.primary,
  },
  formControl: {
    minWidth: "100%",
  },

  genderLabel: {
    marginBottom: 8,
  },
}));

export default function UserFormView(props) {
  const classes = useStyles();

  const user = useSelector((state) => {
    return state.user;
  });

  const [allProvinces, setAllProvinces] = useState([]);
  useQuery(PROVINCE, {
    onCompleted: (data) => {
      setAllProvinces(data.provinces.data.map(item => {
        return {
          id: item.id,
          ...item.attributes,
        }
      }));
    }
  });
  const [firstName, setFirstName] = useState(() => "");
  const [lastName, setLastName] = useState(() => "");
  const [gender, setGender] = useState("");
  const [other, setOther] = useState("");
  const [address, setAddress] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pronoms, setPronoms] = useState("");
  const [city, setCity] = useState("");
  const [postCode, setPostCode] = useState("");
  const [province, setProvince] = useState(() => "");
  const [participantTypes, setParticipantTypes] = useState(() => []);
  const [disabilities, setDisabilities] = useState(() => []);
  const [DOB, setDOB] = useState(new Date('2000-01-01'));
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const genderRef = useRef(null);
  const addressRef = useRef(null);
  const cityRef = useRef(null);
  const postCodeRef = useRef(null);
  const provinceRef = useRef(null);
  const participantTypesRef = useRef(null);
  const disabilitiesRef = useRef(null);
  const emailAddRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const [isValidFirstName, setIsValidFirstName] = useState(() => true);
  const [isValidYear, setIsValidYear] = useState(() => true);
  const [isValidAddress, setIsValidAddress] = useState(() => true);
  const [isValidCity, setIsValidCity] = useState(() => true);
  const [isValidPostCode, setIsValidPostCode] = useState(() => true);
  const [isValidEmailAddress, setIsValidEmailAddress] = useState(() => true);
  const [IsValidPhoneNumber, setIsValidPhoneNumber] = useState(() => true);
  const [participantHasDOB, setParticipantHasDOB] = useState(false);
  const [hasSetYear, setHasSetYear] = useState(false);
  const [hasSetMonth, setHasSetMonth] = useState(false);
  const [hasSetDay, setHasSetDay] = useState(false);
  const [messengerUrl, setMessengerUrl] = useState('');
  const [medicalConditions, setMedicalConditions] = useState('');
  const [school, setSchool] = useState('');
  const [schoolLevel, setSchoolLevel] = useState('');
  const [healthInsuranceNumber, setHealthInsuranceNumber] = useState('');
  const [referringOrganisation, setReferringOrganisation] = useState(''); 
  const [contactPerson, setContactPerson] = useState('');
  const [authorization, setAuthorization] = useState(false);
  const [exitPermit, setExitPermit] = useState(false);
  const [otherContacts, setOtherContacts] = useState('');
  const [firstAttendanceDate, setFirstAttendanceDate] = useState(new Date());

  const [openErrSnackBar, setOpenErrSnackBar] = useState(() => false);
  const [clientErrMsg, setClientErrMsg] = useState("");

  const snackBarErrClose = () => {
    setOpenErrSnackBar(false);
    setClientErrMsg("");
  };

  const [tags, setTags] = useState(() => []);
  const [allTags, setAllTags] = useState(() => []);

  useQuery(GET_ALL_PARTICIPANT_TAGS(user.youth_center.id), {
    onCompleted: data => {
      setAllTags(data.participantTags.data.map(item => {
        return {
          id: item.id,
          ...item.attributes,
        };
      }));
    },
  });

  const [
    createParticipantTagReq,
    {
      error: errCreateParticipantTag,
      loading: participantTagLoading,
    },
  ] = useMutation(CREATE_PARTICIPANT_TAG, {
    onCompleted: (data) => {
      let combinedTags = _.unionBy(
        [{
          id: data.createParticipantTag.data.id,
          ...data.createParticipantTag.data.attributes,
        }],
        tags,
        "name"
      );

      setTags(combinedTags);
    },
  });

  useEffect(() => {
    let newTag = _.find(tags, (tag) => tag.id < 0);

    if (!_.isNil(newTag)) {
      createParticipantTagReq({
        variables: {
          name: newTag.name,
          youth_centers: [user.youth_center.id]
        },
      });
    }

    setAllTags(_.unionBy(allTags, tags, "name"));
  }, [tags]);

  const genderOptions = {
    Male: "Masculin",
    Female: "Féminin",
    Other: "Autre",
  };

  const [allParticipantTypes, setAllParticipantTypes] = useState([]);
  useQuery(PARTICIPANT_TYPES, {
    onCompleted: (data) => {
      setAllParticipantTypes(data.participantTypes.data.map(item => {
        return {
          id: item.id,
          ...item.attributes,
        }
      }));
    },
  });
  const [allDisabilities, setAllDisabilities] = useState([]);
  useQuery(DISABILITIES, {
    onCompleted: (data) => {
      setAllDisabilities(data.disabilities.data.map(item => {
        return {
          id: item.id,
          ...item.attributes,
        }
      }));
    }
  });

  useEffect(() => {
    setParticipantTypes(
      allParticipantTypes.filter(
        (type) => type.name == "Jeune"
      )
    );
  }, [allParticipantTypes]);

  useEffect(() => {

    setFirstName(props.singleParticipant?.participant?.firstName ?? "");
    setLastName(props.singleParticipant?.participant?.lastName ?? "");
    setGender(props.singleParticipant?.participant?.sexe ?? null);
    setAddress(props.singleParticipant?.participant?.address ?? "");
    setCity(props.singleParticipant?.participant?.city ?? "");
    setOther(props.singleParticipant?.participant?.other ?? "")
    setEmailAddress(props.singleParticipant?.participant?.email ?? "")
    setPhoneNumber(props.singleParticipant?.participant?.phoneNumber ?? "")
    setPronoms(props.singleParticipant?.participant?.pronoms ?? "")
    setPostCode(props.singleParticipant?.participant?.postcode ?? "");
    setProvince(
      props.singleParticipant?.participant?.province
        ? {
          name: props.singleParticipant.participant.province.name,
          value: props.singleParticipant.participant.province.id,
        }
        : ""
    );
    setParticipantTypes(props.singleParticipant?.participant?.participant_types);
    setDisabilities(props.singleParticipant?.participant?.disabilities || []);
    setTags(props.singleParticipant?.participant.participant_tags || []);
    setMessengerUrl(props.singleParticipant?.participant.messenger_url || '');
    setMedicalConditions(props.singleParticipant?.participant.medical_conditions || '');
    setSchool(props.singleParticipant?.participant.school || '');
    setSchoolLevel(props.singleParticipant?.participant.school_level || '');
    setHealthInsuranceNumber(props.singleParticipant?.participant.health_insurance_number || '');
    setReferringOrganisation(props.singleParticipant?.participant.referring_organisation || '');
    setContactPerson(props.singleParticipant?.participant.contact_person || '');
    setAuthorization(props.singleParticipant?.participant.authorization || false);
    setExitPermit(props.singleParticipant?.participant.exit_permit || false);
    setOtherContacts(props.singleParticipant?.participant.other_contacts || '');

    if (props.singleParticipant?.participant.first_attendance_date) {
      setFirstAttendanceDate(new Date(props.singleParticipant?.participant.first_attendance_date + "T00:00"))
    } else if (props.singleParticipant?.participant.attendances.length) {
      const sAttendances = _.sortBy(props.singleParticipant?.participant.attendances, (o) => o.date);
      setFirstAttendanceDate(sAttendances[0].date + "T00:00");
    };

    const date = new Date();

    if (props.singleParticipant?.participant?.year) {
      setHasSetYear(true);
      date.setYear(props.singleParticipant?.participant?.year);
      setParticipantHasDOB(true)
    }

    if (props.singleParticipant?.participant?.month) {
      setHasSetMonth(true);
      date.setMonth(props.singleParticipant?.participant?.month - 1);
      setParticipantHasDOB(true)
    }

    if (props.singleParticipant?.participant?.day) {
      setHasSetDay(true);
      date.setDate(props.singleParticipant?.participant?.day);
      setParticipantHasDOB(true)
    }

    setDOB(date);

  }, [props.singleParticipant])

  const validateForm = () => {
    let isValid = true;
    setIsValidFirstName(true);
    setIsValidYear(true);
    setIsValidAddress(true);
    setIsValidCity(true);
    setIsValidPostCode(true);

    if (_.isEmpty(firstName) || !Util.isValidName(firstName)) {
      firstNameRef.current.focus();
      setIsValidFirstName(false);
      isValid = false;
    }

    if (_.some(participantTypes, { isYearRequired: true }) && !hasSetYear) {
      setIsValidYear(false);
      isValid = false;
    }
    if (!_.isEmpty(address) && !Util.isValidAddress(address)) {
      addressRef.current.focus();
      setIsValidAddress(false);
      isValid = false;
    }
    if (!_.isEmpty(emailAddress) && !Util.isEmailValid(emailAddress)) {
      emailAddRef.current.focus();
      setIsValidEmailAddress(false);
      isValid = false;
    }
    if (!_.isEmpty(phoneNumber) && !Util.isValidPhoneNumber(phoneNumber)) {
      phoneNumberRef.current.focus();
      setIsValidPhoneNumber(false);
      isValid = false;
    }
    if (!_.isEmpty(city) && !Util.isValidCityName(city)) {
      cityRef.current.focus();
      setIsValidCity(false);
      isValid = false;
    }
    if (!_.isEmpty(postCode) && !Util.isValidPostCode(postCode)) {
      postCodeRef.current.focus();
      setIsValidPostCode(false);
      isValid = false;
    }

    return isValid;
  };

  const clearFormState = () => {
    setFirstName(() => "");
    setLastName(() => "");
    setGender("");
    setOther("");
    setAddress("");
    setEmailAddress("");
    setPhoneNumber("");
    setPronoms("");
    setCity("");
    setPostCode("");
    setProvince(() => "");
    setParticipantTypes(() => []);
    setDisabilities(() => []);
    setDOB(new Date('2000-01-01'));
    setParticipantHasDOB(false);
    setHasSetYear(false);
    setHasSetMonth(false);
    setHasSetDay(false);
    setMessengerUrl('');
    setMedicalConditions('');
    setSchool('');
    setSchoolLevel('');
    setHealthInsuranceNumber('');
    setReferringOrganisation('');
    setContactPerson('');
    setAuthorization(false);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    if (validateForm()) {

      let day = null;
      let month = null;
      let year = null;

      if (participantHasDOB) {
        if (hasSetYear) {
          year = parseInt(moment(DOB).format("YYYY"));
        }

        if (hasSetMonth) {
          month = parseInt(moment(DOB).format("M"));
        }

        if (hasSetDay) {
          day = parseInt(moment(DOB).format("D"));
        }
      }

      const vars = {
        firstName: firstName,
        lastName: lastName,
        month,
        day,
        year,
        sexe: gender ? gender : null,
        address: address ? address : '',
        city: city ? city : '',
        other: other ? other : '',
        postcode: postCode ? postCode : '',
        email: emailAddress ? emailAddress : null,
        phoneNumber: phoneNumber ? phoneNumber : '',
        messenger_url: messengerUrl ? messengerUrl : '',
        medical_conditions: medicalConditions ? medicalConditions : '',
        school: school ? school : '',
        school_level: schoolLevel ? schoolLevel : '',
        health_insurance_number: healthInsuranceNumber ? healthInsuranceNumber : '',
        referring_organisation: referringOrganisation ? referringOrganisation : '',
        contact_person: contactPerson ? contactPerson : '',
        authorization: authorization ? authorization : false,
        pronoms: pronoms ? pronoms : '',
        province: province.value ? province.value : null,
        participant_types: _.map(participantTypes, "id"),
        disabilities: _.map(disabilities, "id"),
        youth_center: user.youth_center.id,
        participant_tags: _.map(tags, "id"),
        first_attendance_date: moment(firstAttendanceDate).format("YYYY-MM-DD"),
        exit_permit: exitPermit ? exitPermit : false,
        other_contacts: otherContacts ? otherContacts : '',
      };

      if (props.formSubmitCallback) {
        props.formSubmitCallback(vars);
      }

      if (props.clearFormAfterSubmit) {
        clearFormState();
      }
    } else {
      setClientErrMsg(FORM_VALIDATION_FAILED);
      setOpenErrSnackBar(true);
    }
  };

  const clearDatePickerState = () => {
    setHasSetYear(false);
    setHasSetMonth(false);
    setHasSetDay(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openErrSnackBar}
        onClose={snackBarErrClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={snackBarErrClose}
          severity="error"
        >
          {clientErrMsg}
        </Alert>
      </Snackbar>
      <div className={classes.root}>
        <>
          {props.title &&
            <Grid container spacing={4} alignItems="flex-end">
              <Grid item>
                <Typography className={css(AppStyles.weight7)}>
                  {props.title}
                </Typography>
              </Grid>
            </Grid>
          }
          <form
            className={classes.root}
            noValidate
            autoComplete="off"
            onSubmit={formSubmitHandler}
          >
            <Grid container spacing={6} justifyContent="space-between">
              <Grid item xs={12} md={6}>
                <TextField
                  id="firstName"
                  label="Prénom"
                  fullWidth
                  value={firstName}
                  onChange={(e) =>
                    setFirstName(e.target.value, setIsValidFirstName(true))
                  }
                  ref={firstNameRef}
                />
                {!isValidFirstName ? (
                  <span className={`${css(AppStyles.formError)}`}>
                    {INVALID_FIRSTNAME}
                  </span>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="lastName"
                  label="Nom de famille"
                  fullWidth
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  ref={lastNameRef}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={6}
              justifyContent="space-between"
              className={classes.inputFieldsWrapper}
            >
              <MuiPickersUtilsProvider
                locale={frLocale}
                utils={DateFnsUtils}
              >
                <Grid item xs={12} md={4}>
                  <KeyboardDatePicker
                    className={`${css(AppStyles.mTop0)} ${hasSetYear ? 'nd-default-mui-input' : 'nd-hide-mui-input'}`}
                    margin="normal"
                    fullWidth
                    disableToolbar
                    variant="inline"
                    views={["year"]}
                    format="yyyy"
                    minDateMessage="La date ne doit pas être antérieure à la date minimale"
                    maxDateMessage="La date ne doit pas être postérieure à la date maximale"
                    invalidDateMessage=""
                    id="date-timepicker"
                    label="Année"
                    value={participantHasDOB ? DOB : null}
                    defaultValue={null}
                    onChange={(data) => {
                      if (data) {
                        setHasSetYear(true);
                        setParticipantHasDOB(true);
                      } else {
                        clearDatePickerState();
                        setParticipantHasDOB(false);
                      }

                      setDOB(data, setIsValidYear(true));
                    }}
                  />
                  {!isValidYear ? (
                    <span className={`${css(AppStyles.formError)}`}>
                      {INVALID_DOB}
                    </span>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  <KeyboardDatePicker
                    className={`${css(AppStyles.mTop0)} ${hasSetMonth ? 'nd-default-mui-input' : 'nd-hide-mui-input'}`}
                    margin="normal"
                    fullWidth
                    disableToolbar
                    variant="inline"
                    views={["month"]}
                    format="MM"
                    invalidDateMessage="Format de mois invalide"
                    id="date-timepicker"
                    label="Mois"
                    value={participantHasDOB ? DOB : null}
                    defaultValue={null}
                    onChange={(data) => {
                      if (data) {
                        setHasSetMonth(true);
                        setParticipantHasDOB(true);
                      } else {
                        clearDatePickerState();
                        setParticipantHasDOB(false);
                      }

                      setDOB(data);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <KeyboardDatePicker
                    className={`${css(AppStyles.mTop0)} ${hasSetDay ? 'nd-default-mui-input' : 'nd-hide-mui-input'}`}
                    margin="normal"
                    fullWidth
                    disableToolbar
                    variant="inline"
                    views={["date"]}
                    format="dd"
                    invalidDateMessage="Format de jour invalide"
                    id="date-timepicker"
                    label="Jour"
                    value={participantHasDOB ? DOB : null}
                    defaultValue={null}
                    onChange={(data) => {
                      if (data) {
                        setHasSetDay(true);
                        setParticipantHasDOB(true);
                      } else {
                        clearDatePickerState();
                        setParticipantHasDOB(false);
                      }

                      setDOB(data);
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid
              container
              spacing={6}
              justifyContent="space-between"
              className={classes.inputFieldsWrapper}
            >
              <Grid item xs={12} md={6}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormGroup row>
                    {allDisabilities &&
                      allDisabilities.map(
                        (item, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  inputRef={disabilitiesRef}
                                  id={item.id}
                                  checked={
                                    _.find(
                                      disabilities,
                                      (i) => i.id == item.id
                                    )
                                      ? true
                                      : false
                                  }
                                  value={item.id}
                                  onChange={() => {
                                    let data =
                                      _.cloneDeep(disabilities);

                                    if (
                                      _.find(
                                        data,
                                        (i) => i.id == item.id
                                      )
                                    ) {
                                      data = _.filter(
                                        data,
                                        (i) => i.id != item.id
                                      );
                                    } else {
                                      data.push(item);
                                    }

                                    setDisabilities(data);
                                  }}
                                />
                              }
                              label={item.name}
                            />
                          );
                        }
                      )}
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="gender-dropdown-label">Genre</InputLabel>
                  <Select
                    labelId="gender-dropdown-label"
                    id="gender-dropdown-select"
                    value={gender}
                    renderValue={(val) => {
                      return genderOptions[val];
                    }}
                    onChange={(e) => setGender(e.target.value)}
                    ref={genderRef}
                  >
                    {Object.keys(genderOptions).map(function (key, index) {
                      return (
                        <MenuItem key={`${key}-${index}`} value={key}>
                          {genderOptions[key]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {gender && gender == 'Other' &&
                  <TextField
                    id="other"
                    label="Précisez (optionnel)"
                    fullWidth
                    value={other}
                    className={classes.otherField}
                    onChange={(e) =>
                      setOther(e.target.value)
                    }
                    ref={addressRef}
                  />
                }
              </Grid>
            </Grid>
            <Grid
              container
              spacing={6}
              justifyContent="space-between"
              className={classes.inputFieldsWrapper}
            >
              <Grid item xs={12} md={12}>
                <TextField
                  id="pronoms"
                  label="Pronoms"
                  fullWidth
                  value={pronoms}
                  onChange={(e) =>
                    setPronoms(e.target.value)
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={6}
              justifyContent="space-between"
              className={classes.inputFieldsWrapper}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  id="emailAddress"
                  label="Courriel"
                  fullWidth
                  value={emailAddress}
                  onChange={(e) =>
                    setEmailAddress(e.target.value, setIsValidEmailAddress(true))
                  }
                  ref={emailAddRef}
                />
                {!isValidEmailAddress ? (
                  <span className={`${css(AppStyles.formError)}`}>
                    {INVALID_EMAIL}
                  </span>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="phoneNumber"
                  label="Téléphone"
                  fullWidth
                  value={phoneNumber}
                  onChange={(e) =>
                    setPhoneNumber(e.target.value, setIsValidPhoneNumber(true))
                  }
                  ref={phoneNumberRef}
                />
                {!IsValidPhoneNumber ? (
                  <span className={`${css(AppStyles.formError)}`}>
                    {INVALID_PHONE}
                  </span>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={6}
              justifyContent="space-between"
              className={classes.inputFieldsWrapper}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  id="address"
                  label="Adresse"
                  fullWidth
                  value={address}
                  onChange={(e) =>
                    setAddress(e.target.value, setIsValidAddress(true))
                  }
                  ref={addressRef}
                />
                {!isValidAddress ? (
                  <span className={`${css(AppStyles.formError)}`}>
                    {INVALID_ADDRESS}
                  </span>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="city"
                  label="Ville"
                  fullWidth
                  value={city}
                  onChange={(e) =>
                    setCity(e.target.value, setIsValidCity(true))
                  }
                  ref={cityRef}
                />
                {!isValidCity ? (
                  <span className={`${css(AppStyles.formError)}`}>
                    {INVALID_CITY}
                  </span>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>

            <Grid
              container
              spacing={6}
              justifyContent="space-between"
              className={classes.inputFieldsWrapper}
            >
              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    Province
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={province}
                    renderValue={(val) => {
                      return val.name;
                    }}
                    onChange={(e) => setProvince(e.target.value)}
                    ref={provinceRef}
                  >
                    <MenuItem
                      value={{ name: "--", value: null }}
                    >
                      --
                    </MenuItem>
                    {allProvinces &&
                      allProvinces.map((item) => {
                        return (
                          <MenuItem
                            key={item.id}
                            value={{ name: item.name, value: item.id }}
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="postalCode"
                  label="Code Postal"
                  fullWidth
                  value={postCode}
                  onChange={(e) =>
                    setPostCode(e.target.value, setIsValidPostCode(true))
                  }
                  ref={postCodeRef}
                />
                {!isValidPostCode ? (
                  <span className={`${css(AppStyles.formError)}`}>
                    {INVALID_POSTALCODE}
                  </span>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>

            <Grid
              container
              spacing={6}
              justifyContent="space-between"
              className={classes.inputFieldsWrapper}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  id="messengerUrl"
                  label="Adresse Messenger"
                  fullWidth
                  value={messengerUrl}
                  onChange={(e) =>
                    setMessengerUrl(e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="contactPerson"
                  label="Contact (parent ou tuteur)"
                  fullWidth
                  value={contactPerson}
                  onChange={(e) =>
                    setContactPerson(e.target.value)
                  }
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={6}
              justifyContent="space-between"
              className={classes.inputFieldsWrapper}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  id="school"
                  label="École"
                  fullWidth
                  value={school}
                  onChange={(e) =>
                    setSchool(e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="schoolLevel"
                  label="Niveau Scolaire"
                  fullWidth
                  value={schoolLevel}
                  onChange={(e) =>
                    setSchoolLevel(e.target.value)
                  }
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={6}
              justifyContent="space-between"
              className={classes.inputFieldsWrapper}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  id="healthInsuranceNumber"
                  label="Numéro d’assurance maladie"
                  fullWidth
                  value={healthInsuranceNumber}
                  onChange={(e) =>
                    setHealthInsuranceNumber(e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="referringOrganisation"
                  label="Organisme de référence"
                  fullWidth
                  value={referringOrganisation}
                  onChange={(e) =>
                    setReferringOrganisation(e.target.value)
                  }
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={6}
              justifyContent="space-between"
              className={classes.inputFieldsWrapper}
            >
              <Grid item xs={12} md={12}>
                <TextField
                  id="medicalConditions"
                  label="Allergies et conditions médicales"
                  fullWidth
                  value={medicalConditions}
                  onChange={(e) =>
                    setMedicalConditions(e.target.value)
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={6}
              className={classes.inputFieldsWrapper}
            >
              <Grid item xs={12} md={12}>
                <Autocomplete
                  disableCloseOnSelect
                  multiple
                  id="participant-type-tag"
                  options={
                    allParticipantTypes
                      ? getSortedOptions(allParticipantTypes, "name")
                      : []
                  }
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  value={participantTypes || []}
                  onChange={(event, newVal) => setParticipantTypes(newVal)}
                  ref={participantTypesRef}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Types de participants"
                      placeholder="Types de participants"
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container spacing={6} className={classes.inputFieldsWrapper}>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  freeSolo
                  disableCloseOnSelect
                  multiple
                  id="activity-tags"
                  options={allTags}
                  getOptionLabel={(option) => option.name || option}
                  getOptionSelected={(option, value) => {
                    return (
                      (_.isString(option)
                        ? option.toLowerCase()
                        : option.name.toLowerCase()) ===
                      (_.isString(value)
                        ? value.toLowerCase()
                        : value.name.toLowerCase())
                    );
                  }}
                  value={tags || []}
                  onChange={(event, newVal) => {
                    let alteredObj = newVal.map((val) => {
                      // If selected from dropdown
                      if (_.isObject(val)) return val;

                      // If typed and option available
                      let foundFromTagsObj = _.find(
                        allTags,
                        (tag) => tag.name.toLowerCase() === val.toLowerCase()
                      );

                      // If typed and option not available
                      if (foundFromTagsObj) return foundFromTagsObj;

                      let newTag = { id: Math.random() * -1, name: val };
                      return newTag;
                    });
                    setTags(alteredObj);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Étiquettes"
                      placeholder="Appuyez sur Entrée pour créer l'étiquette"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={6}
              alignItems="center"
              justifyContent="space-between"
              className={classes.inputFieldsWrapper}
            >
              <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
                  <Grid container>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      margin="normal"
                      id="date-picker-inline"
                      label="Début de fréquentation"
                      value={firstAttendanceDate}
                      onChange={date => {
                        setFirstAttendanceDate(date);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} md={6}></Grid>
            </Grid>
            <Grid
              container
              spacing={6}
              alignItems="center"
              justifyContent="space-between"
              className={classes.inputFieldsWrapper}
            >
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  style={{marginLeft: 0}}
                  label="Autorisation photo/vidéo"
                  control={
                    <Checkbox
                      className={`visitor-checkbox`}
                      edge="end"
                      checked={authorization}
                      onChange={(e) => {
                        setAuthorization(e.target.checked);
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  style={{marginLeft: 0}}
                  label="Autorisation de sortie"
                  control={
                    <Checkbox
                      className={`visitor-checkbox`}
                      edge="end"
                      checked={exitPermit}
                      onChange={(e) => {
                        setExitPermit(e.target.checked);
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  id="autres-contacts"
                  label="Autres contacts"
                  fullWidth
                  value={otherContacts}
                  onChange={(e) =>
                    setOtherContacts(e.target.value)
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={6}
              alignItems="center"
              justifyContent="space-between"
              className={classes.inputFieldsWrapper}
            >
              {props.closeFormCallback &&
                <Grid item xs>
                  <ActionButton
                    title={props.closeFormBtnLabel ? props.closeFormBtnLabel : "Annuler"}
                    className={css(styles.cancelBtn)}
                    onClick={() => {
                      if (props.closeFormCallback) {
                        props.closeFormCallback();
                      }
                      if (props.clearFormAfterSubmit) {
                        clearFormState();
                      }
                    }}
                  />
                </Grid>
              }
              <Grid item>
                <ActionButton
                  title={props.formSubmitBtnLabel ? props.formSubmitBtnLabel : "Ajouter"}
                  className={css(styles.addBtn)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </form>
        </>
      </div>
    </>
  );
}
