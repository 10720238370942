import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../theme";
import {
  Box,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  Switch,
  TextField,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Close, Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { css } from "aphrodite";
import { AppStyles } from "../../theme";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import {
  CREATE_USER,
  DELETE_USER,
  UPDATE_USER,
  CHANGE_PASSWORD,
  getSingleUser,
} from "../../graphql";
import {
  FORM_VALIDATION_FAILED,
  INVALID_EMAIL,
  ROUTES,
} from "../../constants";
import styles from "./UsersStyles";
import { ActionButton, Header } from "../../components";
import _ from "lodash";
import Util from "../../services/Util";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  flexClass: { flex: 1 },
  appBarBg: {
    background: Colors.brand.primary,
  },
  inputFieldsWrapper: {
    marginTop: theme.spacing(2),
  },
  hrMargin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  hrColor: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
    fontSize: 18,
    textTransform: "uppercase",
  },

  green: {
    color: Colors.brand.primary,
  },
  formControl: {
    minWidth: "100%",
  },
  deleteBtn: {
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 400,
    boxShadow: "2px 2px 8px 1px #0000002b",
    "&:hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
}));

const PasswordField = ({ textFieldRef, ...props }) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(value => !value);
  const handleMouseDownPassword = event => event.preventDefault();

  return <TextField
    variant="outlined"
    margin="normal"
    fullWidth
    type={showPassword ? "text" : "password"}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ),
    }}
    ref={textFieldRef}
    {...props}
  />;
};

export default function UserForm(props) {
  const user = useSelector((state) => {
    return state.user;
  });
  const classes = useStyles();
  const history = useHistory();
  const [singleUser, setSingleUser] = useState(() => { });
  const [firstName, setFirstName] = useState(() => "");
  const [lastName, setLastName] = useState(() => "");
  const [email, setEmail] = useState(() => "");
  const [password, setPassword] = useState(() => "");
  const [passwordRepeat, setPasswordRepeat] = useState(() => "");
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const passwordRepeatRef = useRef(null);
  const [isValidEmail, setIsValidEmail] = useState(() => true);
  const [isValidPassword, setIsValidPassword] = useState(() => true);
  const [isValidPasswordRepeat, setIsValidPasswordRepeat] = useState(() => true);
  const [blocked, setBlocked] = useState(false);
  const guideSignedRef = useRef(null);
  const [guideSigned, setGuideSigned] = useState(() => "");


  const [openSnackBar, setOpenSnackBar] = useState(() => false);
  const [formSubmitResponse, setFormSubmitResponse] = useState(() => "");

  const [openErrSnackBar, setOpenErrSnackBar] = useState(() => false);
  const [clientErrMsg, setClientErrMsg] = useState("");

  const snackBarErrClose = () => {
    setOpenErrSnackBar(false);
  };

  const snackBarCloseHandler = () => {
    setOpenSnackBar(false);
  };

  const [showModal, setShowModal] = useState(() => false);

  const handlerModalOpen = () => {
    setShowModal(true);
  };

  const handlerModalClose = () => {
    setShowModal(false);
  };

  const {
    match: { params },
  } = props;

  const isNewUser = !Boolean(params.id);
  const [changePassword, setChangePassword] = useState(isNewUser);

  const [
    updateUserReq,
    {
      data: userUpdate,
      error: errUptUser,
      loading: userUpdateLoading,
    },
  ] = useMutation(UPDATE_USER, {
    onError: ({ graphQLErrors }) => {
      let errMsg = "Erreur lors de la mise à jour de l'usager"

      graphQLErrors.forEach(err => {
        if (err.message === "Cannot return null for non-nullable field UsersPermissionsUser.id.") {
          errMsg = "Un compte avec cette adresse courriel existe déjà.";
        }
      })
      
      setClientErrMsg(errMsg)
      setOpenErrSnackBar(true);
    },
    onCompleted: (data) => {
      setOpenSnackBar(true);
      setFormSubmitResponse("L'usager a été mis à jour avec succès");
    }
  });

  const [
    updatePasswordReq,
    {
      error: errChangeUserPws
    }
  ] = useMutation(CHANGE_PASSWORD);

  const [
    createUserReq,
    {
      loading: userCreateLoading,
    },
  ] = useMutation(CREATE_USER, {
    onCompleted: (data) => {
      setOpenSnackBar(true);
      setFormSubmitResponse("L'usager a été créé avec succès");
      setTimeout(() => {
        history.push(`${ROUTES.USERS}`);
      }, 500);
    },
    onError: ({ graphQLErrors }) => {
      let errMsg = "Erreur lors de la création de l'usager"

      graphQLErrors.forEach(err => {
        if (err.message === "Cannot return null for non-nullable field UsersPermissionsUser.id.") {
          errMsg = "Un compte avec cette adresse courriel existe déjà.";
        }
      })
      
      setClientErrMsg(errMsg)
      setOpenErrSnackBar(true);
    },
  });

  const [
    deleteUserReq,
    {
      loading: userDeleteLoading,
      error: errDelUser,
    },
  ] = useMutation(DELETE_USER, {
    onCompleted: (data) => {
      setOpenSnackBar(true);
      setFormSubmitResponse("L'usager a été supprimé avec succès");
      setTimeout(() => {
        history.push(`${ROUTES.USERS}`);
      }, 1000)
    },
  });

  const { data, loading: loadingUser, error: errFetchUser } = useQuery(getSingleUser, {
    variables: { userId: params.id },
    skip: _.isUndefined(params.id) || _.isNil(params.id),
    onCompleted: (data) => {
      setSingleUser({
        id: data.usersPermissionsUser.data.id,
        ...data.usersPermissionsUser.data.attributes,
        youth_center: {
          id: data.usersPermissionsUser.data.attributes.youth_center.data.id,
          ...data.usersPermissionsUser.data.attributes.youth_center.data.attributes
        }        
      })
    },
  });

  useEffect(() => {
    let hasError = true;

    if (errDelUser) {
      setClientErrMsg("Erreur lors de la suppression de l'usager");
    } else if (errChangeUserPws) {
      setClientErrMsg("Erreur lors de la modification du mot de passe de l'usager");
    } else if (errFetchUser) {
      setClientErrMsg("Erreur lors du chargement de l'usager");
    } else {
      hasError = false;
    }

    if (hasError) {
      setOpenErrSnackBar(true);
    }

  }, [errDelUser, errFetchUser, errChangeUserPws]);

  useEffect(() => {
    setFirstName(singleUser?.firstName ?? "");
    setLastName(singleUser?.lastName ?? "");
    setEmail(singleUser?.email ?? "");
    setBlocked(singleUser?.blocked ?? false);
    setGuideSigned(singleUser?.guide_signed ?? "");
  }, [singleUser]);

  const minPasswordLength = 8;
  const validateForm = () => {
    let isValid = true;
    setIsValidEmail(true);
    setIsValidPassword(true);
    setIsValidPasswordRepeat(true);

    if (_.isEmpty(email) || !Util.isEmailValid(email)) {
      emailRef.current.focus();
      setIsValidEmail(false);
      isValid = false;
    }

    if (changePassword) {
      if (!password || password.length < minPasswordLength) {
        passwordRef.current.focus();
        setIsValidPassword(false);
        isValid = false;
      }

      if (password !== passwordRepeat) {
        passwordRepeatRef.current.focus();
        setIsValidPasswordRepeat(false);
        isValid = false;
      }
    }

    return isValid;
  };

  const saveUserFunc = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (isNewUser) {
        const res = await createUserReq({
          variables: {
            firstName: firstName,
            lastName: lastName,
            email: email,
            username: email,
            password: password,
            youth_center: user.youth_center.id,
            guide_signed: guideSigned,
          }
        });

        if (res?.data?.createUser?.user?.id) {
          history.push(`${ROUTES.USERS}/${res.data.createUser.user.id}`);
        }
      } else {
        await updateUserReq({
          variables: {
            id: params.id,
            firstName: firstName,
            lastName: lastName,
            email: email,
            username: email,
            blocked: blocked
          }
        });
        if (changePassword) {
          await updatePasswordReq({
            variables: {
              id: params.id,
              password,
            }
          });
        }
      }
    } else {
      setClientErrMsg(FORM_VALIDATION_FAILED);
      setOpenErrSnackBar(true);
    }
  };

  const deleteUserFunc = async (e) => {
    e.preventDefault();
    deleteUserReq({
      variables: {
        id: params.id,
      }
    });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openErrSnackBar}
        onClose={snackBarErrClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={snackBarErrClose}
          severity="error"
        >
          {clientErrMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openSnackBar}
        autoHideDuration={4000}
        onClose={snackBarCloseHandler}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={snackBarCloseHandler}
          severity="success"
        >
          {formSubmitResponse}
        </Alert>
      </Snackbar>
      <div>
        <Header />
        <>
          <div className={classes.root}>
            <Container
              className={css(AppStyles.marginTop128, AppStyles.mBottom45)}
            >
              <Typography variant="h6">Informations</Typography>
              <Card className={classes.cardWrapper}>
                <CardContent className={classes.cardContentWrapperTwo}>

                  {loadingUser
                    ?
                    <div className="progress-container">
                      <CircularProgress color="primary" />
                    </div>
                    :
                    <form
                      className={classes.root}
                      noValidate
                      autoComplete="off"
                    >
                      <Grid
                        container
                        spacing={6}
                        justifyContent="space-between"
                      >
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="firstName"
                            label="Prénom"
                            fullWidth
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            ref={firstNameRef}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="lastName"
                            label="Nom de famille"
                            fullWidth
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            ref={lastNameRef}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="email"
                            label="Courriel"
                            fullWidth
                            value={email}
                            onChange={(e) => {
                              setEmail(String(e.target.value).trim());
                              setIsValidEmail(true);
                            }}
                            ref={emailRef}
                          />
                          {!isValidEmail ? (
                            <span className={`${css(AppStyles.formError)}`}>
                              {INVALID_EMAIL}
                            </span>
                          ) : (
                            ""
                          )}
                        </Grid>
                        {!isNewUser && <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={blocked}
                                onChange={e => setBlocked(e.target.checked)}
                              />
                            }
                            label="Désactiver l'usager?"
                          />
                        </Grid>}
                        {!isNewUser && <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={changePassword}
                                onChange={e => setChangePassword(e.target.checked)}
                              />
                            }
                            label="Modifier le mot de passe?"
                          />
                        </Grid>}
                        {changePassword && <>
                          <Grid item xs={12} md={6}>
                            <PasswordField
                              name="password"
                              id="password"
                              value={password}
                              onChange={e => {
                                setPassword(e.target.value);
                                setIsValidPassword(true);
                              }}
                              label="Mot de passe"
                              textFieldRef={passwordRef}
                            />
                            {!isValidPassword ? (
                              <span className={`${css(AppStyles.formError)}`}>
                                Le mot de passe doit contenir au moins {minPasswordLength} caractères
                              </span>
                            ) : (
                              ""
                            )}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <PasswordField
                              name="passwordRepeat"
                              id="passwordRepeat"
                              value={passwordRepeat}
                              onChange={e => {
                                setPasswordRepeat(e.target.value);
                                setIsValidPasswordRepeat(true);
                              }}
                              label="Mot de passe (répéter)"
                              textFieldRef={passwordRepeatRef}
                            />
                            {!isValidPasswordRepeat ? (
                              <span className={`${css(AppStyles.formError)}`}>
                                Le mot de passe doit concorder
                              </span>
                            ) : (
                              ""
                            )}
                          </Grid>
                          {isNewUser && (
                              <Grid item xs={12}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={guideSigned}
                                      onChange={(e) =>
                                        setGuideSigned(e.target.checked)
                                      }
                                    />
                                  }
                                  label={
                                    <span>
                                      Avant de créer les accès de vos
                                      employé·es, veuillez vous assurer de leur
                                      faire signer le{" "}
                                      <a
                                        href="https://forms.gle/pJz1EJ5H4d1UpAyb9"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        formulaire
                                      </a>{" "}
                                      d'acceptation du{" "}
                                      <a
                                        href="https://drive.google.com/open?id=1RtOwkNmC6fWdiwpyyNSkowF_RXf5n5DG&authuser=communications%40rmjq.org&usp=drive_fs"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {" "}
                                        guide éthique
                                      </a>
                                      .
                                    </span>
                                  }
                                />
                              </Grid>
                            )}
                        </>}
                      </Grid>
                      <Grid
                        container
                        spacing={6}
                        alignItems="center"
                        justifyContent="space-between"
                        className={classes.inputFieldsWrapper}
                      >
                        <Grid item>
                          {!isNewUser && (
                            <ActionButton
                              className={css(styles.deleteBtn)}
                              title="Supprimer"
                              onClick={handlerModalOpen}
                            />
                          )}
                          <Dialog
                            open={showModal}
                            onClose={handlerModalClose}
                            aria-labelledby="responsive-dialog-title"
                          >
                            <DialogTitle id="responsive-dialog-title">
                              Êtes-vous sûr?
                            </DialogTitle>
                            <Box position="absolute" top={0} right={0}>
                              <IconButton onClick={handlerModalClose}>
                                <Close />
                              </IconButton>
                            </Box>
                            <DialogContent>
                              <DialogContentText>
                                Voulez-vous vraiment supprimer l'usager? Cette opération est irréversible!
                              </DialogContentText>
                            </DialogContent>
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="space-between"
                              style={{ padding: "0 12px 16px 0" }}
                            >
                              <Grid item></Grid>
                              <DialogActions>
                                <Grid item xs>
                                  <Grid
                                    container
                                    spacing={2}
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <Grid item>
                                      <ActionButton
                                        title="Annuler"
                                        onClick={handlerModalClose}
                                        className={css(styles.deleteBtn)}
                                      />
                                    </Grid>
                                    <Grid item>
                                      <ActionButton
                                        title="Confirmer"
                                        onClick={deleteUserFunc}
                                        className={css(styles.updateBtn)}
                                        isLoading={userDeleteLoading}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </DialogActions>
                            </Grid>
                          </Dialog>
                        </Grid>
                        <Grid item>
                          <ActionButton
                            className={css(styles.updateBtn)}
                            title={isNewUser ? "Créer" : "Mettre à jour"}
                            isLoading={userUpdateLoading || userCreateLoading}
                            onClick={saveUserFunc}
                            disabled={isNewUser && !guideSigned}
                          />
                        </Grid>
                      </Grid>
                    </form>
                  } {/* loading user ternary end */}

                </CardContent>
              </Card>
            </Container>
          </div>
        </>
      </div>
    </>
  );
}
