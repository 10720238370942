// @flow
import { StyleSheet } from "aphrodite";
import { Colors } from "../../theme";

export default StyleSheet.create({
  updateBtn: {
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    fontWeight: 400,
    width: "auto",
    boxShadow: "2px 2px 8px 1px #0000002b",
    cursor: "pointer",
    ":hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
    ":disabled": {
      color: Colors.grey,
      background: Colors.white,
      boxShadow: "none",
      border: `1px solid ${Colors.grey2}`,
      // opacity: 0.5,
      cursor: "not-allowed",
    },
  },
  TableWrpper: {
    width: "100%",
    height: "calc(70vh - 410px)",
    minHeight: 300,
  },
});
