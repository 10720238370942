import React, { useState } from 'react';
import {
  makeStyles,
  Grid,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { ROUTES } from '../../constants';
import ExpulsionModal from '../ExpulsionModal';
import MergeModal from '../MergeModal';


const useStyles = makeStyles((theme) => ({
  vertBtn: {
    minWidth: 4,
    width: 20,
    height: 16,
  },
}));


export default function ParticipantThreeDotMenuView(props) {

  const history = useHistory();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [modalExpulsionOpen, setModalExpulsionOpen] = useState(false);

  const handleModalExpulsionOpen = () => {
    setModalExpulsionOpen(true);
  };

  const handleModalExpulsionClose = () => {
    setModalExpulsionOpen(false);
  };

  const [modalMergeOpen, setModalMergeOpen] = useState(false);

  const handleModalMergeOpen = () => {
    setModalMergeOpen(true);
  };

  const handleModalMergeClose = () => {
    setModalMergeOpen(false);
  };

  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    participant,
    handleArchivation,
    formSubmitCallback,
    formErrCallback,
  } = props;

  return (
    <Grid item>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.vertBtn}
      >
        <MoreVert />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            if (props.openProfileInNewTab) {
              window.open(`${ROUTES.PARTICIPANTS}/${participant.id}`, '_blank').focus();
            } else {
              history.push(
                `${ROUTES.PARTICIPANTS}/${participant.id}`
              );
            }
            
            handleClose();
          }}
        >
          Profil
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleArchivation(participant)
            handleClose();
          }}
        >
          {participant.archived ? 'Désarchiver le participant' : 'Archiver le participant'}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleModalExpulsionOpen();
            handleClose();
          }}
        >
          Expulser
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleModalMergeOpen();
            handleClose();
          }}
        >
          Fusionner
        </MenuItem>
      </Menu>
      <ExpulsionModal
        open={modalExpulsionOpen}
        handleClose={handleModalExpulsionClose}
        participant={participant}
        formSubmitCallback={() => {
          formSubmitCallback('Expulsion créée');
        }}
      />
      <MergeModal
        open={modalMergeOpen}
        handleClose={handleModalMergeClose}
        participant={participant}
        formSubmitCallback={() => {
          formSubmitCallback('Participants fusionnés');
        }}
        formErrCallback={formErrCallback}
      />
    </Grid>
  );
};