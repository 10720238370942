import React, { useEffect, useState } from "react";
import QueryString from "qs";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment";
import { css } from "aphrodite";
import {
  Grid,
  Typography,
  Checkbox,
  Divider,
  TextField,
  FormControlLabel,
  Switch,
  Snackbar,
  Box,
  CircularProgress,
} from "@material-ui/core";
import _ from "lodash";
import { Search, Class, Block } from "@material-ui/icons";
import { AppStyles } from "../../theme";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import {
  MARK_ATTENDANCE,
  UPDATE_ATTENDANCE,
  ARCHIVE_PARTICIPANT,
  CREATE_PARTICIPANT,
  GET_ATTENDANCES_FOR_DATE,
} from "../../graphql";
import { DATE_FORMAT11, ROUTES, STRAPI_DATE_FORMAT, DATE_FORMAT12, BACKEND_URI } from "../../constants";
import styles from "./VisitorsListStyles";
import { ActionButton, UserForm } from "../../components";

import { Alert, Pagination } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_TODAYS_PARTICIPANTS_MAP } from "../../actions/ActionTypes";
import ParticipantThreeDotMenu from "../ParticipantThreeDotMenu";
import Util from "../../services/Util";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  hrMargin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  hrColor: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  dateClass: {
  },
  buttonMargin: {
    marginLeft: "auto",
  },
  vertBtn: {
    minWidth: 4,
    width: 20,
    height: 16,
  },
  vertCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  reason: {
    marginTop: '7px',
    fontFamily: 'inherit',
  },
}));


export default function VisitorsListView(props) {
  const dispatch = useDispatch();

  const user = useSelector((state) => {
    return state.user;
  });

  const currServicePoint = props?.servicePoint == '0' ? 0 : parseInt(props?.servicePoint);
  const classes = useStyles();
  const [showCreateUserForm, setShowCreateUserForm] = useState(() => false);
  const [participantList, setParticipantList] = useState(() => []);
  const [showArchived, setShowArchived] = useState(false);
  const [openErrSnackBar, setOpenErrSnackBar] = useState(() => false);
  const [clientErrMsg, setClientErrMsg] = useState("");
  const [errFetchParticipants, setErrFetchParticipants] = useState("");
  const [page, setPage] = useState(1);
  const [participantsCount, setParticipantsCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [todaysAttendances, setTodaysAttendances] = useState([]);
  
  const snackBarErrClose = () => {
    setOpenErrSnackBar(false);
  };
  const [openSuccessSnackBar, setOpenSuccessSnackBar] = useState(() => false);
  const [clientSuccessMsg, setClientSuccessMsg] = useState("");
  const snackBarSuccessClose = () => {
    setOpenSuccessSnackBar(false)
  };
  const [processAttendanceId, setProcessAttendanceId] = useState('0');

  const { refetch: refetchTodayAttendances } = useQuery(GET_ATTENDANCES_FOR_DATE({
    youth_center: user.youth_center.id,
    date: moment(props.date).format(STRAPI_DATE_FORMAT),
  }), {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const attendances = data.attendances.data.map((attendance) => {
        return {
          id: attendance.id,
          ...attendance.attributes,
          participant: {
            id: attendance.attributes.participant.data.id,
            ...attendance.attributes.participant.data.attributes,
          },
          service_point: attendance.attributes.service_point.data ? {
            id: attendance.attributes.service_point.data.id,
            ...attendance.attributes.service_point.data.attributes,
          } : null,
        };
      });

      updateTodaysParticipantsMap(attendances);
      setTodaysAttendances(attendances)
    },
  });

  useEffect(() => {
    updateTodaysParticipantsMap(todaysAttendances)
  }, [currServicePoint])

  const fetchParticipantsSearch = async () => {
    const { results: participants, pagination } = await Util.fetchParticipantsSearch({
      user,
      page,
      pageSize: 20,
      searchTerm,
      showArchived,
    })

    setParticipantList(participants);
    setParticipantsCount(pagination?.total || 0);
    refetchTodayAttendances();
  }

  const fetchParticipants = async () => {
    try {

      if (searchTerm) {
        return fetchParticipantsSearch();
      }
      
      let resBuff = null;

      let query = {
        youth_center: user.youth_center.id,
        pagination: {
          page: page,
          pageSize: 20,
        }
      };

      if (!showArchived) {
        query.archived = false;
      }

      resBuff = await fetch(`${BACKEND_URI}/api/participants?${QueryString.stringify(query)}`, {
        headers: {
          Authorization: "Bearer " + user.jwt,
        }
      });
      const { results: participants, pagination } = await resBuff.json();

      setParticipantList(participants);
      setParticipantsCount(pagination?.total || 0)
      refetchTodayAttendances();
    } catch (e) {
      console.error("err", e);
      setErrFetchParticipants(e);
    }
  }

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchParticipants();
    }, 300);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [showArchived, page, searchTerm, currServicePoint]);

  const refreshParticipants = newParticipent => {
    fetchParticipants().then(() => {
      if (newParticipent) {
        setAttendance(newParticipent, true);
      }
    });
  };


  // for creating user
  const [
    createUserReq,
    { data: userInfo, error: userError, loading: userLoading },
  ] = useMutation(CREATE_PARTICIPANT, {
    onCompleted: (data) => {
      const participant = Util.serialize_PARTICIPANT_res(data);
      refreshParticipants(participant);
      setOpenSuccessSnackBar(true);
      setClientSuccessMsg("La personne est ajoutée");
    },
  });

  const applySearchOnUser = (userSearchQuery) => {
    setSearchTerm(userSearchQuery)
  };


  const [markAttendanceReq, {
    loading: loadingMarkAttendance,
    error: errMarkAttendance,
  }] = useMutation(MARK_ATTENDANCE, {
    onCompleted: (data) => {
      fetchParticipants();
      setClientSuccessMsg("Présence mise à jour.");
      setOpenSuccessSnackBar(true);
      setTimeout(() => {
        setProcessAttendanceId('0');
      }, 1500);
    },
  });

  const [updateAttendanceReq, {
    loading: loadingUpdateAttendance,
    error: errUpdateAttendace,
  }] = useMutation(UPDATE_ATTENDANCE, {
    onCompleted: (data) => {
      fetchParticipants();
      setClientSuccessMsg("Présence mise à jour.");
      setOpenSuccessSnackBar(true);
      setTimeout(() => {
        setProcessAttendanceId('0');
      }, 1500);
    },
  });

  const [archiveParticipantReq] = useMutation(ARCHIVE_PARTICIPANT, {
    onCompleted: (data) => {
      refreshParticipants();
    },
  });

  useEffect(() => {
    let hasError = true;
    if (errFetchParticipants) {
      setClientErrMsg("Erreur lors du chargement des participants")
    } else if (errMarkAttendance || errUpdateAttendace) {
      setClientErrMsg("Erreur lors de la mise à jour des présences")
    } else {
      hasError = false;
    }
    if (hasError) {
      setOpenErrSnackBar(true);
    }
  }, [errFetchParticipants, errMarkAttendance, errUpdateAttendace]);

  const checkAttendance = (item) => {
    let last_date = undefined;
    let last_service_point = undefined;
    const curr_sp = currServicePoint || 0;
    const attendance = item.attendances.find(att => {
      return att.present && (att.service_point?.id || 0) === curr_sp && moment(att.date).isSame(props.date, "day");
    })

    if (attendance) {
      last_date = attendance?.date;
      last_service_point = attendance?.service_point?.id || 0;
    }

    return Boolean(
      last_service_point === curr_sp &&
      last_date &&
      moment(last_date).isSame(props.date, "day")
    );
  };

  const updateTodaysParticipantsMap = (attendances) => {
    const todaysParticipantsMap = {};
    const curr_sp = currServicePoint || 0;

    attendances.forEach(att => {
      const sp = att.service_point?.id ? parseInt(att.service_point?.id) : 0;
      if (att.present && sp === curr_sp && moment(att.date).isSame(props.date, "day")) {
        todaysParticipantsMap[att.participant.id] = att.participant;
      }
    });

    dispatch({
      type: UPDATE_TODAYS_PARTICIPANTS_MAP,
      payload: todaysParticipantsMap
    });
  }

  const setAttendance = (item, val = true) => {
    setProcessAttendanceId(item.id);

    const day = _.find(item.attendances, (curr) => {
      return moment(curr.date).isSame(props.date, "day");
    });

    if (_.isUndefined(day)) {
      const payload = {
        date: moment(props.date).format(STRAPI_DATE_FORMAT),
        participant: item.id,
        present: val,
        youth_center: user.youth_center.id,
        service_point: props?.servicePoint == '0' ? null : parseInt(props?.servicePoint),
      };

      markAttendanceReq({
        variables: {
          ...payload,
        },
      });
    } else {
      const updatePayload = {
        id: day.id,
        date: moment(props.date).format(STRAPI_DATE_FORMAT),
        participant: item.id,
        present: val,
        youth_center: user.youth_center.id,
        service_point: props?.servicePoint == '0' ? null : parseInt(props?.servicePoint),
      };
      updateAttendanceReq({
        variables: {
          ...updatePayload,
        },
      });
    }
  };

  const handleParticipantArchivation = (participant) => {

    archiveParticipantReq({
      variables: {
        id: participant.id,
        archived: !participant.archived,
        archivationDate: moment(new Date(), moment.ISO_8601),
      }
    });

  };

  const handleModalFormSubmit = (message) => {
    setClientSuccessMsg(message);
    setOpenSuccessSnackBar(true);
    refreshParticipants();
  }

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openErrSnackBar}
        onClose={snackBarErrClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={snackBarErrClose}
          severity="error"
        >
          {clientErrMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openSuccessSnackBar}
        autoHideDuration={4000}
        onClose={snackBarSuccessClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={snackBarSuccessClose}
          severity="success"
        >
          {clientSuccessMsg}
        </Alert>
      </Snackbar>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} md={12}>
          <FormControlLabel
            control={
              <Switch
                checked={showArchived}
                onChange={e => {
                  setShowArchived(e.target.checked);
                }}
              />
            }
            label="Afficher les participants archivés"
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} md={6}>
          <Grid container spacing={12} alignItems="flex-end">
            <Grid item>
              <Search />
            </Grid>
            <Grid item xs>
              <TextField
                id="input-with-icon-grid"
                label=""
                placeholder="Rechercher un usager"
                fullWidth
                onChange={(e) => {
                  applySearchOnUser(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.buttonMargin}>
          <ActionButton
            title="Ajouter"
            className={css(styles.addBtn)}
            onClick={() => {
              setShowCreateUserForm(true);
            }}
          />
        </Grid>
      </Grid>
      <div className={classes.hrMargin}>
        <Divider className={classes.hrColor} />
      </div>

      {showCreateUserForm &&
        <UserForm
          formSubmitCallback={vars => {
            createUserReq({
              variables: {
                ...vars
              },
            });
            setShowCreateUserForm(false);
          }}
          closeFormCallback={() => {
            setShowCreateUserForm(false)
          }}
          title="Ajouter une Personne"
        />
      }

      <Grid container spacing={2} alignItems="flex-end">
        <Grid item></Grid>
        <Grid item>
          <Typography className={css(AppStyles.weight7)}>Visiteur</Typography>
        </Grid>
      </Grid>
      <div className={classes.hrMargin}>
        <Divider className={classes.hrColor} />
      </div>

      <List dense className={classes.root}>
        {participantList &&
          participantList.map((itemone, index) => {

            let isExpulsed = false;
            let expulsedUntil = '';
            let expulsionReason = '';

            if (itemone.expulsions.length) {
              for (const expulsion of itemone.expulsions) {
                if (new Date(expulsion.end + "T00:00").getTime() > Date.now()) {
                  isExpulsed = true;
                  expulsedUntil = expulsion.end;
                  expulsionReason = expulsion.reason;
                  break;
                }
              }
            }

            return (
              <div key={index}>
                <ListItem>
                  <Grid
                    container
                    spacing={12}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={9}>
                      <ListItemText>{`${itemone.firstName} ${itemone.lastName != null ? itemone.lastName : ""
                        }`}</ListItemText>
                      <ListItemText className={classes.dateClass}>
                        {itemone.last_attendance_date && itemone.last_attendance_date !== '1970-01-01' &&
                          `${itemone.sexe == "Male"
                            ? "Vu"
                            : itemone.sexe == "Female"
                              ? "Vue"
                              : "Vu.e"
                          } ${moment(itemone.last_attendance_date).format(DATE_FORMAT11)}`
                        }
                      </ListItemText>
                      {itemone.archived &&
                        <ListItemText>
                          <small>
                          Archivé: {moment(itemone.archivationDate).format(
                                DATE_FORMAT12
                              )}
                          </small>
                        </ListItemText>
                      }
                      {isExpulsed &&
                        <ListItemText>
                          <small>
                            Expulsé jusqu'à: {expulsedUntil}
                          </small>
                        </ListItemText>
                      }
                      {Boolean(expulsionReason) &&
                        <ListItemText>
                        <small>
                          <pre className={classes.reason}>Raison: {expulsionReason}</pre>
                        </small>
                      </ListItemText>
                      }
                    </Grid>
                    <Grid item xs={3}>
                    {Boolean(processAttendanceId === itemone.id)
                      ?
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <CircularProgress size={24} />
                      </Grid>
                      :
                      <ListItemSecondaryAction> 
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <Grid item>
                            <Box display={'flex'}>
                              {isExpulsed &&
                                <div
                                  title={"Expulsé jusqu'à: " + expulsedUntil}
                                  className={classes.vertCenter}
                                >
                                  <Block />
                                </div>
                              }
                              {itemone.archived &&
                                <div className={classes.vertCenter}>
                                  <Class />
                                </div>
                              }
                            </Box>
                          </Grid>
                          <Grid item xs>
                            <Checkbox
                              className={`visitor-checkbox`}
                              edge="end"
                              checked={checkAttendance(itemone)}
                              onChange={(e) => {
                                setAttendance(itemone, e.target.checked);
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <ParticipantThreeDotMenu
                              openProfileInNewTab={true}
                              participant={itemone}
                              handleArchivation={handleParticipantArchivation}
                              formSubmitCallback={msg => {
                                handleModalFormSubmit(msg)
                                if (props.particiapntsActivitiesMerged) {
                                  props.particiapntsActivitiesMerged();
                                }
                              }}
                              formErrCallback={errMsg => {
                                setClientErrMsg(errMsg);
                                setOpenErrSnackBar(true);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </ListItemSecondaryAction>
                    }
                    </Grid>
                  </Grid>
                </ListItem>
                <div className={classes.hrMargin}>
                  <Divider className={classes.hrColor} />
                </div>
              </div>
            );
          })}
      </List>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <div></div>
        <Pagination count={Math.ceil(participantsCount / 20)} page={page} onChange={handlePaginationChange} />
      </div>
    </div>
  );
}