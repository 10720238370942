import React, { useEffect, useRef, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Colors } from "../../theme";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Divider,
  useTheme,
  Button,
  Typography,
  CardContent,
  Snackbar,
  Card,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { css } from "aphrodite";
import { AppStyles } from "../../theme";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import {
  CREATE_SERVICE_POINT,
  DELETE_SERVICE_POINT,
  GET_YOUTHCENTER,
  UPDATE_SERVICE_POINT,
  UPDATE_YOUTHCENTER,
  GET_REGIONS,
  GET_YOUTH_CENTERS,
} from "../../graphql";
import {
  INVALID_YOUTHCENTER_NAME,
  INVALID_YOUTHCENTER_EMAIL,
  INVALID_YOUTHCENTER_PHONENUM,
  INVALID_YOUTHCENTER_ADDRESS,
  INVALID_SERVICE_INFO,
  USER_PERMISSIONS,
  FORM_VALIDATION_FAILED,
} from "../../constants";
import styles from "./YouthCenterFormStyles";
import { ActionButton } from "..";
import _, { set } from "lodash";
import Util from "../../services/Util";
import { Add, Remove } from "@material-ui/icons";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  flexClass: { flex: 1 },
  appBarBg: {
    background: Colors.brand.primary,
  },
  inputFieldsWrapper: {
    marginTop: theme.spacing(2),
  },
  hrMargin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  hrColor: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
    fontSize: 18,
    textTransform: "uppercase",
  },

  green: {
    color: Colors.brand.primary,
  },
  formControl: {
    minWidth: "100%",
  },
  deleteBtn: {
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 400,
    boxShadow: "2px 2px 8px 1px #0000002b",
    "&:hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
  cardWrapper: {
    marginTop: 15,
    padding: "20px 0 0px",
    boxShadow: "1px 1px 5px 0px #00000040",
    borderRadius: 3,
  },
  cardContentWrapperTwo: {
    padding: "0px 12px 0",
  },
}));

export default function YouthCenterForm(props) {
  const user = useSelector((state) => {
    return state.user;
  });
  const theme = useTheme();
  const classes = useStyles();
  const [youthCenterID, setYouthCenterID] = useState(() => "");
  const [name, setName] = useState(() => "");
  const [emailAddress, setEmailAddress] = useState(() => "");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState(() => "");
  const nameRef = useRef(null);
  const emailAddRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const addressRef = useRef(null);
  const [isValidName, setIsValidName] = useState(() => true);
  const [isValidEmailAddress, setIsValidEmailAddress] = useState(() => true);
  const [isValidEmailAddressRP, setIsValidEmailAddressRP] = useState(
    () => true
  );
  const [isValidEmailAddressCA, setIsValidEmailAddressCA] = useState(
    () => true
  );
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(() => true);
  const [isValidAddress, setIsValidAddress] = useState(() => true);
  const [youthCenterSuccessMsg, setYouthCenterSuccessMsg] = useState("");
  const [serviceFormValues, setserviceFormValues] = useState(() => []);
  const [isValidServiceValues, setIsValidServiceValues] = useState(() => true);
  // Servicecreation Response Message
  const [formSubmitResponse, setFormSubmitResponse] = useState("");

  const regionRef = useRef(null);
  const [region, setRegion] = useState(""); // Instance régionale RMJQ
  const [isValidRegion, setIsValidRegion] = useState(() => true);
  const [mailingAddress, setMailingAddress] = useState(""); // Adresse postale
  const [secondPhoneNumber, setSecondPhoneNumber] = useState(""); // Numéro de téléphone secondaire
  const [website, setWebsite] = useState(""); // Website
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [tikTok, setTikTok] = useState("");
  const [charityNumber, setCharityNumber] = useState(""); // Numéro d'organisme de bienfaisance
  const [NEQ, setNEQ] = useState(""); // Numéro d'entreprise du Québec
  const [incorporationDate, setIncorporationDate] = useState(); //Date d'incorporation
  const [memberSinceDate, setMemberSinceDate] = useState(); // Date d’adhésion au RMJQ

  // Personne responsable
  const [nameRP, setNameRP] = useState(""); // Nom
  const [titleRP, setTitleRP] = useState(""); // Titre
  const [emailRP, setEmailRP] = useState(""); //Adresse courriel

  // Board
  const [numOfBoardSeats, setNumOfBoardSeats] = useState(""); // Nombre de sièges du CA
  const [boardContactName, setBoardContactName] = useState(""); // Nom de la personne contact avec le CA
  const [boardPosition, setBoardPosition] = useState(""); // Poste sur le CA
  const [phoneBoard, setPhoneBoard] = useState(""); // Téléphone
  const [emailBoard, setEmailBoard] = useState(""); // Adresse courriel

  const [disableForm, setDisableForm] = useState(false);

  const [REGIONS, setREGIONS] = useState([]);

  const [openErrSnackBar, setOpenErrSnackBar] = useState(() => false);
  const [clientErrMsg, setClientErrMsg] = useState("");

  const snackBarCloseHandler = () => {
    setOpenErrSnackBar(false);
  };

  const [openSuccessSnackBar, setOpenSuccessSnackBar] = useState(() => false);
  const [clientSuccessMsg, setClientSuccessMsg] = useState("");

  const snackBarSuccessClose = () => {
    setOpenSuccessSnackBar(false);
  };

  //for updating activity
  const [
    updateActivityReq,
    {
      data: youthCenterUpdate,
      error: youthCenterUpdateError,
      loading: youthCenterUpdateLoading,
    },
  ] = useMutation(UPDATE_YOUTHCENTER, {
    onCompleted: (data) => {
      setYouthCenterSuccessMsg("Détails de la maison des jeunes mis à jour");
      setClientSuccessMsg("Détails de la maison des jeunes mis à jour");
      setOpenSuccessSnackBar(true);
      setTimeout(() => {
        setYouthCenterSuccessMsg("");
      }, 3000);
    },
  });

  // for deleting service
  const [deleteServicePointReq, { error: errDeleteServicePoint }] = useMutation(
    DELETE_SERVICE_POINT,
    {
      onCompleted: (data) => {
        let _d = _.cloneDeep(serviceFormValues);

        _.remove(_d, (a) => a.serviceId == data.deleteServicePoint.data.id);
        setserviceFormValues(_d);
      },
    }
  );

  //for updating note
  const [
    updateServiceReq,
    {
      data: updateService,
      error: updateNoteError,
      loading: updateServiceLoading,
    },
  ] = useMutation(UPDATE_SERVICE_POINT, {
    onCompleted: (data) => {
      setFormSubmitResponse("Le point de service a été soumis");
      setClientSuccessMsg("Le point de service a été soumis");
      setOpenSuccessSnackBar(true);
      setTimeout(() => {
        setFormSubmitResponse("");
      }, 3000);
    },
  });

  // for creating note
  const [
    createServiceReq,
    { data: serviceInfo, error: createServiceError, loading: serviceLoading },
  ] = useMutation(CREATE_SERVICE_POINT, {
    onCompleted: (data) => {
      setFormSubmitResponse("Le point de service a été soumis");
      setClientSuccessMsg("Le point de service a été soumis");
      setOpenSuccessSnackBar(true);
      setTimeout(() => {
        setFormSubmitResponse("");
      }, 3000);
    },
  });

  const [getRegionsReq, { error: errFetchRegions }] = useLazyQuery(
    GET_REGIONS,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setREGIONS(
          [
            {
              id: "0",
              name: "--",
            },
            ...data.regions.data.map((r) => {
              return {
                id: r.id,
                ...r.attributes,
              };
            }),
          ] || []
        );
      },
    }
  );

  useEffect(() => {
    getRegionsReq();
  }, []);

  useEffect(() => {
    if ([USER_PERMISSIONS.MDJ_TEAM].includes(user.role.name)) {
      setDisableForm(true);
    } else {
      setDisableForm(false);
    }
  }, [user]);

  useEffect(() => {
    let hasError = true;

    if (youthCenterUpdateError) {
      setClientErrMsg("Erreur lors de la mise à jour de la maison des jeunes");
    } else if (props.errFetchYouthCenter || props.errFetchYouthCenterById) {
      setClientErrMsg("Erreur lors du chargement de la maison des jeunes");
    } else if (errFetchRegions) {
      setClientErrMsg("Erreur lors du chargement de la liste de régions");
    } else if (updateNoteError) {
      setClientErrMsg("Erreur lors de la mise à jour des notes");
    } else if (createServiceError) {
      setClientErrMsg("Erreur lors de la création du point de service");
    } else if (errDeleteServicePoint) {
      setClientErrMsg("Erreur lors de la suppression du point de service");
    } else {
      hasError = false;
    }

    if (hasError) {
      setOpenErrSnackBar(true);
    }
  }, [
    youthCenterUpdateError,
    props.errFetchYouthCenter,
    props.errFetchYouthCenterById,
    errFetchRegions,
    updateNoteError,
    createServiceError,
    errDeleteServicePoint,
  ]);

  useEffect(() => {
    setYouthCenterID(props.singleYouthCenter?.id || "");
    setName(props.singleYouthCenter?.Name || "");
    setEmailAddress(props.singleYouthCenter?.email || "");
    setPhoneNumber(props.singleYouthCenter?.phonenumber || "");
    setAddress(props.singleYouthCenter?.Address || "");
    setserviceFormValues(
      props.singleYouthCenter?.service_points
        ? props.singleYouthCenter?.service_points?.map((item) => {
            return {
              serviceId: item.id,
              serviceName: item.name,
              serviceAddress: item.address,
            };
          })
        : []
    );

    setRegion(props.singleYouthCenter?.region?.id || "");
    setMailingAddress(props.singleYouthCenter?.mailingAddress || "");

    setSecondPhoneNumber(props.singleYouthCenter?.secondPhoneNumber || "");
    setWebsite(props.singleYouthCenter?.website || "");
    setFacebook(props.singleYouthCenter?.facebook || "");
    setInstagram(props.singleYouthCenter?.instagram || "");
    setTwitter(props.singleYouthCenter?.twitter || "");
    setTikTok(props.singleYouthCenter?.tikTok || "");
    setCharityNumber(props.singleYouthCenter?.charityNumber || "");
    setNEQ(props.singleYouthCenter?.NEQ || "");
    setIncorporationDate(props.singleYouthCenter?.incorporationDate);
    setMemberSinceDate(props.singleYouthCenter?.memberSinceDate);
    setNameRP(props.singleYouthCenter?.nameRP || "");
    setTitleRP(props.singleYouthCenter?.titleRP || "");
    setEmailRP(props.singleYouthCenter?.emailRP || "");
    setNumOfBoardSeats(String(props.singleYouthCenter?.numOfBoardSeats) || "");
    setBoardContactName(props.singleYouthCenter?.boardContactName || "");
    setBoardPosition(props.singleYouthCenter?.boardPosition || "");
    setPhoneBoard(props.singleYouthCenter?.phoneBoard || "");
    setEmailBoard(props.singleYouthCenter?.emailBoard || "");
  }, [props.singleYouthCenter]);

  const validateForm = () => {
    let isValid = true;
    // required check
    if (_.isEmpty(name) || !Util.isValidName(name)) {
      nameRef.current.focus();
      setIsValidName(false);
      isValid = false;
    }

    if (_.isEmpty(emailAddress) || !Util.isEmailValid(emailAddress)) {
      emailAddRef.current.focus();
      setIsValidEmailAddress(false);
      isValid = false;
    }

    if (!_.isEmpty(emailRP) && !Util.isEmailValid(emailRP)) {
      emailAddRef.current.focus();
      setIsValidEmailAddressRP(false);
      isValid = false;
    }

    if (!_.isEmpty(emailBoard) && !Util.isEmailValid(emailBoard)) {
      emailAddRef.current.focus();
      setIsValidEmailAddressCA(false);
      isValid = false;
    }

    if (_.isNull(phoneNumber) || !Util.isNumber(phoneNumber)) {
      phoneNumberRef.current.focus();
      setIsValidPhoneNumber(false);
      isValid = false;
    }

    if (_.isEmpty(address) || !Util.isValidAddress(address)) {
      addressRef.current.focus();
      setIsValidAddress(false);
      isValid = false;
    }

    // region (setRegion) is required
    if (_.isEmpty(region)) {
      regionRef.current.focus();
      setIsValidRegion(false);
      isValid = false;
    }

    return isValid;
  };

  let handleChangeServiceName = (i, e) => {
    let newFormValues = [...serviceFormValues];
    newFormValues[i].serviceName = e.target.value;
    setserviceFormValues(newFormValues);
  };
  let handleChangeServiceAddress = (i, e) => {
    let newFormValues = [...serviceFormValues];
    newFormValues[i].serviceAddress = e.target.value;
    setserviceFormValues(newFormValues);
  };

  let addFormFields = () => {
    setserviceFormValues([
      ...serviceFormValues,

      {
        serviceName: "",
        serviceAddress: "",
        serviceId: null,
      },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...serviceFormValues];
    newFormValues.splice(i, 1);
    setserviceFormValues(newFormValues);
  };

  const deleteServicePoint = (serviceId) => {
    deleteServicePointReq({
      variables: {
        id: serviceId,
      },
    });
  };

  const validateServiceForm = () => {
    let isValid = true;
    setIsValidServiceValues(true);
    // required check
    serviceFormValues.map((f) => {
      if (_.isEmpty(f.serviceName) || _.isEmpty(f.serviceAddress)) {
        setIsValidServiceValues(false);
        isValid = false;
      }
    });

    return isValid;
  };

  const submitServicePointFunction = (e) => {
    e.preventDefault();
    if (validateServiceForm()) {
      let asynServiceFun = async () => {
        let formValues = _.cloneDeep(serviceFormValues);

        formValues = await Promise.all(
          formValues.map(async (f) => {
            if (f.serviceId) {
              const vars = {
                id: f.serviceId,
                serviceName: f.serviceName,
                serviceAddress: f.serviceAddress,
                youth_center: user.youth_center.id,
              };
              updateServiceReq({
                variables: {
                  ...vars,
                },
              });
              return { ...f };
            } else {
              const vars = {
                serviceName: f.serviceName,
                serviceAddress: f.serviceAddress,
                youth_center: user.youth_center.id,
              };
              let created = await createServiceReq({
                variables: {
                  ...vars,
                },
              });
              return {
                ...f,
                serviceId: created?.data?.createServicePoint?.servicePoint?.id,
              };
            }
          })
        );

        setserviceFormValues(formValues);
      };
      asynServiceFun();
    }
  };

  // *form update func*
  const updateYouthCenterFunc = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const varsUpdate = {
        id: youthCenterID,
        Name: name,
        email: emailAddress,
        phonenumber: parseInt(phoneNumber),
        Address: address,
        region: region ? region : null,
        mailingAddress,
        secondPhoneNumber,
        website,
        facebook,
        instagram,
        twitter,
        tikTok,
        charityNumber,
        NEQ,
        incorporationDate: incorporationDate
          ? moment(incorporationDate).format("YYYY-MM-DD")
          : null,
        memberSinceDate: memberSinceDate
          ? moment(memberSinceDate).format("YYYY-MM-DD")
          : null,
        nameRP,
        titleRP,
        emailRP,
        numOfBoardSeats: parseInt(numOfBoardSeats),
        boardContactName,
        boardPosition,
        phoneBoard,
        emailBoard,
      };

      updateActivityReq({
        variables: {
          ...varsUpdate,
        },
      });
    } else {
      setClientErrMsg(FORM_VALIDATION_FAILED);
      setOpenErrSnackBar(true);
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openErrSnackBar}
        onClose={snackBarCloseHandler}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={snackBarCloseHandler}
          severity="error"
        >
          {clientErrMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openSuccessSnackBar}
        autoHideDuration={4000}
        onClose={snackBarSuccessClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={snackBarSuccessClose}
          severity="success"
        >
          {clientSuccessMsg}
        </Alert>
      </Snackbar>
      <div className={classes.root}>
        <form className={classes.root} noValidate autoComplete="off">
          <Grid container spacing={6} justifyContent="space-between">
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="activity-scenario-dropdown-label">
                  Instance régionale RMJQ
                </InputLabel>
                <Select
                  disabled={disableForm}
                  labelId="activity-scenario-label"
                  id="activity-scenario-select"
                  value={region}
                  onChange={(e) => {
                    if (e.target.value == 0) {
                      setRegion(null);
                    } else {
                      setRegion(e.target.value);
                      setIsValidRegion(true);
                    }
                  }}
                  ref={regionRef}
                >
                  {REGIONS.map((item, index) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {!isValidRegion && (
                  <span className={`${css(AppStyles.formError)}`}>
                    Instance régionale RMJQ est obligatoire
                  </span>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled={disableForm}
                type="text"
                id="nom"
                label="Nom"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value, setIsValidName(true))}
                ref={nameRef}
              />
              {!isValidName ? (
                <span className={`${css(AppStyles.formError)}`}>
                  {INVALID_YOUTHCENTER_NAME}
                </span>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid container spacing={6} className={classes.inputFieldsWrapper}>
            <Grid item xs={12} md={6}>
              <TextField
                disabled={disableForm}
                type="text"
                id="Adresse"
                label="Adresse"
                fullWidth
                multiline
                value={address}
                onChange={(e) =>
                  setAddress(e.target.value, setIsValidAddress(true))
                }
                ref={addressRef}
              />
              {!isValidAddress ? (
                <span className={`${css(AppStyles.formError)}`}>
                  {INVALID_YOUTHCENTER_ADDRESS}
                </span>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled={disableForm}
                type="text"
                id="adresse-postale"
                label="Adresse postale (si différente de l’adresse physique)"
                fullWidth
                multiline
                value={mailingAddress}
                onChange={(e) => setMailingAddress(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={6} justifyContent="space-between">
            <Grid item xs={12} md={4}>
              <TextField
                disabled={disableForm}
                type="email"
                id="e-mail"
                label="Courriel"
                fullWidth
                value={emailAddress}
                onChange={(e) =>
                  setEmailAddress(e.target.value, setIsValidEmailAddress(true))
                }
                ref={emailAddRef}
              />
              {!isValidEmailAddress ? (
                <span className={`${css(AppStyles.formError)}`}>
                  {INVALID_YOUTHCENTER_EMAIL}
                </span>
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                disabled={disableForm}
                id="phonenumber"
                label="Numéro de téléphone"
                fullWidth
                value={phoneNumber}
                onChange={(e) =>
                  setPhoneNumber(e.target.value, setIsValidPhoneNumber(true))
                }
                ref={phoneNumberRef}
              />
              {!isValidPhoneNumber ? (
                <span className={`${css(AppStyles.formError)}`}>
                  {INVALID_YOUTHCENTER_PHONENUM}
                </span>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled={disableForm}
                id="secondPhoneNumber"
                label="Téléphone secondaire"
                fullWidth
                value={secondPhoneNumber}
                onChange={(e) => setSecondPhoneNumber(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={6} justifyContent="space-between">
            <Grid item xs={12} md={12}>
              <TextField
                disabled={disableForm}
                id="website"
                label="Site internet"
                fullWidth
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={6} justifyContent="space-between">
            <Grid item xs={12} md={6}>
              <TextField
                disabled={disableForm}
                id="facebook"
                label="Facebook"
                fullWidth
                value={facebook}
                onChange={(e) => setFacebook(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled={disableForm}
                id="instagram"
                label="Instagram"
                fullWidth
                value={instagram}
                onChange={(e) => setInstagram(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={6} justifyContent="space-between">
            <Grid item xs={12} md={6}>
              <TextField
                disabled={disableForm}
                id="twitter"
                label="Twitter"
                fullWidth
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled={disableForm}
                id="tikTok"
                label="Tik Tok"
                fullWidth
                value={tikTok}
                onChange={(e) => setTikTok(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={6} justifyContent="space-between">
            <Grid item xs={12} md={6}>
              <TextField
                disabled={disableForm}
                id="charityNumber"
                label="Numéro d'organisme de bienfaisance"
                fullWidth
                value={charityNumber}
                onChange={(e) => setCharityNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled={disableForm}
                id="NEQ"
                label="Numéro d'entreprise du Québec"
                fullWidth
                value={NEQ}
                onChange={(e) => setNEQ(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6} justifyContent="space-between">
            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disabled={disableForm}
                  autoOk
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  label="Date d'incorporation"
                  format="yyyy-MM-dd"
                  value={incorporationDate}
                  InputAdornmentProps={{ position: "start" }}
                  onChange={(date) => setIncorporationDate(date)}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disabled={disableForm}
                  autoOk
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  label="Date d’adhésion au RMJQ"
                  format="yyyy-MM-dd"
                  value={memberSinceDate}
                  InputAdornmentProps={{ position: "start" }}
                  onChange={(date) => setMemberSinceDate(date)}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>

          <Typography
            className={`${css(AppStyles.mTop25)}`}
            style={{ color: "rgba(0, 0, 0, 0.54)" }}
          >
            Personne responsable
          </Typography>

          <Card className={classes.cardWrapper}>
            <CardContent className={classes.cardContentWrapperTwo}>
              <Grid container spacing={6} justifyContent="space-between">
                <Grid item xs={12} md={4}>
                  <TextField
                    disabled={disableForm}
                    id="nameRP"
                    label="Nom"
                    fullWidth
                    value={nameRP}
                    onChange={(e) => setNameRP(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    disabled={disableForm}
                    id="titleRP"
                    label="Titre"
                    fullWidth
                    value={titleRP}
                    onChange={(e) => setTitleRP(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    disabled={disableForm}
                    id="emailRP"
                    label="Adresse courriel"
                    fullWidth
                    value={emailRP}
                    onChange={(e) =>
                      setEmailRP(e.target.value, setIsValidEmailAddressRP(true))
                    }
                  />
                  {!isValidEmailAddressRP ? (
                    <span className={`${css(AppStyles.formError)}`}>
                      {INVALID_YOUTHCENTER_EMAIL}
                    </span>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Typography
            className={`${css(AppStyles.mTop25)}`}
            style={{ color: "rgba(0, 0, 0, 0.54)" }}
          >
            CA
          </Typography>
          <Card className={classes.cardWrapper}>
            <CardContent className={classes.cardContentWrapperTwo}>
              <Grid container spacing={6} justifyContent="space-between">
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled={disableForm}
                    id="numOfBoardSeats"
                    label="Nombre de sièges du CA"
                    fullWidth
                    value={numOfBoardSeats}
                    type={"number"}
                    onChange={(e) => setNumOfBoardSeats(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled={disableForm}
                    id="boardContactName"
                    label="Nom de la personne contact avec le CA"
                    fullWidth
                    value={boardContactName}
                    onChange={(e) => setBoardContactName(e.target.value)}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6} justifyContent="space-between">
                <Grid item xs={12} md={4}>
                  <TextField
                    disabled={disableForm}
                    id="boardPosition"
                    label="Poste sur le CA"
                    fullWidth
                    value={boardPosition}
                    onChange={(e) => setBoardPosition(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    disabled={disableForm}
                    id="phoneBoard"
                    label="Téléphone"
                    fullWidth
                    value={phoneBoard}
                    onChange={(e) => setPhoneBoard(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    disabled={disableForm}
                    id="emailBoard"
                    label="Adresse courriel"
                    fullWidth
                    value={emailBoard}
                    onChange={(e) =>
                      setEmailBoard(
                        e.target.value,
                        setIsValidEmailAddressCA(true)
                      )
                    }
                  />
                  {!isValidEmailAddressCA ? (
                    <span className={`${css(AppStyles.formError)}`}>
                      {INVALID_YOUTHCENTER_EMAIL}
                    </span>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {true && (
            <>
              <Typography
                className={`${css(AppStyles.mTop25)}`}
                style={{ color: "rgba(0, 0, 0, 0.54)" }}
              >
                Points de service ({serviceFormValues.length})
              </Typography>
              <Card className={classes.cardWrapper}>
                <CardContent className={classes.cardContentWrapperTwo}>
                  {serviceFormValues.map((element, index) => (
                    <>
                      <Grid
                        container
                        spacing={3}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={12} md={12}>
                          <TextField
                            disabled={disableForm}
                            id="Nom"
                            label="Nom"
                            fullWidth
                            multiline
                            value={element.serviceName}
                            onChange={(e) => {
                              handleChangeServiceName(index, e);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField
                            disabled={disableForm}
                            id="adresse"
                            label="Adresse"
                            fullWidth
                            multiline
                            value={element.serviceAddress}
                            onChange={(e) => {
                              handleChangeServiceAddress(index, e);
                            }}
                          />
                        </Grid>
                        <Grid item>
                          {element.serviceId ? (
                            <Button
                              hidden={disableForm}
                              variant="contained"
                              startIcon={<Remove />}
                              onClick={() => {
                                deleteServicePoint(element.serviceId);
                              }}
                              className={css(styles.deleteBtn)}
                            >
                              Supprimer
                            </Button>
                          ) : (
                            <Button
                              hidden={disableForm}
                              variant="contained"
                              startIcon={<Remove />}
                              onClick={() => removeFormFields(index)}
                              className={css(styles.deleteBtn)}
                            >
                              Supprimer
                            </Button>
                          )}
                        </Grid>
                        <Grid item></Grid>
                      </Grid>
                      <div className={classes.hrMargin}>
                        <Divider className={classes.hrColor} />
                      </div>
                    </>
                  ))}

                  <Grid
                    container
                    spacing={6}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item></Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        hidden={disableForm}
                        className={css(
                          styles.updateBtn,
                          AppStyles.mBottom5,
                          AppStyles.mTop15
                        )}
                        startIcon={<Add />}
                        onClick={() => addFormFields()}
                      >
                        Point de service
                      </Button>
                    </Grid>
                  </Grid>
                  {!_.isEmpty(serviceFormValues) && (
                    <div className={classes.hrMargin}>
                      <Divider light={true} />
                    </div>
                  )}

                  {!isValidServiceValues ? (
                    <span className={`${css(AppStyles.formError)}`}>
                      {INVALID_SERVICE_INFO}
                    </span>
                  ) : (
                    ""
                  )}
                  {!_.isEmpty(serviceFormValues) && (
                    <>
                      <Grid
                        container
                        spacing={4}
                        justifyContent="space-between"
                      >
                        <Grid item></Grid>
                        <Grid item>
                          <span
                            className={css(
                              AppStyles.formSuccess,
                              AppStyles.mBottom10
                            )}
                          >
                            {formSubmitResponse}
                          </span>
                          <ActionButton
                            hide={disableForm}
                            className={css(styles.addBtn)}
                            title="Sauvegarder les points de service"
                            isLoading={_.find(
                              serviceFormValues,
                              function (service) {
                                if (service.serviceId == null) {
                                  return serviceLoading;
                                } else {
                                  return updateServiceLoading;
                                }
                              }
                            )}
                            onClick={submitServicePointFunction}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </CardContent>
              </Card>
            </>
          )}

          <Grid
            container
            spacing={6}
            alignItems="center"
            justifyContent="space-between"
            className={classes.inputFieldsWrapper}
          >
            <Grid item>
              <span className={css(AppStyles.formSuccess, AppStyles.mBottom10)}>
                {youthCenterSuccessMsg}
              </span>
              <ActionButton
                hide={disableForm}
                className={css(styles.updateBtn)}
                title="Mettre À Jour"
                onClick={updateYouthCenterFunc}
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
}
