import Immutable from "seamless-immutable";

import { UPDATE_LAYOUT } from "../actions/ActionTypes";

const initialState = Immutable({
  shouldShowCookieConsent: true,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LAYOUT: {
      const { shouldShowCookieConsent } = action.payload;

      return Immutable.merge(state, {
        shouldShowCookieConsent,
      });
    }
    default:
      return state;
  }
};
