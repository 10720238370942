// @flow
import { StyleSheet } from "aphrodite";
import { Colors } from "../../theme";

export default StyleSheet.create({
  addBtn: {
    background: Colors.brand.primary,
    color: Colors.white,
    width: "100%",
    height: 45,
    marginTop: 20,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 400,
    boxShadow: "2px 2px 8px 1px #0000002b",
    ":hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
});
