// @flow
import React from "react";
import PageForbiddenView from "./PageForbiddenView";

class PageForbiddenController extends React.Component {
  static propTypes = {};

  render() {
    return <PageForbiddenView {...this.props} />;
  }
}

export default PageForbiddenController;
