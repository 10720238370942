import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Editor, EditorState, convertFromRaw } from "draft-js";

import { TIME_FORMAT1 } from "../../constants";
import { ACTIVITY } from "../../graphql";
import Util from "../../services/Util";

const useStyles = makeStyles((theme) => ({
  content: {
    fontFamily: 'sans-serif',
    margin: theme.spacing(2),
    '& table, th, td': {
      border: 'gray 1px solid',
    },
    '& table': {
      borderCollapse: 'collapse',
      margin: `0 0 ${theme.spacing(3)}px 0`,
      width: '100%',
      padding: theme.spacing(1),
    },
    '& th': {
      backgroundColor: 'lightGray',
      padding: theme.spacing(1),
    },
    '& td': {
      padding: theme.spacing(1),
    },
    '& h1': {
      textAlign: 'center',
      margin: `0 0 ${theme.spacing(6)}px 0`,
    }
  },
  borderRight: {
    borderRight: '1px solid grey !important',
  },
  borderRightNone: {
    borderRight: '1px solid #fff !important',
  },
}));

export default function ActivitySummaryView(props) {
  const classes = useStyles();

  const [invCharacteristicsArr, setInvCharacteristicsArr] = useState([]);
  const [invTagsArr, setInvTagsArr] = useState([]);
  const [invThemesArr, setInvThemesArr] = useState([]);

  const [observationsEditorState, setObservationsEditorState] = useState(() => EditorState.createEmpty());
  const [activity, setActivity] = useState({});

  const {
    match: { params },
  } = props;
  useQuery(ACTIVITY(params.id), {
    fetchPolicy: "network-only",
    onCompleted: data => {
      const activity = Util.serialize_ACTIVITY_res(data.activity.data);
      const { activity_involvements } = activity;
      const newInvCharacteristictsArr = [];
      const newInvTagsArr = [];
      const newInvThemesArr = [];
    
      for (const involvement of activity_involvements) {
        if (involvement.activity_characteristic) {
          newInvCharacteristictsArr.push({...involvement, ...involvement.activity_characteristic});
        } else if (involvement.activity_tag) {
          newInvTagsArr.push({...involvement, ...involvement.activity_tag});
        } else if (involvement.activity_theme) {
          newInvThemesArr.push({...involvement, ...involvement.activity_theme});
        }
      }

      setInvCharacteristicsArr(newInvCharacteristictsArr);
      setInvTagsArr(newInvTagsArr);
      setInvThemesArr(newInvThemesArr);

      const observationStateFromDB = activity.observation || ""
      let contentState;

      try {
        const observationRawState = JSON.parse(observationStateFromDB);
        contentState = convertFromRaw(observationRawState);
      } catch (e) {
        console.error(e);
        // Failled to parse the JSON
        // The observation is treated as text and new state is initialized
        
        contentState = convertFromRaw({
          "blocks": [
            {
              "key": "637gr",
              "text": observationStateFromDB,
              "type": "unstyled",
              "depth": 0,
              "inlineStyleRanges": [],
              "entityRanges": [],
              "data": {
                "text-align": "left"
              }
            }
          ],
          "entityMap": {}
        });
      }

      const editorState = EditorState.createWithContent(contentState);
      setObservationsEditorState(editorState);

      setActivity(activity);
    }
  });

  if (!activity?.id) return <></>;

  let startTime = moment(activity.start_time, [moment.ISO_8601, 'HH:mm']);
  let endTime = moment(activity.end_time, [moment.ISO_8601, 'HH:mm']);
  let duration = moment.duration(endTime.diff(startTime));
  const participantsByType = type => activity.participants.filter(p => Boolean(
    p.participant_types.filter(t => t.name.toLowerCase() === type).length)
  );
  const ados = participantsByType('ado');
  const intervenants = participantsByType('anim');
  const others = activity.participants.filter(p => !ados.includes(p) && !intervenants.includes(p));
  const durationMoreThanDay = !(moment(new Date(activity.start_date)).isSame(new Date(activity.end_date)));
  let datesFormat = TIME_FORMAT1

  if (durationMoreThanDay) {
    datesFormat = "YYYY-MM-DD HH:mm";
    const activityStartTime = moment(activity.start_time, [moment.ISO_8601, 'HH:mm']);

    startTime = moment(new Date(activity.start_date)).set({
      hour: activityStartTime.get('hour'),
      minute: activityStartTime.get('minute'),
    });

    const activityEndTime = moment(activity.end_time, [moment.ISO_8601, 'HH:mm']);

    endTime = moment(new Date(activity.end_date)).set({
      hour: activityEndTime.get('hour'),
      minute: activityEndTime.get('minute'),
    });

    duration = moment.duration(endTime.diff(startTime));
  }


  const renderInvolvementsRows = () => {
    const rows = [];

    const maxInv = Math.max(
      invCharacteristicsArr.length,
      invTagsArr.length,
      invThemesArr.length,
    );

    for (let i = 0; i < maxInv; i++) {
      const char = invCharacteristicsArr[i];
      const tag = invTagsArr[i];
      const theme = invThemesArr[i];

      rows.push(
        <tr>
          {Boolean(char)
            ?
            <>
              <td className={classes.borderRightNone}>{char.name}</td>
              <td className={classes.borderRight}>{char.count}</td>
            </>
            :
            <>
              <td className={classes.borderRightNone}></td>
              <td className={classes.borderRight}></td>
            </>
          }
          {Boolean(tag)
            ?
            <>
              <td className={classes.borderRightNone}>{tag.name}</td>
              <td className={classes.borderRight}>{tag.count}</td>
            </>
            :
            <>
              <td className={classes.borderRightNone}></td>
              <td className={classes.borderRight}></td>
            </>
          }
          {Boolean(theme)
            ?
            <>
              <td className={classes.borderRightNone}>{theme.name}</td>
              <td>{theme.count}</td>
            </>
            :
            <>
              <td className={classes.borderRightNone}></td>
              <td></td>
            </>
          }
        </tr>
      );
    }

    return rows;
  }


  return <div className={classes.content}>
    <h1>Rapport d’activité</h1>
    <table>
      <tr>
        <th>ID</th>
        <th>Date</th>
        <th>Titre</th>
        <th>Type</th>
        <th>Heure début</th>
        <th>Heure fin</th>
        <th>Durée</th>
      </tr>
      <tr>
        <td>{activity.id}</td>
        <td>{activity.date}</td>
        <td>{activity.title}</td>
        <td>{activity.activity_type?.name}</td>
        <td>{startTime.format(datesFormat)}</td>
        <td>{endTime.format(datesFormat)}</td>
        <td>{parseFloat(duration.asHours().toFixed(2))}</td>
      </tr>
    </table>
    <table>
      <tr>
        <th>Participants IDs</th>
        <th>Ados</th>
        <th>Total ados</th>
        <th>Intervenants</th>
        <th>Total intervenants</th>
        <th>Autres participants</th>
        <th>Total autres participants</th>
      </tr>
      <tr>
        <td>
          {activity.participants.map(p => p.id).join(', ')}
        </td>
        <td>
          {ados.map(p => <>{p.firstName} {p.lastName}<br/></>)}
        </td>
        <td>{ados.length}</td>
        <td>
          {intervenants.map(p => <>{p.firstName} {p.lastName}<br/></>)}
        </td>
        <td>{intervenants.length}</td>
        <td>
          {others.map(p => <>{p.firstName} {p.lastName}<br/></>)}
        </td>
        <td>{others.length}</td>
      </tr>
    </table>
    <table>
      <tr>
        <th>Thèmes</th>
        <th>Total thèmes</th>
        <th>Objectifs</th>
        <th>Total objectifs</th>
        <th>Étiquettes</th>
      </tr>
      <tr>
        <td>{activity.themes.map(t => <>{t.name}<br/></>)}</td>
        <td>{activity.themes.length}</td>
        <td>{activity.goals.map(g => <>{g.name}<br/></>)}</td>
        <td>{activity.goals.length}</td>
        <td>{activity.activity_tags.map(t => <>{t.name}<br/></>)}</td>
      </tr>
    </table>
    <table>
      <tr>
        <th>Observations</th>
      </tr>
      <tr>
        <td>
          <Editor editorState={observationsEditorState} readOnly={true} />
        </td>
      </tr>
    </table>
    <table>
      <tr>
        <th>Catégories</th>
      </tr>
      <tr>
        <td>{activity.activity_characteristics.map(t => <>{t.name}<br/></>)}</td>
      </tr>
    </table>
    <table>
      <tr>
        <th colSpan={6}>Décompte des participants</th>
      </tr>
      <tr>
        <th colSpan={2} className={classes.borderRight}>Catégories</th>
        <th colSpan={2} className={classes.borderRight}>Étiquettes</th>
        <th colSpan={2}>Thèmes</th>
      </tr>
      {renderInvolvementsRows()}
    </table>



    <table>
      <tr>
        <th>Nombre d'ados additionnels</th>
        <th>Étiquettes pour les ados</th>
        <th>Nombre de grand public additionnels</th>
        <th>Étiquettes pour le grand public</th>
      </tr>
      <tr>
        <td>{activity.youth}</td>
        <td>{activity.youth_tags.map(t => <>{t.name}<br/></>)}</td>
        <td>{activity.grand_public}</td>
        <td>{activity.public_tags.map(p => <>{p.name}<br/></>)}</td>
      </tr>
    </table>
  </div>;
};
