// @flow
import React from "react";
import EtiquettesListing from "./EtiquettesListingView";

export default class EtiquettesListingController extends React.Component {
  static propTypes = {};

  static defaultProps = {};
  render() {
    return <EtiquettesListing {...this.props} />;
  }
}
