// @flow
import React from "react";
import UsersListingView from "./UsersListingView";

export default class UsersListingController extends React.Component {
  static propTypes = {};

  static defaultProps = {};
  render() {
    return <UsersListingView {...this.props} />;
  }
}
