// @flow
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import ParticipantsView from "./ParticipantsView";

export default class ParticipantsController extends React.Component {
  render() {
    return <ParticipantsView {...this.props} />;
  }
}
