// @flow
import React from "react";
import ActivitiesListingView from "./ActivitiesListingView";

export default class ActivitiesListingController extends React.Component {
  static propTypes = {};

  static defaultProps = {};
  render() {
    return <ActivitiesListingView {...this.props} />;
  }
}
