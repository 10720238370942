import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, frFR } from "@material-ui/data-grid";
import { Colors } from "../../theme";
import {
  useTheme,
  Container,
  Typography,
  Card,
  CardContent,
  TextField,
  Grid,
  Button,
  Slider,
  InputAdornment,
  FormControlLabel,
  Switch,
  CircularProgress,
  Snackbar,
  Modal,
  Fade,
  Backdrop,
  Box,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel
} from "@material-ui/core";

import { css } from "aphrodite";
import { AppStyles } from "../../theme";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import {
  PARTICIPANT_TYPES,
  ARCHIVE_PARTICIPANT,
  CREATE_PARTICIPANT,
  GET_SERVICE_POINT_YOUTH_CENTER,
  GET_ALL_PARTICIPANT_TAGS,
} from "../../graphql";
import { DATE_FORMAT11, ROUTES, STRAPI_DATE_FORMAT } from "../../constants";
import styles from "./ParticipantsListingStyles";
import { Header, UserForm } from "../../components";
import _, { last, map } from "lodash";
import { useHistory } from "react-router-dom";
import { GetApp, Search, Class, Block, Add } from "@material-ui/icons";
import * as XLSX from "xlsx";
import { Autocomplete, Alert } from "@material-ui/lab";
import { useSelector } from "react-redux";

import ParticipantThreeDotMenu from "../../components/ParticipantThreeDotMenu";
import { getSortedOptions } from "../../helpers/dataHelpers";
import xlsxTemplate from "../../assets/xlsx_templates/participants.xlsx";
import frLocale from "date-fns/locale/fr";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const dateSorting = (arr) => {
  if (_.isEmpty(arr)) return false;

  const mOrderedArray = _.sortBy(arr, (o) => o.date);
  return mOrderedArray;
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  flexClass: { flex: 1 },
  appBarBg: {
    background: Colors.brand.primary,
  },
  inputFieldsWrapper: {
    marginTop: theme.spacing(2),
  },
  hrMargin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  hrColor: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  green: {
    color: Colors.brand.primary,
  },
  formControl: {
    minWidth: "100%",
  },
  deleteBtn: {
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 400,
    boxShadow: "2px 2px 8px 1px #0000002b",
    "&:hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
  cardWrapper: {
    padding: "40px 0",
    boxShadow: "1px 1px 9px 2px #0000001a",
    borderRadius: 7,
  },
  cardContentWrapper: {
    padding: "16px 26px",
  },
  downloadBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 600,
    height: 45,
    minWidth: 45,
    borderRadius: "100%",
    boxShadow: "2px 2px 8px 1px #0000002b",
    marginLeft: "10px",
    "&:hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
  title: {
    color: "rgba(0, 0, 0, 0.54)",
    padding: 0,
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "0.00938em",
    transform: "translate(0, 1.5px) scale(0.75)",
    transformOrigin: "top left",
  },
  viewParticipant: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  vertBtn: {
    minWidth: 4,
    width: 20,
    height: 16,
  },
  vertCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  threeDotContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '500px',
    margin: '0 20px',
    position: 'relative',
    maxHeight: '80vh',
    overflowY: 'auto'
  },
}));


export default function ParticipantsListingView(props) {
  const user = useSelector((state) => {
    return state.user;
  });
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const [tableData, setTableData] = useState(() => []);
  const [tableDataDupe, setTableDataDupe] = useState(() => []);
  const [userName, setUserName] = useState(() => "");
  const [ageRange, setAgeRange] = useState(() => [0, 99]);
  const [participantTypes, setParticipantTypes] = useState(() => []);
  const [userNote, setUserNote] = useState(() => "");
  const [showArchived, setShowArchived] = useState(false);
  const [triggerFilter, setTriggerFIlter] = useState(0.1);
  const [participantModalOpen, setParticipantModalOpen] = useState(false);
  const [servicePoints, setServicePoints] = useState([]);
  const [selectedServicePoints, setSelectedServicePoints] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [avAuthorization, setAvAuthorization] = useState("");
  const [exitPermit, setExitPermit] = useState("");
  const [medicalConditions, setMedicalConditions] = useState("")
  const [openErrSnackBar, setOpenErrSnackBar] = useState(() => false);
  const [clientErrMsg, setClientErrMsg] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [errFetchParticipants, setErrFetchParticipants] = useState(false);
  const [reportDate, setReportDate] = useState(() => moment());

  const snackBarErrClose = () => {
    setOpenErrSnackBar(false);
  };

  const fetchParticipants = async ({
    page,
    pageSize,
  }) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URI}/api/participants/find-listing`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.jwt}`,
        },
        body: JSON.stringify({
          page,
          pageSize,
          youthCenterId: user.youth_center.id,
          userName,
          ageRange,
          participantTypes: participantTypes.map((type) => type.id),
          userNote,
          showArchived,
          selectedServicePoints: selectedServicePoints.map(sp => sp.id),
          selectedTags: selectedTags.map((tag) => tag.id),
          selectedCities,
          avAuthorization,
          medicalConditions,
          exitPermit,
          reportDate: reportDate.format("YYYY-MM-DD"),
        }),
      }
    );

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData.message);
    }

    return responseData;
  };

  const getParticipants = async () => {
    try {
      const responseData = await fetchParticipants({
        page,
        pageSize,
      });

      setTableData(responseData.results);
      setTableDataDupe(responseData.results);
      setTotalParticipants(responseData.pagination.total);
    } catch (err) {
      console.error(err);

      setErrFetchParticipants(true);
    }
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      getParticipants();
    }, 500);

    return () => {
      clearTimeout(debounce);
    }
  }, [
    user.youth_center?.id,
    page,
    pageSize,
    userName,
    ageRange,
    participantTypes,
    userNote,
    showArchived,
    selectedServicePoints,
    selectedTags,
    selectedCities,
    avAuthorization,
    medicalConditions,
    exitPermit,
  ]);

  const [openSuccessSnackBar, setOpenSuccessSnackBar] = useState(() => false);
  const [clientSuccessMsg, setClientSuccessMsg] = useState("");

  const snackBarSuccessClose = () => {
    setOpenSuccessSnackBar(false)
  };

  function valuetext(value) {
    return `${value} Years`;
  }

  const marks = [
    {
      value: 0,
      label: "0 ans",
    },
    {
      value: 99,
      label: "99 ans",
    },
  ];

  const youthCenterName = user.youth_center.Name;

  const { error: errorFetchServicePoints } = useQuery(GET_SERVICE_POINT_YOUTH_CENTER(user.youth_center.id), {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const tmpSP = [
        {
          id: '0',
          name: youthCenterName,
        },
        ...data.youthCenter.data.attributes.service_points.data.map(sp => {
          return {
            id: sp.id,
            name: sp.attributes.name,
          }
        }),
      ];

      setServicePoints(tmpSP);
    }
  });

  useQuery(GET_ALL_PARTICIPANT_TAGS(user.youth_center.id), {
    onCompleted: data => {
      setAllTags(data.participantTags.data.map(item => {
        return {
          id: item.id,
          ...item.attributes,
        };
      }));
    },
  });

  const [archiveParticipantReq] = useMutation(ARCHIVE_PARTICIPANT, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      getParticipants();
    },
  });

  const [
    createUserReq,
  ] = useMutation(CREATE_PARTICIPANT, {
    onCompleted: () => {
      getParticipants();
      setOpenSuccessSnackBar(true);
      setClientSuccessMsg("La personne est ajoutée");
    },
  });

  const [allParticpantTypes, setAllParticipantTypes] = useState([]);
  const {
    loading: loadingParticipantTypes,
    error: errFetchParticipantTypes,
  } = useQuery(PARTICIPANT_TYPES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setAllParticipantTypes(data.participantTypes.data.map(item => {
        return {
          id: item.id,
          ...item.attributes,
        };
      }));
    }
  });

  useEffect(() => {
    let hasError = true;

    if (errFetchParticipants) {
      setClientErrMsg("Erreur lors du chargement des participants");
    } else if (errFetchParticipantTypes) {
      setClientErrMsg("Erreur lors du chargement des types de participants");
    } else if (errorFetchServicePoints) {
      setClientErrMsg("Erreur lors du chargement des points de service");
    } else {
      hasError = false;
    }

    if (hasError) {
      setOpenErrSnackBar(true);
    }

  }, [errFetchParticipants, errFetchParticipantTypes]);

  const handleParticipantArchivation = (participant) => {
    archiveParticipantReq({
      variables: {
        id: participant.id,
        archived: !participant.archived,
        archivationDate: moment(new Date(), moment.ISO_8601),
      }
    });
  }

  useEffect(() => {
    setTableData([...tableDataDupe].filter(p => {
      if (!showArchived) {
        return !p.archived;
      }
      return true;
    }));

  }, [showArchived])

  const expulsionCreatedCallback = message => {
    setClientSuccessMsg(message);
    setOpenSuccessSnackBar(true);
    getParticipants();
  };

  const columns = [
    {
      key: "id",
      field: "id",
      headerName: "ID",
      sortable: false,
    },
    {
      key: "participant",
      field: "participant",
      headerName: "Nom",
      // flex: 1,
      sortable: false,
      minWidth: 200,
      renderCell: (param) => {
        return <div>{`${param.row?.firstName} ${param.row?.lastName}`}</div>;
      },
    },
    {
      key: "attendance",
      field: "firstSeenDate",
      headerName: "Début de fréquentation",
      // flex: 1,
      sortable: false,
      minWidth: 220,
      renderCell: (param) => {
        if (param.row?.first_attendance_date) {
          return (
            <div>
              {moment(param.row?.first_attendance_date).format(DATE_FORMAT11)}
            </div>
          );
        };

        return <div></div>;
      },
    },
    {
      key: "attendance",
      field: "lastSeenDate",
      headerName: "Dernière visite",
      // flex: 1,
      sortable: false,
      minWidth: 220,
      renderCell: (param) => {
        const last_attendance_date = param.row?.last_attendance_date;

        if (last_attendance_date && last_attendance_date !== "1970-01-01") {
          return (
            <div>
              {moment(param.row?.last_attendance_date).format(DATE_FORMAT11)}
            </div>
          );
        };

        return <div></div>;
      },
    },
    {
      key: "notescount",
      field: "notescount",
      headerName: "Nombre de notes",
      sortable: false,
      minWidth: 150,
      renderCell: (param) => {
        return <div>{param.row?.participant_notes.length}</div>;
      },
    },
    {
      key: "actions",
      field: "actions",
      headerName: " ",
      sortable: false,
      flex: 1,
      renderCell: (param) => {
        let isExpulsed = false;
        let expulsedUntil = '';

        if (param.row.expulsions.length) {
          for (const expulsion of param.row.expulsions) {
            if (new Date(expulsion.end + "T00:00").getTime() > Date.now()) {
              isExpulsed = true;
              expulsedUntil = expulsion.end;
              break;
            };
          };
        };

        return (
          <div className={classes.threeDotContainer}>
            {isExpulsed &&
              <div
                title={"Expulsé jusqu'à: " + expulsedUntil}
                className={classes.vertCenter}
              >
                <Block />
              </div>
            }
            {param?.row.archived &&
              <div className={classes.vertCenter}>
                <Class />
              </div>
            }
            <ParticipantThreeDotMenu
              participant={param.row}
              handleArchivation={handleParticipantArchivation}
              formSubmitCallback={expulsionCreatedCallback}
              formErrCallback={errMsg => {
                setClientErrMsg(errMsg);
                setOpenErrSnackBar(true);
              }}
            />
          </div>
        );
      },
    },
  ];

  const ageRangeHandler = (event, newValue) => {
    setAgeRange(newValue);
  };

  const participantTypeHandler = (event, newValue) => {
    setParticipantTypes(newValue);
  };

  const customDownloadMethod = async () => {
    let participantRows = [];
    const res = await fetchParticipants({
      page: 1,
      pageSize: 10000,
    });

    const tableData = res.results;

    for (let i = 0, j = tableData.length; i < j; i++) {
      if (tableData[i].archived) {
        continue;
      }

      let firstSeenDate = null;
      const first_attendance_date = tableData[i].first_attendance_date;

      if (first_attendance_date && first_attendance_date !== "1970-01-01") {
        firstSeenDate = first_attendance_date + "T00:00";
      }

      let lastSeenDate = null;
      const last_attendance_date = tableData[i].last_attendance_date;

      if (last_attendance_date && last_attendance_date !== "1970-01-01") {
        lastSeenDate = last_attendance_date + "T00:00";
      }

      participantRows.push([
        { t: 'n', v: tableData[i].id },
        `${tableData[i].firstName} ${tableData[i].lastName}`,
        firstSeenDate ? { t: 'd', v: firstSeenDate } : "",
        lastSeenDate ? { t: 'd', v: lastSeenDate } : "",
        { t: 'n', v: tableData[i].participant_notes.length },
        tableData[i].address,
        tableData[i].city,
        tableData[i].province?.name,
        tableData[i].postcode,
        tableData[i].other ? tableData[i].other : tableData[i].sexe,
        tableData[i].disabilities.find(d => d.name === "Handicap") ? "Handicap" : "",
        tableData[i].disabilities.find(d => d.name === "Minorité Visible") ? "Minorité" : "",
        tableData[i].day,
        tableData[i].month,
        tableData[i].year,
        tableData[i].participant_types.map(t => t.name).join(', '),
        tableData[i].email,
        tableData[i].phoneNumber,
        tableData[i].pronoms,
      ]);
    }

    const req = new XMLHttpRequest();
    req.open("GET", xlsxTemplate, true);
    req.responseType = "arraybuffer";

    req.onload = function(e) {
      const data = new Uint8Array(req.response);
      const wb = XLSX.read(data, {type:"array"});
      const ws = wb.Sheets['Participants'];

      XLSX.utils.sheet_add_aoa(ws, participantRows, {origin: 1});
      XLSX.writeFile(wb, 'Participants report.xlsx');
    }

    req.send();
  };

  return (
    <div>
      <Header />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openSuccessSnackBar}
        autoHideDuration={4000}
        onClose={snackBarSuccessClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={snackBarSuccessClose}
          severity="success"
        >
          {clientSuccessMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openErrSnackBar}
        onClose={snackBarErrClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={snackBarErrClose}
          severity="error"
        >
          {clientErrMsg}
        </Alert>
      </Snackbar>
      <>
        <div className={classes.root}>
          <Container maxWidth="lg" className={css(AppStyles.marginTop128)}>
            <Card className={classes.cardWrapper}>
              <CardContent className={classes.cardContentWrapper}>
                <Grid
                  style={{ marginTop: "20px", width: "100%" }}
                  container
                  spacing={4}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs={12} md={8}>
                    <Typography variant="h4" className={classes.titleone}>
                      Participants
                    </Typography>
                  </Grid>
                  <Grid item xs={1} md={4} alignItems="flex-end">
                    {/* <CSVLink data={tableData} filename={"my-file.csv"}> */}
                    <Box display={'flex'} alignItems="center" justifyContent="flex-end">
                    <MuiPickersUtilsProvider
                        locale={frLocale}
                        utils={DateFnsUtils}
                      >
                        <KeyboardDatePicker
                          style={{
                            width: "240px",
                          }}
                          margin="normal"
                          fullWidth
                          disableToolbar
                          variant="inline"
                          format="iiii dd MMMM yyyy"
                          invalidDateMessage=""
                          id="date-timepicker"
                          label="Date du rapport"
                          value={reportDate}
                          onChange={(data) => {
                            setReportDate(moment(data));
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      <Button
                        onClick={customDownloadMethod}
                        className={classes.downloadBtn}
                      >
                        <GetApp />
                      </Button>
                      <Button
                        onClick={() => {
                          setParticipantModalOpen(true);
                        }}
                        className={classes.downloadBtn}
                      >
                        <Add />
                      </Button>
                    </Box>
                    {/* </CSVLink> */}
                  </Grid>
                </Grid>
                <Grid
                  style={{ marginTop: "20px", width: "100%" }}
                  container
                  spacing={4}
                  alignItems="stretch"
                  justifyContent="space-between"
                >
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="input-with-icon-grid"
                      label="Rechercher par nom"
                      placeholder="Le nom contient..."
                      fullWidth
                      value={userName}
                      onChange={(e) => {
                        setUserName(e.target.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="input-with-icon-grid"
                      label="Rechercher parmi les notes"
                      placeholder="Les notes contiennent..."
                      fullWidth
                      value={userNote}
                      onChange={(e) => setUserNote(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      disableCloseOnSelect
                      multiple
                      id="participant-type-tag"
                      options={
                        allParticpantTypes
                          ? getSortedOptions(allParticpantTypes, "name")
                          : []
                      }
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      value={participantTypes || []}
                      onChange={participantTypeHandler}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Filtrer par type de participant"
                          placeholder="Type de participant..."
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography
                      id="range-slider"
                      gutterBottom
                      className={classes.title}
                    >
                      Filtrer par âge
                    </Typography>
                    <Slider
                      value={ageRange}
                      onChange={ageRangeHandler}
                      valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      getAriaValueText={valuetext}
                      color="secondary"
                      min={0}
                      max={99}
                      marks={marks}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      disableCloseOnSelect
                      multiple
                      id="participant-type-tag"
                      options={getSortedOptions(allTags, "name")}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      value={selectedTags || []}
                      onChange={(event, newVal) => setSelectedTags(newVal)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Filtrer par étiquettes"
                          placeholder="Rechercher"
                        />
                      )}
                    />
                  </Grid>
                  {servicePoints.length > 0 &&  (
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        disableCloseOnSelect
                        multiple
                        id="service-point"
                        options={
                          servicePoints
                            ? getSortedOptions(servicePoints, "name")
                            : []
                        }
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) =>
                          option.name === value.name
                        }
                        value={selectedServicePoints || []}
                        onChange={(event, newVal) => setSelectedServicePoints(newVal)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Filtrer par lieu"
                            placeholder="Rechercher"
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {allCities.length > 0 &&  (
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        disableCloseOnSelect
                        multiple
                        id="all-cities"
                        options={allCities}
                        getOptionLabel={(option) => option}
                        getOptionSelected={(option, value) =>
                          option === value
                        }
                        value={selectedCities || []}
                        onChange={(event, newVal) => setSelectedCities(newVal)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Filtrer par ville"
                            placeholder="Rechercher"
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid
                    style={{ margin: "20px 0px"}}
                    container
                    spacing={4}
                  >
                    <Grid item xs={12} md={4}>
                      <FormControl className={classes.formControl}>
                        <InputLabel>Filter par autorisation audio/vidéo</InputLabel>
                        <Select
                          value={avAuthorization}
                          onChange={(event) => {
                            setAvAuthorization(event.target.value);
                          }}
                          className={classes.selectEmpty}
                          inputProps={{ 'aria-label': 'Autorisation audio/vidéo' }}
                        >
                          <MenuItem value="">
                            <span style={{visibility: "hidden"}}>
                              Peu importe
                            </span>
                          </MenuItem>
                          <MenuItem value={true}>Oui</MenuItem>
                          <MenuItem value={false}>Non</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl className={classes.formControl}>
                        <InputLabel>Filter par autorisation de sortie</InputLabel>
                        <Select
                          value={exitPermit}
                          onChange={(event) => {
                            setExitPermit(event.target.value);
                          }}
                          className={classes.selectEmpty}
                          inputProps={{ 'aria-label': 'Autorisation de sortie' }}
                        >
                          <MenuItem value="">
                            <span style={{visibility: "hidden"}}>
                              Peu importe
                            </span>
                          </MenuItem>
                          <MenuItem value={true}>Oui</MenuItem>
                          <MenuItem value={false}>Non</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl className={classes.formControl}>
                        <InputLabel>Filter par allergies et conditions médicales</InputLabel>
                        <Select
                          value={medicalConditions}
                          onChange={(event) => {
                            setMedicalConditions(event.target.value);
                          }}
                          className={classes.selectEmpty}
                          inputProps={{ 'aria-label': 'Allergies et conditions médicales' }}
                        >
                          <MenuItem value="">
                            <span style={{visibility: "hidden"}}>
                              Peu importe
                            </span>
                          </MenuItem>
                          <MenuItem value={true}>Oui</MenuItem>
                          <MenuItem value={false}>Non</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs={12} md={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showArchived}
                          onChange={e => {
                            setShowArchived(e.target.checked);
                          }}
                        />
                      }
                      label="Afficher les participants archivés"
                    />
                  </Grid>
                </Grid>
                <div className={css(styles.TableWrpper)}>
                  {(loadingParticipantTypes || loadingParticipantTypes)
                    ?
                    <div className="progress-container">
                      <CircularProgress color="primary" />
                    </div>
                    :
                    <DataGrid
                      className={`participantsTable`}
                      localeText={frFR.props.MuiDataGrid.localeText}
                      rows={tableData}
                      columns={columns}
                      isRowSelectable={false}
                      disableSelectionOnClick={true}
                      disableColumnMenu={true}
                      sortingOrder={["desc", "asc", null]}
                      rowThreshold={0}
                      disable
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      page={page - 1}
                      onPageChange={(newPage) => setPage(newPage + 1)}
                      rowCount={totalParticipants}
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      paginationMode="server"
                      onRowClick={(param) => {
                        window.open(
                          `${ROUTES.PARTICIPANTS}/${param.row.id}`,
                          '_blank'
                        );
                      }}
                    />
                  }
                </div>
              </CardContent>
            </Card>
          </Container>
        </div>
      </>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={participantModalOpen}
        onClose={() => {
          setParticipantModalOpen(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={participantModalOpen}>
          <div className={classes.paper}>
            <UserForm
              formSubmitCallback={vars => {
                createUserReq({
                  variables: {
                    ...vars
                  },
                });
                setParticipantModalOpen(false);
              }}
              closeFormCallback={() => {
                setParticipantModalOpen(false);
              }}
              title="Ajouter une Personne"
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
