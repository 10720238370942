import _ from "lodash";


const logoutUser = () => {
  window.localStorage.removeItem("jwt");
  window.localStorage.removeItem("youth-center");
  window.localStorage.removeItem("persist:root");
  window.localStorage.removeItem("requestPasswordChange");
  window.localStorage.removeItem("user");
  window.localStorage.removeItem('location');
  window.localStorage.removeItem('userId');
  window.localStorage.removeItem('token');
  window.location.reload();
}

//get youth center
const getYouthCenter = () => {
  try {
    const youthCenter = localStorage.getItem("youth-center");
    const id = JSON.parse(youthCenter).id;

    if (!id) throw "youthCenter ID is not defined";
    if (!parseInt(id)) throw "youthCenter ID is not a number";

    return id;
  } catch (e) {
    console.error(e);
    console.log("No youth center ID found! Logging out...");
    logoutUser();
  }

};

//sorting arrray
const getSortedOptions = (array, key) => {
  let arr = _.sortBy(array, [entity => {
    return entity[key].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
  }], ['asc']);
  return arr;
};

export { getYouthCenter, getSortedOptions, logoutUser };
