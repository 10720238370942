import React, { useEffect, useState } from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { Header } from "../../components";
import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { AppStyles } from "../../theme";
import { css } from "aphrodite";
import { YouthCenterForm } from "../../components";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { GET_YOUTHCENTER_BY_ID, GET_YOUTH_CENTERS, UPDATE_USER_YOUTH_CENTER } from "../../graphql";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { USER_PERMISSIONS } from "../../constants";
import { UPDATE_USER } from "../../actions/ActionTypes";

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 50,
  },
  appBarBg: {
    background: "#5cbdaf",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hrMargin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  hrColor: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  cardWrapper: {
    marginTop: 15,
    padding: "20px 0 0px",
    boxShadow: "1px 1px 5px 0px #00000040",
    borderRadius: 3,
  },
  cardContentWrapper: {
    padding: "16px 26px 0",
  },
  cardContentWrapperTwo: {
    padding: "0px 26px 0",
  },
}));

export default function YouthCentersView(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useSelector((state) => {
    return state.user;
  });

  const [singleYouthCenter, setSingleYouthCenter] = useState({});
  const [youthCenters, setYouthCenters] = useState([]);
  const [value, setValue] = useState(null);

  const [updateUserYouthCenter] = useMutation(UPDATE_USER_YOUTH_CENTER, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const userData = data.updateUsersPermissionsUser.data;

      dispatch({
        type: UPDATE_USER,
        payload: {
          user: {
            id: userData.id,
            ...userData.attributes,
            role: {
              id: userData.attributes.role.data.id,
              ...userData.attributes.role.data.attributes,
            },
            youth_center: {
              id: userData.attributes.youth_center.data.id,
              ...userData.attributes.youth_center.data.attributes,
            },
          },
        },
      });
    },
  })

  const [
    getYouthCenterById,
    {
      error: errFetchYouthCenterById,
      loading: loadingCenterByID,
    },
  ] = useLazyQuery(GET_YOUTHCENTER_BY_ID, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const youthCenter = {
        id: data.youthCenter.data.id,
        ...data.youthCenter.data.attributes,
        service_points: data.youthCenter.data.attributes.service_points.data.map(sp => {
          return {
            id: sp.id,
            name: sp.attributes.name,
            address: sp.attributes.address
          }
        }),
        region: data.youthCenter.data.attributes.region.data ? {
          id: data.youthCenter.data.attributes.region.data.id,
          name: data.youthCenter.data.attributes.region.data.attributes.name
        } : null,
      };
      
      setValue({
        id: data.youthCenter.data.id,
        Name: data.youthCenter.data.attributes.Name,
      });
      setSingleYouthCenter(youthCenter);

      updateUserYouthCenter({
        variables: {
          id: user.id,
          youthCenter: youthCenter.id
        }
      })
    },
  });

  useEffect(() => {
    if (singleYouthCenter?.id !== user.youth_center.id) {
      getYouthCenterById({
        variables: {
          id: user.youth_center.id
        },
      });
    }
  }, []);

  useQuery(GET_YOUTH_CENTERS, {
    variables: { userId: Number(user.id) },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setYouthCenters(data.youthCenters.data.map(yc => {
        return {
          id: yc.id,
          ...yc.attributes,
        };
      }));
    },
  });
  
  return (
    <div>
      <Header title="Coordonnées" />
      <>
        <div className={classes.root}>
          <Container className={css(AppStyles.marginTop128)}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12}>
                <Grid container alignItems="center">
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6">Coordonnées de la maison des jeunes</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                  {[USER_PERMISSIONS.ADMINISTRATOR, USER_PERMISSIONS.RMJQ].includes(user.role.name) && <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                      if (newValue && newValue.id) {
                        getYouthCenterById({
                          variables: {
                            id: newValue.id,
                          },
                        });

                        setValue(newValue);
                        window.localStorage.removeItem('location');
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          Name: `"${params.inputValue}"`,
                        });
                      } 

                      return filtered;
                    }}
                    options={youthCenters}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.Name;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    renderOption={(option) => option.Name}
                    freeSolo
                    renderInput={(params) => (
                      <TextField {...params} label="Maison des jeunes" variant="outlined" />
                    )}
                  />}
                  </Grid>
                </Grid>
                <Card className={classes.cardWrapper}>
                  <CardContent className={classes.cardContentWrapperTwo}>
                    {Boolean(loadingCenterByID)
                      ?
                      <div className="progress-container">
                        <CircularProgress color="primary" />
                      </div>
                      :
                      <YouthCenterForm
                        singleYouthCenter={singleYouthCenter}
                        // errFetchYouthCenter={errFetchYouthCenter}
                        errFetchYouthCenterById={errFetchYouthCenterById}
                      />
                    }
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </div>
      </>
    </div>
  );
}
