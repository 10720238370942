// @flow
import React from "react";
import PropTypes from "prop-types";

import VisitorsListView from "./VisitorsListView";

export default class VisitorsListController extends React.Component {
  static propTypes = {
    date: PropTypes.object.isRequired,
    servicePoint: PropTypes.string,
  };

  static defaultProps = {};

  render() {
    return <VisitorsListView {...this.props} />;
  }
}
