// @flow
import { StyleSheet } from "aphrodite";
import { Colors } from "../../theme";

export default StyleSheet.create({
  addBtn: {
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 400,
    boxShadow: "2px 2px 8px 1px #0000002b",
    ":hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
  cancelBtn: {
    background: "transparent",
    color: "#949494",
    border: `1px solid transparent`,
    textTransform: "uppercase",
    fontWeight: 400,
    ":hover": {
      background: "rgba(0, 0, 0, 0.04)",
    },
  },
});
