import Immutable from "seamless-immutable";

import {
  SET_MERGE_MODAL_SEARCH_TEXT,
  SET_YC_PARTICIPANTS,
} from "../actions/ActionTypes";

const initialState = Immutable({
  participants: [],
  mergeModalSearchText: "",
  loadingParticipants: false,
});


export default (state = initialState, action) => {
  switch (action.type) {
    case SET_YC_PARTICIPANTS: {
      return Immutable.merge(state, {
        participants: action.payload,
        loadingParticipants: false,
      });
    }
    case SET_MERGE_MODAL_SEARCH_TEXT: {
      return Immutable.merge(state, {
        mergeModalSearchText: action.payload,
        loadingParticipants: true,
      });
    }
    default:
      return state;
  }
}



