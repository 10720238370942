import React, { useEffect, useState } from "react";
import {
  Avatar,
  TextField,
  Container,
  Typography,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import _, { toNumber } from "lodash";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { LockOutlined, Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { AppStyles, Colors } from "../../theme";
import { ROUTES } from "../../constants";
import { ActionButton } from "..";
import { css } from "aphrodite";
import styles from "./PasswordResetStyles";
import { useMutation } from "@apollo/client";
import { CHANGE_PASSWORD } from "../../graphql";
import Util from "../../services/Util";
import { useSelector, useDispatch } from "react-redux";
import { SET_PASSWORD_COMPLETED } from "../../actions/ActionTypes";

const PasswordField = props => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(value => !value);
  const handleMouseDownPassword = event => event.preventDefault();

  return <TextField
    variant="outlined"
    margin="normal"
    type="password"
    fullWidth
    type={showPassword ? "text" : "password"}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ),
    }}
    label="Mot de passe"
    {...props}
  />;
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: Colors.brand.primary,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function PasswordResetView() {

  const user = useSelector((state) => {
    return state.user;
  });

  const dispatch = useDispatch();

  const [resetRequest] = useMutation(CHANGE_PASSWORD, {
    onError: () => {
      setError("Une erreur s'est produite lors de la réinitialisation du mot de passe.");
    },
    onCompleted: () => {
      dispatch({
        type: SET_PASSWORD_COMPLETED,
      });

      // TODO: check user permissions for proper redirect
      history.push(ROUTES.DASHBOARD)
    }
  });
  const classes = useStyles();
  const [error, setError] = useState("");
  const [values, setValues] = React.useState({
    password: "",
    passwordRepeat: ""
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const history = useHistory();

  const resetFunc = e => {
    e.preventDefault();
    const id = toNumber(user.id);
    
    resetRequest({
      variables: {
        id,
        password: values.password
      },
    });
  };

  const minPasswordLength = 8;
  const passwordsMatch = values.password === values.passwordRepeat;
  const passwordValid = values.password && values.password.length >= minPasswordLength;
  const formValid = passwordsMatch && passwordValid;
  const passwordError = (!values.password || passwordValid)
    ? undefined
    : `Le mot de passe doit contenir au moins ${minPasswordLength} caractères`;
  const passwordRepeatError = (!values.passwordRepeat || passwordsMatch)
    ? undefined
    : "Le mot de passe doit concorder";

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Réinitialisation du mot de passe
        </Typography>
        <Typography component="p" variant="body1">
          Veuillez réinitialiser votre mot de passe.
        </Typography>
        <form
          className={classes.form}
          noValidate={false}
          onSubmit={resetFunc}
          method="post"
        >
          <PasswordField
            name="password"
            id="password"
            value={values.password}
            onChange={handleChange("password")}
            label="Mot de passe"
            error={Boolean(passwordError)}
            helperText={passwordError}
          />
          <PasswordField
            name="passwordRepeat"
            id="passwordRepeat"
            value={values.passwordRepeat}
            onChange={handleChange("passwordRepeat")}
            label="Mot de passe (répéter)"
            error={Boolean(passwordRepeatError)}
            helperText={passwordRepeatError}
          />
          <ActionButton
            className={css(styles.addBtn)}
            title="Enregistrer"
            type="submit"
            disabled={!formValid}
          />
          <div className={css([AppStyles.textAlignCenter, AppStyles.mTop10])}>
            <span className={css(AppStyles.formError)}>{error}</span>
          </div>
        </form>
      </div>
    </Container>
  );
}

const mapStateToProps = ({ user }) => ({});

const actions = {};

export default connect(mapStateToProps, actions)(withRouter(PasswordResetView));
