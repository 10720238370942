// @flow
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import ActivitiesView from "./ActivitiesView";

export default class ActivitiesController extends React.Component {
  static propTypes = {};

  static defaultProps = {};

  render() {
    return <ActivitiesView {...this.props} />;
  }
}
