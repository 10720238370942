// @flow
import React from "react";
// import PropTypes from "prop-types";

import PreFilledActivityFormView from "./PreFilledActivityFormView";

export default class PreFilledActivityFormController extends React.Component {
  static propTypes = {};

  static defaultProps = {};

  render() {
    return <PreFilledActivityFormView {...this.props} />;
  }
}
